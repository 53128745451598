import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './template1.css';
// import '../../../../Mosque/NewMosque.css';
import temph1 from '../../../../../images/Print/1.jpg';
import temph2 from '../../../../../images/Print/2.jpg';
import temph3 from '../../../../../images/Print/3.jpg';
import temph4 from '../../../../../images/Print/4.jpg';
import temph5 from '../../../../../images/Print/5.jpg';
import temph6 from '../../../../../images/Print/6.jpg';
import temph7 from '../../../../../images/Print/7.jpg';
import temph8 from '../../../../../images/Print/8.jpg';
import header from '../../../../../images/Print/header.jpeg'
import upload from '../../../../../images/file-upload-solid.png';
import { CONFIG } from '../../../../../utils/constants';
import axios from '../../../../../utils/axios';
import { withRouter } from 'react-router-dom';
import Adminheader from '../../../../AdminNavBar';
import Headerad from '../../../../../components/pages/headeradmin'
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import iqama from '../../../../../images/Print/Iqama.jpg'
import { getImgBase64 } from '../../../../../utils/Helper'


class DemoTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {

      selectadspot: "",
      selectedOption: "",
      prayerTimes: [],
      config: {},
      showUploadView: true,
      showChoosespot: true,
      spots: [
        { id: 1, height: 940, width: 610, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false },
        { id: 2, height: 940, width: 610, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false },
        { id: 3, height: 940, width: 610, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false },
        { id: 4, height: 940, width: 610, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false },
        { id: 5, height: 592, width: 938, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false },
        { id: 6, height: 592, width: 938, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false },
        { id: 7, height: 592, width: 938, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false },
        { id: 8, height: 592, width: 938, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false }
      ],
      showCheckout: false
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  onValueChange(e) {
    const id = e.target.value;
    // const isOccupied = this.state.spots.find(s => s.id == id)?.occupied;
    if (!this.state.slotsLocked) {
      const id = e.target.value;
      const spots = this.state.spots.map(
        spot => ({
          ...spot,
          checked: spot.id === Number(id) ? true : false
        })
      )
      this.setState({
        // selectedOption: e.target.value
        spots,
        uploadValidErr: false,
        slotid: id
      });
      // this.showChoosespot();
    }
    this.showTransactiondetails(id);
  }

  handleDropdownChange(e) {
    this.setState({
      selectadspot: e.target.value
    });
  }

  async getImages() {
    // let slotsData = await axios.get(`${CONFIG.API_END_POINT}api/v1/common/getImages?&id=${this.props.id}` + `&jobcloseOn=${this.state.jobcloeson}`);
    let slotsData = await axios.get(CONFIG.API_END_POINT + 'api/v1/common/getImages', {
      params: { id: this.props.id, jobcloseOn: this.state.jobcloeson }
    }) 
    slotsData = slotsData.data?.success?.slotimages;
    const occupiedSlots = slotsData && Object.keys(slotsData);
    const updateSlotsData = this.state.spots.map(
      async spot => {
        if (occupiedSlots?.includes(spot.id.toString())) {
          return {
            ...spot,
            occupied: true,
            img: await getImgBase64(slotsData[spot.id])
          }
        } else {
          return spot;
        }
      }
    )
    const slots = await Promise.all(updateSlotsData)
    this.setState({ spots: slots })
  }

  async componentDidMount() {
    const mosqueId = this.props.id;
    try {
      // Prayer TIME API STARTS
      const res = await axios.get(CONFIG.API_END_POINT + 'api/v1/common/getPrayerTimes?&id=' + mosqueId + '&year=2020&month=12');
      const prayerTime = res.data.success.PrayerTimesValues;
      // Prayer TIME API ENDS
      this.getFilleradvertiser();
      this.getPrice(mosqueId);
      this.setState({ prayerTimes: prayerTime, mosqueName: this.props.name, })
    } catch (err) {
      console.log(err)
    }
  }



  getPrice = async mosqueId => {
    try {
      await axios.get(CONFIG.API_END_POINT + 'api/v1/common/getPrice?id=' + mosqueId).then(async (response) => {
        const data = response.data?.success
        const batch = response.data?.success?.batchDetails[0]
        this.setState({
          forperiod: batch.forPeriod,
          formonth: batch.forMonth,
          jobcloeson: batch.adAcceptTill,
          AverageJumaAttendees: data.AverageJumaAttendees,
          BackgroundColor: data.BackgroundColor,
          MosqueCapacity: data.MosqueCapacity,
          Quantity: data.Quantity,
          price: data.price,
          headerImage: await getImgBase64(data.headerImage)
        })
        this.getImages()
      })
    } catch (err) {
      console.log(err)
    }
  }



  showUploadView = (show, e) => {
    e.preventDefault();
    const selectedSpot = this.state.spots.find(s => s.checked === true)?.id;
    if (!selectedSpot || !this.state.filleradvertiser) {
      if (!selectedSpot) {
        this.setState({ uploadValidErr: true, upload1ValidErr: false })
      } else {
        this.setState({ upload1ValidErr: true, uploadValidErr: false })
      }

    } else {
      this.setState({ showUploadView: show })
    }
  };


  // designerCheckout = () => {
  //   this.props.history.push('/businessuser', {
  //     selected: true
  //   })
  // }

  // proceedCheckout = () => {
  //   this.props.history.push('/businessuser', {
  //     selected: false
  //   })
  // }



  getFilleradvertiser = () => {
    const apiData = {
      page: 1,
    }
    axios.get(CONFIG.API_END_POINT + 'api/v1/common/FillerAdvertisers', {
      params: apiData
    }).then((response) => {
      const filler = response.data.success
      this.setState({
        filler: filler,
        BusinessID: filler.BusinessID
      })
      
    })
  }

  fillerChange = (event) => {
    this.setState({
      filleradvertiser: event.target.value,
      upload1ValidErr: false
    })
  }


  validateImg = async imgObj => {
    const image = imgObj;
    return new Promise((res, rej) => {
      if (!image) {
        alert("Please select image");
        rej(false);
      }
      if (!image?.name.match(/\.(jpg|jpeg|png)$/)) {
        alert("Only JPG, JPED and PNG files are allowed");
        rej(false);
      }
      let img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = async () => {
        const validationObj = this.state.spots.find(s => s.checked === true);
        // const validationObj = spots.find(spot => spot.id === selectedSpot);
        if (img.width < validationObj.width || img.height < validationObj.height) {
          alert("Please check minimum image resolution")
          rej(false);
        } else {
          const imgAspectRatio = img.width / img.height;
          const validAspectRatio = validationObj.width / validationObj.height;
          const validAspectObj = {
            min: validAspectRatio - (0.25 * validAspectRatio),
            max: validAspectRatio + (0.25 * validAspectRatio),
          }
          const imageDPI = img.height / validationObj.inches.h;
          if (imageDPI < 300) {
            alert("Image less than 300 DPI, please upload image with greater than 300 DPI or work with designer");
            rej(false);
          }
          if (imgAspectRatio < validAspectObj.min || imgAspectRatio > validAspectObj.max) {
            alert(`Aspect ratio of the image not as expected. Image will not print correctly. Please work with the designer to get the best image`)
          }
          res(true);
        }
      }
    })
  };

  onFileChange = obj => {
    const imageObj = obj.target.files[0];
    // const mosqueId = localStorage.getItem('mosqueId');
    const checkedSpot = this.state.spots.find(s => s.checked === true).id;
    if (imageObj) {
      const reader = new FileReader();
      reader.readAsDataURL(imageObj);
      reader.onload = async () => {
        this.validateImg(imageObj)
          .then(async res => {
            this.setState({ slotsLocked: true })
            const image = URL.createObjectURL(imageObj);
            const formData = new FormData();
            formData.append('slotImage', imageObj);
            formData.append('slotNumber', checkedSpot);
            formData.append('Mosqueid', this.props.id);
            // formData.append('totalslot', 8);
            formData.append('jobcloseOn', this.state.jobcloeson);
            formData.append('Iqamaschedule', this.state.formonth);
            formData.append('BusinessID', this.state.filleradvertiser);
            try {
              await axios.post(CONFIG.API_END_POINT + 'api/v1/common/uploadFillerImages', formData)
                .then((res) => {
                  if (res.data.success) {
                    alert("Image uploaded successfully")
                    window.location.reload(false)
                  } else {
                    alert("Image could not be uploaded")
                  }
                })
              const spots = this.state.spots.map(
                spot => (
                  spot.id === Number(checkedSpot)
                    ? { ...spot, img: image, occupied: true }
                    : spot
                )
              );
              this.setState({ spots, showFinalPreview: true })
              // setShowFinalPreview(true);
              // this.showCheckout()
            } catch (err) {
              console.log(err)
            }

          })
          
      }
    }
    // setFileList(newFileList);
  };


  showCheckout = () => {
    this.setState({ showCheckout: true })
  };


  showDesign = () => {
    const selectedSpot = this.state.spots.find(s => s.checked === true)?.id;
    if (!selectedSpot) {
      this.setState({ uploadValidErr: true })
    } else {
      this.setState({ showDesign: true })
    }
  };


  clickHere = () => {
    this.props.history.push('/transactiondetail', {
      jobcloseson: this.state.jobcloeson,
      slotnumber: this.state.slotnumber,
      mosqueid: this.props.id
    })
  }


  orderSummary = () => {
    this.props.history.push('/ordersummary/' + this.props.id, {
      id: this.props.id
    })
  }

  getTransactionalDetails = (id = 0) => {
    const spotId = id;
    const isOccupied = this.state.spots.find(s => s.id === Number(spotId) && s.occupied)?.id;
    axios.get(CONFIG.API_END_POINT + 'api/v1/admin/common/TransactionOnSlot', {
      params: {
        MosqueID: this.props.id || this.state.filleradvertiser,
        jobcloseOn: this.state.jobcloeson,
        slotNumber: isOccupied
      }
    })
      .then((response) => {
        if (response.data.success) {
          const data = response.data.success
          this.setState({
            transactionid: data.transactionID,
            businessname: data.BusinessTitle,
            paymentdate: data.paymentDate,
            month: data.Iqamaschedule,
            slotnumber: data.slotNumber,
            direction: data.Direction,
            amount: data.amount,
            paymentprocessor: data.PaymentProcessor,
            city: data.city,
            state: data.state,
            zipcode: data.zipCode,
          })
        } else {
          alert("No Transaction details")
        }
      })
  }


  showTransactiondetails = (id = 0) => {
    const spotId = id;
    const isOccupied = this.state.spots.find(s => s.id === Number(spotId) && s.occupied)?.id;
    if (!isOccupied) {
      this.setState({ showChoosespot: true, showTransactiondetails: false })
    } else {
      this.setState({ showTransactiondetails: true, showChoosespot: false })
      this.getTransactionalDetails(id)
    }
  };


  // showChoosespot = () => {
  //   const selectedSpot = this.state.spots.find(s => s.occupied === true)?.id;
  //   if (selectedSpot) {
  //     this.setState({ showChoosespot: true, showTransactiondetails: false })

  //   } else {
  //     this.setState({ showTransactiondetails: true, showChoosespot: false })
  //   }
  // };

  printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 306);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
      ;
  }



  deleteSpot = () => {
    const spotId = this.state.slotid
    const isOccupied = this.state.spots.find(s => s.id === Number(spotId) && s.occupied)?.id;
    let obj = {}
    obj.jobcloseOn = this.state.jobcloeson
    obj.Iqamaschedule = this.state.formonth
    obj.slotNumber = isOccupied
    obj.id = this.props.id

    axios.post(CONFIG.API_END_POINT + 'api/v1/common/Deleteslot', obj).then((response) => {
      if (response.data.success) {
        alert("Spot has been deleted")
        window.location.reload(false)
      } else {
        alert("Spot could not be deleted")
      }
    })
  }



  renderTableHeader() {
    return (
      <tr className="head-size" style={{ textAlign: "center", fontSize: "6.5px" }}>
        <th>Date</th>
        <th><span style={{ marginRight: '2px' }}>Fajr</span><span className="title-second">/</span><span className="title-second" style={{ marginLeft: "2px" }}>Iqama</span></th>
        <th>Sunrise</th>
        <th><span style={{ fontSize: "6px" }}>Dhuhr</span><span className="title-second">/</span><span className="title-second" >Iqama</span></th>
        <th><span style={{ marginRight: '2px' }}>Asr</span><span className="title-second">/</span><span className="title-second" style={{ marginLeft: "2px" }}>Iqama</span></th>
        <th style={{ textAlign: "left", fontSize: '5.5px' }}><span >Maghrib</span><span className="title-second">/</span><span className="title-second" >Iqama</span></th>
        <th><span style={{ marginRight: '2px' }}>Isha</span><span className="title-second">/</span><span className="title-second" style={{ marginLeft: "2px" }}>Iqama</span></th>
      </tr>
    )
  }

  renderTableData() {
    return this.state.prayerTimes?.map((time, idx) => {
      return (
        <tr className="head-size">
          <td style={{ fontSize: "6.5px", textAlign: "center" }}>{time.Date}</td>
          <td style={{ textAlign: "center", fontSize: '7px' }}><span style={{ marginRight: '2px' }}>{time.fajrTime}</span><span>/</span><span className="title-second" style={{ marginLeft: "2px" }}>{time.fajrIqama}</span></td>
          <td style={{ fontSize: "6.5px", textAlign: "center" }}>{time.sunriseTime}</td>
          {
            time.Day === 'Fri' ?
              (<td style={{ textAlign: "center", fontSize: '5px' }}><span >{time.jummaIqama1}</span><span className="title-second" >/{time.jummaIqama2}</span><span>/{time.jummaIqama3}</span></td>) : (<td style={{ textAlign: "center", fontSize: "6.5px" }}><span style={{ marginRight: '2px' }}>{time.dhuhrIqama}</span><span >/</span><span className="title-second" style={{ marginLeft: "2px" }}>{time.dhuhrIqama}</span></td>)
          }
          <td style={{ textAlign: "center", fontSize: "6.5px" }}><span style={{ marginRight: '2px' }}>{time.asrIqama}</span><span >/</span><span className="title-second" style={{ marginLeft: "2px" }}>{time.asrIqama}</span></td>
          <td style={{ textAlign: "center", fontSize: "6.5px" }}><span style={{ marginRight: '2px' }}>{time.maghribIqama}</span><span >/</span><span className="title-second" style={{ marginLeft: "2px" }}>{time.maghribIqama}</span></td>
          <td style={{ textAlign: "center", fontSize: "6.5px" }}><span style={{ marginRight: '2px' }}>{time.ishaIqama}</span><span >/</span><span className="title-second" style={{ marginLeft: "2px" }}>{time.ishaIqama}</span></td>
        </tr>
      )
    })
  }


  render() {
    const { config } = this.state;

    return (
      <>
        <div style={{ paddingLeft: "95px", backgroundColor: "#EEF2F6", paddingRight: "44px" }}>
          <Headerad />
        </div>
        <div style={{ paddingLeft: "93px", backgroundColor: "white", }}>
          <Adminheader />
        </div>
        <div className="bodyy" style={{ height: "1200px" }}>
          <div className="tempcontainer" style={{ backgroundColor: "white", width: "76.5%", marginLeft: "13%" }}>
            <h5 style={{ color: "#374767", padding: "13px", borderBottom: "solid 1px #e4e5e7", fontSize: "18px", fontWeight: "500", fontFamily: "WebFonts" }}>Order<button trailingIcon="picture_as_pdf" label="Resume" style={{ padding: "1px 5px", lineHeight: "1.5", border: "1px solid transparent", fontFamily: "'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif", backgroundColor: "#558B2F", float: "right", color: "white", fontSize: "12px", borderRadius: "2px" }} onClick={this.printDocument}>
              Export to pdf </button></h5>
            <Row>
              <Row style={{ marginLeft: "15px", marginTop: "20px" }}  >
                {/* <div id="divToPrint" style={{ marginLeft: "15px" }}>
                  <div className="temp" style={{ backgroundColor: config.BackgroundColor }}>
                    <div class="radios">
                      <label>
                        <input type="radio" value="8" checked={this.state.spots[7].checked} onChange={this.onValueChange} />
                        <img className="temph5" src={this.state.spots[7].occupied ? this.state.spots[7].img : temph8} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="7" checked={this.state.spots[6].checked} onChange={this.onValueChange} />
                        <img className="temph6" src={this.state.spots[6].occupied ? this.state.spots[6].img : temph7} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="6" checked={this.state.spots[5].checked} onChange={this.onValueChange} />
                        <img className="temph7" src={this.state.spots[5].occupied ? this.state.spots[5].img : temph6} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="5" checked={this.state.spots[4].checked} onChange={this.onValueChange} />
                        <img className="temph8" src={this.state.spots[4].occupied ? this.state.spots[4].img : temph5} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="1" checked={this.state.spots[0].checked} onChange={this.onValueChange} />
                        <img className="temk1" src={this.state.spots[0].occupied ? this.state.spots[0].img : temph1} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="2" checked={this.state.spots[1].checked} onChange={this.onValueChange} />
                        <img className="temk2" src={this.state.spots[1].occupied ? this.state.spots[1].img : temph2} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="3" checked={this.state.spots[2].checked} onChange={this.onValueChange} />
                        <img className="temk3" src={this.state.spots[2].occupied ? this.state.spots[2].img : temph3} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="4" checked={this.state.spots[3].checked} onChange={this.onValueChange} />
                        <img className="temk4" src={this.state.spots[3].occupied ? this.state.spots[3].img : temph4} data-taken="False" />
                      </label>

                      <table style={{ tableLayout: 'fixed', top: '70px', left: '233px', }} className="time iqamak1-table iqamak1print">
                        <tbody>
                          <img src={iqama}style={{height: "490px", width: "346px" }} />
                        </tbody>
                      </table>
                      <img src={header} className="timek1 timek1print" />
                    </div>
                  </div>
                  <Col></Col><br />
                </div> */}
                <div id="divToPrint" style={{ marginLeft: "15px" }}>

                  <div className="viewordertemp" style={{ backgroundColor: config.BackgroundColor }}>
                    <div class="radios">
                      <label>
                        <input type="radio" value="8" checked={this.state.spots[7].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temph5" src={this.state.spots[7].occupied ? this.state.spots[7].img : temph8} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="7" checked={this.state.spots[6].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temph6" src={this.state.spots[6].occupied ? this.state.spots[6].img : temph7} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="6" checked={this.state.spots[5].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temph7" src={this.state.spots[5].occupied ? this.state.spots[5].img : temph6} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="5" checked={this.state.spots[4].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temph8" src={this.state.spots[4].occupied ? this.state.spots[4].img : temph5} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="1" checked={this.state.spots[0].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temk1" src={this.state.spots[0].occupied ? this.state.spots[0].img : temph1} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="2" checked={this.state.spots[1].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temk2" src={this.state.spots[1].occupied ? this.state.spots[1].img : temph2} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="3" checked={this.state.spots[2].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temk3" src={this.state.spots[2].occupied ? this.state.spots[2].img : temph3} data-taken="False" />
                      </label>
                      <label>
                        <input type="radio" value="4" checked={this.state.spots[3].checked} onChange={this.onValueChange} />
                        <img alt="alternate" className="temk4" src={this.state.spots[3].occupied ? this.state.spots[3].img : temph4} data-taken="False" />
                      </label>

                      <table style={{ tableLayout: 'fixed', top: '96px', left: '259px', }} className="time iqamak1-table iqamak1print">
                        <thead>
                          {this.renderTableHeader()}
                        </thead>
                        <tbody>
                        <img alt="alternate" src={iqama}style={{height: "472px", width: "346px" }} />
                        </tbody>
                      </table>
                      <img alt="alternate" src={header} className="timek1 timek1print" />
                    </div>
                  </div>
                  {/* </Col><br /> */}
                  <Col></Col><br />

                </div>

                {
                  !this.state.showCheckout && !this.state.showDesign && (this.state.showUploadView ?
                    (
                      <Col className="noprint" style={{ float: "right" }}>
                        <Row style={{ marginLeft: "10px", }}>
                          <Col><h2 style={{ fontSize: "30px", fontWeight: "700", lineHeight: "1.3", fontFamily: "Arial", color: "#374767", }}>{this.props.location.state.mosqueName}</h2></Col>
                        </Row>
                        {/* <Row>
                          <Col><h2 style={{ fontSize: "14.45px", marginLeft: "60px", lineHeight: "0.3", fontFamily: "Arial" }}>Average Jumma Attendees: {config.AverageJumaAttendees}</h2></Col>
                        </Row>
                        <Row>
                          <Col><h2 style={{ fontSize: "14.45px", marginLeft: "60px", lineHeight: "2.5", fontFamily: "Arial" }}>Total Capacity: {config.MosqueCapacity}</h2></Col>
                        </Row> */}
                        <Row style={{ marginLeft: "10px", }}>
                          <Col><h4 style={{ fontSize: "17px", lineHeight: "2.5", fontFamily: "Arial", fontWeight: "700", color: "#374767", }}>Iqama Schedule</h4></Col>
                        </Row>
                        <Row style={{ marginLeft: "10px", }}>
                          <Col><h2 style={{ fontSize: "14.45px", lineHeight: "0.1", fontFamily: "Arial", color: "#374767", }}>For Period<strong style={{ marginLeft: "3px", color: "#374767", }}>{this.state.forperiod}</strong></h2></Col>
                        </Row>
                        <Row style={{ marginLeft: "10px", }}>
                          <Col>
                            <Row >
                              <Col md={6}>
                                <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767", }}>Job Closes On:</h4><span style={{ fontFamily: "Arial", fontSize: "17px", color: "#374767", }}>{this.state.jobcloeson}</span>
                              </Col>
                              <Col md={6}>
                                <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767", }} >Quantity:</h4>{!this.state.Quantity ? null : <span style={{ fontFamily: "Arial", fontSize: "17px", color: "#374767", }}>{this.state.Quantity}&nbsp;pieces</span>}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {
                          this.state.showChoosespot && (
                            <div style={{ float: "right" }}>
                              <Row style={{ marginLeft: "10px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767", }}>Choose a spot</h4>
                                  <select style={{ border: this.state.uploadValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "100%", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", height: "40px" }} id="dropdown" value={this.state.spots.find(i => i.checked === true)?.id} onChange={this.onValueChange}  >
                                    <option value style={{ display: "none" }}>Select Ad Spot</option>
                                    {
                                      this.state.spots.map(
                                        spot => !spot.occupied && (
                                          <option key={spot.id} value={spot.id}>Ad Spot # {spot.id}</option>
                                        )
                                      )
                                    }
                                  </select>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", width: "200px", color: "#374767", }}>Filler Advertisers</h4>
                                  <div style={{ width: "95%" }}>
                                    <select style={{ border: this.state.upload1ValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "100%", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", height: "40px" }} id="dropdown" value={this.state.filleradvertiser} onChange={this.fillerChange}  >
                                      <option value style={{ display: "none" }}>Choose advertiser</option>
                                      {
                                        this.state.filler?.map((name => (
                                          <option key={name.id} value={name.id}>{name.BusinessTitle}</option>
                                        )))
                                      }
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: "20px", marginLeft: "10px" }}>
                                <Col>
                                  <div style={{ width: "250px" }} >
                                    <button style={{background: "transparent", border: "none"}} onClick={e => this.showUploadView(false, e)}><img alt="alternate" src={upload} /></button> </div>
                                  <h6 style={{ marginTop: "10px", fontWeight: "700", color: "#374767", fontFamily: "WebFonts" }}>Upload your designs</h6>
                                  <p style={{ marginTop: "15px" }}>Put an exisiting design on <br /><div>your choice of card.</div></p>

                                  <button onClick={e => this.showUploadView(false, e)} className="style" style={{ marginLeft: "0", background: "transparent", border: "none" }} >Upload now</button>
                                </Col>
                              </Row>

                            </div>
                          )
                        }
                        {
                          this.state.showTransactiondetails && (this.state.transactionid ?

                            <div style={{ float: "right", fontFamily: "WebFonts" }}>
                              <hr />
                              <Row style={{ marginLeft: "10px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "24px", lineHeight: "2.5",  fontWeight: "700", color: "#374767" }}>Transaction</h4>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "24px", lineHeight: "2.5",  fontWeight: "700", color: "#374767" }}>{this.state.transactionid}</h4>
                                </Col>
                              </Row>

                              <Row style={{ marginLeft: "10px", }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Business name</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.businessname}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Payment date</h4><span style={{  fontSize: "17px", color: "#374767", }}>{this.state.paymentdate?.split('T')[0]}</span>
                                </Col>
                              </Row>

                              <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Description</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.formonth} - Ad Spot # {this.state.slotnumber}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Status</h4><span style={{  fontSize: "17px", color: "#374767", }}>Payment Processed</span>
                                </Col>
                              </Row>


                              <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Direction</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.direction}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Amount</h4>{!this.state.amount ? null : <span style={{  fontSize: "17px", color: "#374767", }}>${this.state.amount}</span>}
                                </Col>
                              </Row>


                              <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Payment processor</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.paymentprocessor}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Payment reciept</h4><button onClick={this.clickHere} style={{ textDecoration: "none", color: "#558B2F", border: "none", background: "transparent" }}><span style={{  fontSize: "17px", }}>Click Here</span></button>
                                </Col>
                              </Row>


                              <hr />
                              <Row style={{ marginLeft: "10px", marginTop: "20px" }}>
                                <Col>
                                  <h4 style={{  fontWeight: "700", color: "#374767", }}>Advertiser details</h4>
                                </Col>
                              </Row>



                              <Row style={{ marginLeft: "10px", marginTop: "10px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Title</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.businessname}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>City</h4><span style={{  fontSize: "17px", color: "#374767", }}>{this.state.city}</span>
                                </Col>
                              </Row>


                              <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>State</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.state}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Zipcode</h4><span style={{  fontSize: "17px", color: "#374767", }}>{this.state.zipcode}</span>
                                </Col>
                              </Row>

                              <hr />

                              <Row style={{ marginLeft: "10px" }}>
                                <Col md={6}>
                                  <Row>
                                    <Col>
                                      <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767" }}>Choose a spot</h4>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <select style={{ border: this.state.uploadValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "60px", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", }} id="dropdown" value={this.state.spots.find(i => i.checked === true)?.id} onChange={this.onValueChange}  >
                                        <option value style={{ display: "none" }}>Select Ad Spot</option>
                                        {
                                          this.state.spots.map(
                                            spot => spot.occupied && (
                                              <option key={spot.id} value={spot.id}>Ad Spot # {spot.id}</option>
                                            )
                                          )
                                        }
                                      </select>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={6} style={{marginTop: "28px"}}>
                                  <Row>
                                    <Col>
                                      
                                    </Col>
                                  </Row>
                                  <button className="danger" onClick={this.deleteSpot}>Delete Spot</button>
                                </Col>
                              </Row>

                              <Row style={{ marginTop: "20px", marginLeft: "10px" }}>
                                <Col>
                                  <div style={{ width: "250px" }} >
                                    <button style={{background: "transparent", border: "none"}} onClick={e => this.showTransactiondetails(false, e)}><img alt="alternate" src={upload} /></button> </div>
                                  <h6 style={{ marginTop: "10px", fontWeight: "700", color: "#374767", fontFamily: "WebFonts" }}>Upload your designs</h6>
                                  <p style={{ marginTop: "15px" }}>Put an exisiting design on <br /><div>your choice of card.</div></p>

                                  <button  onClick={e => this.showTransactiondetails(false, e)} style={{ display: "block", color: "#D21A1A", fontSize: "16px", fontWeight: "700", background: "transparent", fontFamily: "Arial", textAlign: "left", border: "none" }} >Upload now</button>


                                </Col>
                              </Row>

                            </div>

                            :
                            <div style={{fontFamily: "WebFonts"}}>
                              <hr />
                              <Row style={{ marginLeft: "10px", marginTop: "20px" }}>
                                <Col>
                                  <h4 style={{  fontWeight: "700", color: "#374767", }}>Advertiser details</h4>
                                </Col>
                              </Row>



                              <Row style={{ marginLeft: "10px", marginTop: "10px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Title</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.businessname}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>City</h4><span style={{  fontSize: "17px", color: "#374767", }}>{this.state.city}</span>
                                </Col>
                              </Row>


                              <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>State</h4><span style={{  fontSize: "15px", color: "#374767", }}>{this.state.state}</span>
                                </Col>
                                <Col md={6}>
                                  <h4 style={{ fontSize: "17px",  fontWeight: "700", color: "#374767" }}>Zipcode</h4><span style={{  fontSize: "17px", color: "#374767", }}>{this.state.zipcode}</span>
                                </Col>
                              </Row>

                              <hr />

                              <Row style={{ marginLeft: "10px" }}>
                                <Col md={7}>
                                  <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767" }}>Choose a spot</h4>
                                  <select style={{ border: this.state.uploadValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "60px", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", }} id="dropdown" value={this.state.spots.find(i => i.checked === true)?.id} onChange={this.onValueChange}  >
                                    <option value style={{ display: "none" }}>Select Ad Spot</option>
                                    {
                                      this.state.spots.map(
                                        spot => spot.occupied && (
                                          <option key={spot.id} value={spot.id}>Ad Spot # {spot.id}</option>
                                        )
                                      )
                                    }
                                  </select>
                                </Col>
                                <Col md={5} style={{marginTop: "28px"}}>

                                  

                                  <button className="danger" onClick={this.deleteSpot}>Delete Spot</button>
                                </Col>
                              </Row>

                              <Row style={{ marginTop: "20px", marginLeft: "10px" }}>
                                <Col>
                                  <div style={{ width: "250px" }} >
                                    <button style={{background: "transparent", border: "none"}} onClick={e => this.showTransactiondetails(false, e)}><img alt="alternate" src={upload} /></button> </div>
                                  <h6 style={{ marginTop: "10px", fontWeight: "700", color: "#374767", fontFamily: "WebFonts" }}>Upload your designs</h6>
                                  <p style={{ marginTop: "15px" }}>Put an exisiting design on <br /><div>your choice of card.</div></p>

                                  <button onClick={e => this.showTransactiondetails(false, e)} style={{ display: "block", color: "#D21A1A", fontSize: "16px", fontWeight: "700", background: "transparent", fontFamily: "Arial", textAlign: "left", border: "none" }} >Upload now</button>


                                </Col>
                              </Row>
                            </div>
                          )
                        }
                      </Col>
                    )
                    :
                    (
                      <Col style={{ float: "right" }}>

                        <div>
                          <a href="/template">
                            <button onClick={e => this.showUploadView(true, e)} className="bk" style={{ fontWeight: "bolder", width: "95px", height: '40px', }}>
                              <i class="fas fa-arrow-left" style={{ marginRight: "5px" }}></i>Back
                                                </button>
                          </a>
                          <Col className="h2" >

                            <h2>Upload Your Image</h2><br />
                            <ol className="ol">
                              <li>Accepts Jpg, Jpeg and Png files only.</li>
                              <li>Image resolution should be at least 300 dpi.</li>
                            </ol>
                            <div>
                              <div className="fil">
                                <div>
                                  <input type="file" onChange={this.onFileChange} required />
                                  <img alt="alternate" src={this.state.file} />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>

                      </Col>
                    ))
                }
              </Row>
            </Row>
          </div>
          <button style={{
            marginBottom: "10px", backgroundColor: "#428bca", borderColor: "#3b7cb4", color: "white", borderRadius: "2px", padding: "1px 5px", fontSize: "12px", lineHeight: "1.5", display: "inline-block", border: "1px solid transparent", marginLeft: "16px", fontWeight: "400", fontFamily: "Arial", position: "absolute", bottom: -370, left: 198
          }} onClick={this.orderSummary}>Order summary</button>
        </div>
      </>
    );
  }
}

export default withRouter(DemoTemplate)