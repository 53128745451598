import React from 'react';
import { Input, FormGroup, Label, Row, Col, } from 'reactstrap';



export default class Checkbox extends React.Component {
  constructor() {
    super();

    this.state = { checked: true };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChecked() {
    this.setState({
      checked: !this.state.checked
    })
    
    
  }

  render() {
    const content = !this.state.checked
      ? <div>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input id="password" name="password" type="password" value={this.state.password} onChange={this.passwordhandler} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required />

            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="password">Confirm Password</Label>
              <Input id="password" name="password" type="password" value={this.state.confpassword} onChange={this.confpasswordhandler} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required />

            </FormGroup>
          </Col>
        </Row>
      </div>
      : null;

    return <div>
      {content}
      <div>

        <input
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleChecked} />
        <label style={{marginLeft: "5px"}}>Checkout as Guest</label>
      </div>


    </div>;
  }
}