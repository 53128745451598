import React, { useState } from 'react';
import './Button.css';
import '../admin/Logout.css';
import { Link } from 'react-router-dom';
import { LogoutAll } from '../utils/Helper';
// import LoaderGif from '../assets/images/loader3.gif'
import { LoadingOutlined } from '@ant-design/icons';

export function Loginbutton() {
  const [isLoading, setIsLoading] = useState(false)
  const token = localStorage.getItem('token') || localStorage.getItem('businesstoken') || localStorage.getItem("mosquetoken");
  const logout = () => {
    setIsLoading(true)
    LogoutAll()
    setIsLoading(false)
  };

  return (
    <>
    {/* {
      isLoading &&
      <img alt="" src={LoaderGif} style={{ top: "30%", position: "absolute", left: "10%", height: "200px", width: "500px", zIndex: "1000", }} /> // background: 'rgb(255,255,255,0.6)'
    } */}
    <div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "20rem" }}>
          {isLoading &&
            <LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
          }
        </div>
      {
        token === null ? (
          <Link to='login'>
            <button className="headerlog logout-button"  style={{  boxShadow: "none",  fontSize: "13px", fontWeight: "700", lineHeight: "20px", padding: "10px 30px", textShadow: "none", display: "inline-block", borderRadius: "2px", fontFamily: "Helvetica"}}>Login</button>
          </Link>
        ) : (<span className="headerlog logout-button" onClick={_ => logout()} style={{  boxShadow: "none",  fontSize: "13px", fontWeight: "700", lineHeight: "20px", padding: "10px 30px", textShadow: "none", display: "inline-block", borderRadius: "2px", fontFamily: "Helvetica", height:"40px", cursor: "pointer"}}>Logout</span>)
      }
    </>
  );
}