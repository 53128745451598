import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './template1.css';
import '../../../../Mosque/NewMosque.css';
import teme1 from '../../../../../images/temp1-copycopy.jpg';
import teme2 from '../../../../../images/temp2-copycopy.jpg';
import teme3 from '../../../../../images/temp3-copycopy.jpg';
import teme4 from '../../../../../images/temp4-copycopy.jpg';
import teme5 from '../../../../../images/temp5-copycopy.jpg';
import teme6 from '../../../../../images/temp6-copycopy.jpg';
import teme7 from '../../../../../images/temp7-copycopy.jpg';
import teme8 from '../../../../../images/temp8-copycopy.jpg';
import upload from '../../../../../images/file-upload-solid.png';
import { CONFIG } from '../../../../../utils/constants';
import axios from '../../../../../utils/axios';
import { withRouter } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { getImgBase64 } from '../../../../../utils/Helper';
import { LoadingOutlined } from '@ant-design/icons';
import sunrise from '../../../../../images/sunrise.png';
import calendarTxt from '../../../../../images/calendarTxt-19-09-2022.png'
// import LoaderGif from '../../../../../assets/images/loader3.gif'
// import moment from 'moment';

class OrderTemplate2 extends Component {
  constructor(props) {
    super(props);

    this.state = {

      selectadspot: "",
      selectedOption: "",
      prayerTimes: [],
      config: {},
      showUploadView: true,
      showChoosespot: true,
      showTransactiondetails: false,
      filler: [],
      isLoading: false,
      // spots: [
      //   { id: 1, height: 206, width: 134, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false, isActive: false },
      //   { id: 2, height: 206, width: 134, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false, isActive: false },
      //   { id: 3, height: 206, width: 134, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false, isActive: false },
      //   { id: 4, height: 206, width: 134, inches: { w: 2, h: 3.1 }, occupied: false, img: null, checked: false, isActive: false },
      //   { id: 5, height: 130, width: 206, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false, isActive: false },
      //   { id: 6, height: 130, width: 206, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false, isActive: false },
      //   { id: 7, height: 130, width: 206, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false, isActive: false },
      //   { id: 8, height: 130, width: 206, inches: { w: 3.1, h: 2 }, occupied: false, img: null, checked: false, isActive: false }
      // ],
      spots: [
        { id: 1, hei: 2.1, wid: 1.35, height: 288, width: 192, inches: { w: 2, h: 3 }, occupied: false, img: null, checked: false, isActive: false },
        { id: 2, hei: 2.1, wid: 1.35, height: 288, width: 192, inches: { w: 2, h: 3 }, occupied: false, img: null, checked: false, isActive: false },
        { id: 3, hei: 2.1, wid: 1.35, height: 288, width: 192, inches: { w: 2, h: 3 }, occupied: false, img: null, checked: false, isActive: false },
        { id: 4, hei: 2.1, wid: 1.35, height: 288, width: 192, inches: { w: 2, h: 3 }, occupied: false, img: null, checked: false, isActive: false },
        { id: 5, hei: 1.3, wid: 2.1, height: 187.2, width: 288, inches: { w: 3, h: 1.95 }, occupied: false, img: null, checked: false, isActive: false },
        { id: 6, hei: 1.3, wid: 2.1, height: 187.2, width: 288, inches: { w: 3, h: 1.95 }, occupied: false, img: null, checked: false, isActive: false },
        { id: 7, hei: 1.3, wid: 2.1, height: 187.2, width: 288, inches: { w: 3, h: 1.95 }, occupied: false, img: null, checked: false, isActive: false },
        { id: 8, hei: 1.3, wid: 2.1, height: 187.2, width: 288, inches: { w: 3, h: 1.95 }, occupied: false, img: null, checked: false, isActive: false }
      ],
      showCheckout: false
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  onValueChange(e) {
    const id = e.target.value;
    // const isOccupied = this.state.spots.find(s => s.id == id)?.occupied;
    if (!this.state.slotsLocked) {
      const id = e.target.value;

      const spots = this.state.spots.map(
        spot => ({
          ...spot,
          checked: spot.id === Number(id) ? true : false,
          isActive: spot.id === Number(id) ? true : false
        })
      )
      this.setState({
        // selectedOption: e.target.value
        spots,
        uploadValidErr: false,
        slotid: id
      });
      // this.showChoosespot();
    }
    this.showTransactiondetails(id);
  }


  handleDropdownChange(e) {
    this.setState({
      selectadspot: e.target.value
    });
  }


  async getImages() {
    // let slotsData = await axios.get(`${CONFIG.API_END_POINT}api/v1/common/getImages?&id=${this.props.id}` + `&jobcloseOn=${this.state.jobcloeson}`);
    this.setState({ isLoading: true }, async () => {
      let slotsData = await axios.get(CONFIG.API_END_POINT + 'api/v1/common/getImages', {
        params: { id: this.props.id, jobcloseOn: this.state.jobcloeson }
      })
      slotsData = slotsData.data?.success?.slotimages;
      const occupiedSlots = slotsData && Object.keys(slotsData);
      const updateSlotsData = this.state.spots.map(
        async spot => {
          if (occupiedSlots?.includes(spot.id.toString())) {
            return {
              ...spot,
              occupied: true,
              img: await getImgBase64(slotsData[spot.id])
            }
          } else {
            return spot;
          }
        }
      )
      const slots = await Promise.all(updateSlotsData)
      this.setState({ spots: slots, isLoading: false })
    })
  }

  async componentDidMount() {
    const mosqueId = this.props.id;
    const batchStatus = this?.props?.location?.state?.batchStatus
    const batchId = this?.props?.location?.state?.batchId;
    this.setState({ batchStatus: batchStatus })
    this.setState({ isLoading: true, batchId: batchId }, async () => {
      this.getFilleradvertiser();
      this.getPrice(mosqueId);
    })
  }


  getPrayerTimes = async (month, prayerYear) => {
    const mosqueId = this.props.id;
    const monthNo = new Date(`${month} 01 2020`)?.getMonth() + 1;
    try {
      // Prayer TIME API STARTS
      const res = await axios.get(CONFIG.API_END_POINT + 'api/v1/common/getPrayerTimes?&id=' + mosqueId + '&year=' + prayerYear + '&month=' + monthNo);
      const prayerTime = res?.data?.success;
      // Prayer TIME API ENDS
      this.setState({ prayerTimes: prayerTime, mosqueName: this.props.name, isLoading: false })
    } catch (err) {
      console.log(err)
    }
  };


  // formatTimeValue = time => {
  //   let output = '';
  //   if (time) {
  //     const timeArr = time.split(':');
  //     const hh = (timeArr[0]?.length === 1) ? `${timeArr[0]}` : timeArr[0].slice(1)
  //     const mm = (timeArr[1]?.length === 1) ? `0${timeArr[1]}` : timeArr[1];
  //     output = hh + ":" + mm;
  //   }
  //   return output;
  // };

  // formatTimeValue = time => {
  //   let output = '';
  //   if (time) {
  //     const timeArr = time.split(':');

  //     if(timeArr[0].length === 1 && timeArr[1]?.length === 1) {
  //       const hh = `${timeArr[0]}`
  //       const mm = `0${timeArr[1]}`
  //       output = hh + ":" + mm;
  //     } else {
  //       if(timeArr[0][0] === '0') {
  //         const hh = timeArr[0].slice(1)
  //         const mm = timeArr[1]
  //         output = hh + ":" + mm;
  //       } else {
  //         const hh = `${timeArr[0]}`
  //         const mm = timeArr[1]
  //         output = hh + ":" + mm;
  //       }
  //     }
  //   }
  //   return output;
  // };

  formatTimeValue = time => {
    let output = '';
    let hh = '';
    let mm = '';
    if (time) {
      const timeArr = time.split(':');

      if (timeArr[0]?.length === 1) {
        hh = `${timeArr[0]}`
      } else {
        if (timeArr[0][0] === '0') {
          hh = timeArr[0].slice(1)
        } else {
          hh = `${timeArr[0]}`
        }
      }

      if (timeArr[1]?.length === 1) {
        mm = `0${timeArr[1]}`
      } else {
        mm = `${timeArr[1]}`
      }

      output = hh + ':' + mm;
    }
    return output;
  };

  formatMonth = month => {
    let monthhh = '';
    if (month) {
      const monthh = month.length === 1 ? month : `0${month}`
      monthhh = monthh
    }
    return monthhh
  }

  getPrice = async mosqueId => {
    this.setState({ isLoading: true }, async () => {
      try {
        await axios.get(CONFIG.API_END_POINT + 'api/v1/common/getPriceForViewOrder?id=' + mosqueId + '&batchStatus=' + this.props?.location?.state?.batchStatus + '&batchId=' + this.state?.batchId).then(async (response) => {
          const data = response.data?.success
          const batchDetails = response.data?.success?.batchDetail?.forMonth;
          const batch = response.data?.success?.batchDetail
          const month = batch?.forMonth;
          const prayerYear = batch?.forPeriod.slice(7, 11)
          this.setState({
            batchDetails: batchDetails,
            forperiod: batch?.forPeriod,
            formonth: batch?.forMonth,
            jobcloeson: batch?.adAcceptTill,
            AverageJumaAttendees: data?.AverageJumaAttendees,
            BackgroundColor: data?.BackgroundColor,
            MosqueCapacity: data?.MosqueCapacity,
            Quantity: data?.Quantity,
            price: data?.price,
            headerImage: await getImgBase64(data?.headerImage),
            isLoading: false
          })
          this.getPrayerTimes(month, prayerYear);
          const newfor = this.state?.forperiod
          const newfor2 = newfor?.slice(0, 6)
          const newfor3 = newfor?.slice(7, 11)
          const newfor4 = newfor?.slice(11, 12)
          const newfor5 = newfor?.slice(12, 19)
          // const newfor6 = newfor?.slice(19, 23)
          const finalForPeriod = newfor2 + " " + newfor4 + " " + newfor5 + " " + newfor3
          this.setState({ finalForPeriod: finalForPeriod })
          this.getImages()
          this.setState({
            adMonth: this.state.jobcloeson?.slice(5, 8),
            adDate: this.state.jobcloeson?.slice(8, 11),
            adYear: this.state.jobcloeson?.slice(0, 4),
          })
        })
      } catch (err) {
        console.log(err)
      }
    })
  }

  getFilleradvertiser = () => {
    const apiData = {
      // page: 1,
    }
    axios.get(CONFIG.API_END_POINT + 'api/v1/common/FillerAdvertisers', {
      params: apiData
    }).then((response) => {
      const filler = response.data?.success
      this.setState({
        filler: filler,
        BusinessID: filler?.BusinessID
      })

    })
  }

  fillerChange = (event) => {
    this.setState({
      filleradvertiser: event.target.value,
      upload1ValidErr: false
    })
  }


  // designerCheckout = () => {
  //   this.props.history.push('/businessuser', {
  //     selected: true
  //   })
  // }

  // proceedCheckout = () => {
  //   this.props.history.push('/businessuser', {
  //     selected: false
  //   })
  // }

  showUploadView = (show, e) => {
    e.preventDefault();
    const selectedSpot = this.state.spots.find(s => s.checked === true)?.id;
    if (!selectedSpot || !this.state.filleradvertiser) {
      if (!selectedSpot) {
        this.setState({ uploadValidErr: true, upload1ValidErr: false })
      } else {
        this.setState({ upload1ValidErr: true, uploadValidErr: false })
      }

    } else {
      this.setState({ showUploadView: show })
    }
  };

  showUploadView2 = (show, e) => {
    window.scrollTo(0, 0)
    e.preventDefault();
    const selectedSpot = this.state.spots.find(s => s.checked === true)?.id;
    if (!selectedSpot) {
      if (!selectedSpot) {
        this.setState({ uploadValidErr: true })
      }
    } else {
      this.setState({ showUploadView: show })
    }
  };

  validateImg = async imgObj => {
    const image = imgObj;
    return new Promise((res, rej) => {
      if (!image) {
        alert("Please select image");
        rej(false);
      }
      if (!image?.name.match(/\.(jpg|jpeg|png)$/)) {
        alert("Only JPG, JPED and PNG files are allowed");
        rej(false);
      }
      let img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = async () => {
        const validationObj = this.state.spots.find(s => s.checked === true);
        // const validationObj = spots.find(spot => spot.id === selectedSpot);
        if (img.width < validationObj.width || img.height < validationObj.height) {
          alert("Please check minimum image resolution")
          rej(false);
        } else {
          const imgAspectRatio = img.width / img.height;
          const validAspectRatio = validationObj.width / validationObj.height;
          const validAspectObj = {
            min: validAspectRatio - (0.25 * validAspectRatio),
            max: validAspectRatio + (0.25 * validAspectRatio),
          }
          const imageDPI = img.height / validationObj.inches.h;
          if (imageDPI < 300) {
            alert("Image less than 300 DPI, please upload image with greater than 300 DPI or work with designer");
            return false
          }
          if (imgAspectRatio < validAspectObj.min || imgAspectRatio > validAspectObj.max) {
            alert(`Aspect ratio of the image not as expected. Image will not print correctly. Please work with the designer to get the best image`)
          }
          res(true);
        }
      }
    })
  };

  onFileChange = obj => {
    const imageObj = obj.target.files[0];
    // const mosqueId = localStorage.getItem('mosqueId');
    const checkedSpot = this.state.spots.find(s => s.checked === true).id;

    if (imageObj) {
      const reader = new FileReader();
      reader.readAsDataURL(imageObj);
      reader.onload = async () => {
        this.validateImg(imageObj)
          .then(async res => {
            this.setState({ slotsLocked: true })
            const image = URL.createObjectURL(imageObj);
            const formData = new FormData();
            formData.append('slotImage', imageObj);
            formData.append('slotNumber', checkedSpot);
            formData.append('Mosqueid', this.props.id);
            // formData.append('totalslot', 8);
            formData.append('jobcloseOn', this.state.jobcloeson);
            formData.append('Iqamaschedule', this.state.formonth);
            formData.append('BusinessID', this.state.filleradvertiser);
            this.setState({ isLoading: true }, async () => {
              try {
                await axios.post(CONFIG.API_END_POINT + 'api/v1/common/uploadFillerImages', formData)
                  .then((res) => {
                    this.setState({ isLoading: false }, () => {
                      setTimeout(() => {
                        if (res.data.success) {
                          alert("Image uploaded successfully")
                          window.location.reload()
                          this.getImages()
                        } else {
                          alert("Image could not be uploaded")
                        }
                      }, 100)
                    })
                  })
                const spots = this.state.spots.map(
                  spot => (
                    spot.id === Number(checkedSpot)
                      ? { ...spot, img: image, occupied: true, isActive: false }
                      : spot
                  )
                );
                this.setState({ spots, showFinalPreview: true, isLoading: false })
                // setShowFinalPreview(true);
                // this.showCheckout()
              } catch (err) {
                console.log(err)
              }
            })

          })

      }
    }
    // setFileList(newFileList);
  };

  showCheckout = () => {
    this.setState({ showCheckout: true })
  };


  showDesign = () => {
    const selectedSpot = this.state.spots.find(s => s.checked === true)?.id;
    if (!selectedSpot) {
      this.setState({ uploadValidErr: true })
    } else {
      this.setState({ showDesign: true })
    }
  };


  clickHere = () => {
    this.props.history.push('/transactiondetail', {
      jobcloseson: this.state.jobcloeson,
      slotnumber: this.state.slotnumber,
      mosqueid: this.props.id
    })
  }


  orderSummary = () => {
    this.props.history.push('/ordersummary/' + this.props.id, {
      id: this.props.id
    })
  }



  getTransactionalDetails = (id = 0) => {
    const spotId = id;
    const isOccupied = this.state.spots.find(s => s.id === Number(spotId) && s.occupied)?.id;
    this.setState({ isLoading: true }, async () => {
      axios.get(CONFIG.API_END_POINT + 'api/v1/admin/common/TransactionOnSlot', {
        params: {
          MosqueID: this.props.id || this.state.filleradvertiser,
          jobcloseOn: this.state.jobcloeson,
          slotNumber: isOccupied
        }
      })
        .then((response) => {
          this.setState({ isLoading: false }, () => {
            setTimeout(() => {
              if (response.data.success) {
                const data = response.data.success
                this.setState({
                  transactionid: data.transactionID,
                  businessname: data.BusinessTitle,
                  paymentdate: data.paymentDate,
                  month: data.Iqamaschedule,
                  slotnumber: data.slotNumber,
                  direction: data.Direction,
                  amount: data.amount,
                  paymentprocessor: data.PaymentProcessor,
                  city: data.city,
                  state: data.state,
                  zipcode: data.zipCode,
                  isLoading: false
                })
              } else {
                alert("No Transaction details")
              }
            }, 100)
          })
        })
    })
  }

  showTransactiondetails = (id = 0) => {
    const spotId = id;

    const isOccupied = this.state.spots.find(s => s.id === Number(spotId) && s.occupied)?.id;

    if (!isOccupied) {
      this.setState({ showChoosespot: true, showTransactiondetails: false })
    } else {
      this.setState({ showTransactiondetails: true, showChoosespot: false })
      this.getTransactionalDetails(id)
    }
  };


  // showChoosespot = () => {
  //   const selectedSpot = this.state.spots.find(s => s.occupied === true)?.id;
  //   if (selectedSpot) {
  //     this.setState({ showChoosespot: true, showTransactiondetails: false })

  //   } else {
  //     this.setState({ showTransactiondetails: true, showChoosespot: false })
  //   }
  // };

  // printDocument() {
  //   const input = document.getElementById('divToPrint');
  //   html2canvas(input, {
  //     scale: 4
  //   })
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF();
  //       pdf.addImage(imgData, 'JPEG', 0, 0, 210, 306);
  //       // pdf.output('dataurlnewwindow');
  //       pdf.save("download.pdf");
  //     })
  //     ;
  // }

  printDocument() {
    const input = document.getElementById('divToPrint2');
    html2canvas(input, {
      scale: 4
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', [311.15, 234.95]);
        pdf.addImage(imgData, 'JPEG', 0, 0, 234.95, 311.15);
        pdf.save("download.pdf");
      })
      ;
  }


  deleteSpot = () => {
    window.scrollTo(0, 0)
    const spotId = this.state.slotid
    const isOccupied = this.state.spots.find(s => s.id === Number(spotId) && s.occupied)?.id;
    let obj = {}
    obj.jobcloseOn = this.state.jobcloeson
    obj.Iqamaschedule = this.state.formonth
    obj.slotNumber = isOccupied
    obj.id = this.props.id

    this.setState({ isLoading: true }, async () => {
      axios.post(CONFIG.API_END_POINT + 'api/v1/common/Deleteslot', obj).then((response) => {
        this.setState({ isLoading: false }, () => {
          setTimeout(() => {
            if (response.data.success) {
              alert("Spot has been deleted")
              window.location.reload(false)
            } else {
              alert("Spot could not be deleted")
            }
          }, 100)
        })
      })
    })
  }

  formatMonthname = (month) => {
    let output = '';
    if (month) {
      if (month === 'Jan') {
        output = '01'
      }
      if (month === 'Feb') {
        output = '02'
      }
      if (month === 'Mar') {
        output = '03'
      }
      if (month === 'Apr') {
        output = '04'
      }
      if (month === 'May') {
        output = '05'
      }
      if (month === 'Jun') {
        output = '06'
      }
      if (month === 'Jul') {
        output = '07'
      }
      if (month === 'Aug') {
        output = '08'
      }
      if (month === 'Sep') {
        output = '09'
      }
      if (month === 'Oct') {
        output = '10'
      }
      if (month === 'Nov') {
        output = '11'
      }
      if (month === 'Dec') {
        output = '12'
      }
    }
    return output;
  }

  renderTableHeader() {
    const height = 471 / this.state.prayerTimes?.length
    return (
      <div style={{ height: `${height}px` }}>
        <tr className="head-size" style={{ textAlign: "center", fontSize: "7.5px" }}>
          <th className="dat-col" style={{ paddingRight: "0px", paddingLeft: "0px" }}>Date</th>
          <th className="da-col" style={{ paddingRight: "0px", paddingLeft: "0px" }}>Day</th>
          <th className="fa-col" style={{ paddingRight: "0px", paddingLeft: "0px" }}><span >Fajr</span><span className="title-second"><span>/</span></span><span className="title-second">Iqama</span></th>
          <th className="sun-col" style={{ paddingRight: "0px", paddingLeft: "0px" }}>Sunrise</th>
          <th className="dhu-col" style={{ paddingRight: "0px", paddingLeft: "0px" }}><span>Dhuhr</span><span className="title-second"><span>/</span></span><span className="title-second" >Iqama</span></th>
          <th className="as-col" style={{ paddingRight: "0px", paddingLeft: "0px" }}><span >Asr</span><span className="title-second"><span>/</span></span><span className="title-second">Iqama</span></th>
          <th className="mag-col" style={{ paddingRight: "0px", paddingLeft: "0px", fontSize: "6.5px", paddingTop: "8px" }}><span >Maghrib</span><span className="title-second"><span>/</span></span><span className="title-second">Iqama</span></th>
          <th className="ish-col" style={{ paddingRight: "0px", paddingLeft: "0px" }}><span>Isha</span><span className="title-second"><span>/</span></span><span className="title-second">Iqama</span></th>
        </tr>
      </div>
    )
  }

  renderTableData() {
    const height = 471 / this.state.prayerTimes?.length
    return this.state.prayerTimes?.map((time, idx) => {
      const month = time.Date.slice(0, 3)

      const montth = this.formatMonthname(month)
      // 
      const date = time.Date.slice(4,)

      const Dateeee = `${this.formatDate(date)}`
      const monttthh = `${montth}`

      return (
        <div style={{ height: `${height}px`, width: "346px", }}>
          <tr width="346px" className="head-size" style={{ textAlign: "center", fontWeight: "bold", fontSize: "7.5px" }}>
            {/* <td>{Datee}</td> */}
            <td className="dat-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}><span>{monttthh}</span><span>&nbsp;/&nbsp;</span><span>{Dateeee}</span></td>
            <td className="da-col" style={{ paddingLeft: "0px", paddingRight: "0px" }}><span>{time.Day}</span></td>
            <td className="fa-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}><span>{time.fajrTime}</span><span>&nbsp;/&nbsp;</span><span className="title-second">{this.formatTimeValue(time.fajrIqama)}</span></td>
            <td className="sun-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}>{time.sunriseTime}</td>
            {
              time.Day === 'Fri' ?
                (<td className="dhu-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}><span >{this.formatTimeValue(time.jummaIqama1)}</span>{!time.jummaIqama2 ? null : <><span>&nbsp;&&nbsp;</span></>}<span className="title-second" >{this.formatTimeValue(time.jummaIqama2)}</span>
                  {!time.jummaIqama3 ? null : <><span>&nbsp;&&nbsp;</span></>}<span>{this.formatTimeValue(time.jummaIqama3)}</span>
                </td>) : (<td className="dhu-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}><span>{time.dhuhrTime}</span><span>&nbsp;/&nbsp;</span><span className="title-second">{this.formatTimeValue(time.dhuhrIqama)}</span></td>)
            }
            <td className="as-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}><span>{time.asrTime}</span><span>&nbsp;/&nbsp;</span><span className="title-second">{this.formatTimeValue(time.asrIqama)}</span></td>
            <td className="mag-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}><span>{time.maghribTime}</span><span>&nbsp;/&nbsp;</span><span className="title-second">{this.formatTimeValue(time.maghribIqama)}</span></td>
            <td className="ish-col" style={{ paddingLeft: "0px", paddingRight: "0px", }}><span>{time.ishaTime}</span><span>&nbsp;/&nbsp;</span><span className="title-second">{this.formatTimeValue(time.ishaIqama)}</span></td>
          </tr>
        </div>
      )
    })
  }

  formatDate = date => {
    let dateee = '';
    if (date) {
      const datee = date.length === 2 ? date : `0${date}`
      dateee = datee
    }
    return dateee
  }

  renderDivData() {
    const height = 680 / this.state.prayerTimes?.length
    return this.state.prayerTimes?.map((time, idx) => {
      const month = time.Date.slice(0, 3)
      const montth = this.formatMonthname(month)

      const date = time.Date.slice(4,)


      const Dateeee = `${this.formatDate(date)}`
      const monttthh = `${montth}`

      return (
        <div className="trow" style={{ height: `${height}px` }}>
          <div className="tcol date-col" style={{ width: "3.65in", fontSize: "12px" }}>{monttthh}<span style={{ marginLeft: "1.5px" }}>/</span>{Dateeee}</div>
          <div className="tcol day-col" style={{ width: "3.65in", fontSize: "12px" }}>{time.Day}</div>
          <div className="tcol fajriqama-col" style={{ width: "4.1in", fontSize: "12px" }}>{time.fajrTime}<span style={{ marginLeft: "1.5px" }}>/</span><span style={{ color: "#D21A1A" }}>{this.formatTimeValue(time.fajrIqama)}</span></div>
          <div className="tcol sunrise-col" style={{ width: "3.65in", fontSize: "12px" }}>{this.formatTimeValue(time.sunriseTime)}</div>
          {
            time.Day === 'Fri' ?
              <div className="tcol dhuhr-col">
                {
                  time.jummaIqama3 ?
                    time.jummaIqama1 ? <span style={{ fontSize: "12px", color: "#D21A1A" }}>{this.formatTimeValue(time.jummaIqama1)}{!time.jummaIqama2 ? null : <><span>,</span></>}{this.formatTimeValue(time.jummaIqama2)}{!time.jummaIqama3 ? null : <><span>&nbsp;&&nbsp;</span></>}{this.formatTimeValue(time.jummaIqama3)}</span>
                      :
                      null
                    :
                    time.jummaIqama1 ? <span style={{ fontSize: "12px", color: "#D21A1A" }}>{this.formatTimeValue(time.jummaIqama1)}{!time.jummaIqama2 ? null : <><span>&nbsp;&&nbsp;</span></>}{this.formatTimeValue(time.jummaIqama2)}{!time.jummaIqama3 ? null : <><span>&nbsp;&&nbsp;</span></>}{this.formatTimeValue(time.jummaIqama3)}</span>
                      :
                      null
                }
              </div>
              :
              <div className="tcol dhuhr-col" style={{ fontSize: "12px" }}>{time.dhuhrTime}<span style={{ marginLeft: "1.5px" }}>/</span><span style={{ color: "#D21A1A", fontSize: "12px" }}>{this.formatTimeValue(time.dhuhrIqama)}</span></div>
          }
          <div className="tcol asr-col" style={{ width: "4in", fontSize: "12px" }}>{time.asrTime}<span style={{ marginLeft: "1.5px" }}>/</span><span style={{ color: "#D21A1A", fontSize: "12px" }}>{this.formatTimeValue(time.asrIqama)}</span></div>
          <div className="tcol maghribiqama-col" style={{ width: "6in", fontSize: "12px" }}>{time.maghribTime}<span style={{ marginLeft: "1.5px" }}>/</span><span style={{ color: "#D21A1A", fontSize: "12px" }}>{this.formatTimeValue(time.maghribIqama)}</span></div>
          <div className="tcol isha-col" style={{ width: "4.25in", fontSize: "12px" }}>{time.ishaTime}<span style={{ marginLeft: "1.5px" }}>/</span><span style={{ color: "#D21A1A", fontSize: "12px" }}>{this.formatTimeValue(time.ishaIqama)}</span></div>
        </div>
      )
    })
  }

  render() {
    const height = 680 / this.state.prayerTimes?.length
    return (
      <>
        <div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "50%" }}>
          {this.state?.isLoading &&
            <LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
          }
        </div>
        {/* <div>
          {this.state?.isLoading && <img alt="" src={LoaderGif} style={{ top: "50%", position: "absolute", left: "35%", height: "200px", width: "500px", zIndex: "1000", }} />}
        </div> */}

        {/* <div style={{ paddingLeft: "95px", backgroundColor: "#EEF2F6", paddingRight: "44px" }}>
          <Headerad />
        </div>
        <div style={{ paddingLeft: "93px", backgroundColor: "white", }}>
          <Adminheader />
        </div> */}
        <div className="bodyy" style={{ overflow: "hidden", float: "left", display: "block", width: "100%" }}>
          <div className="container">
            <div style={{ backgroundColor: "white", width: "100%", float: "left", display: "block", }}>
              <div className="vieworder-scroll">
                <h5 className="card-header d-flex justify-content-between align-items-center">Order<button trailingIcon="picture_as_pdf" label="Resume" onClick={this.printDocument}>
                  Export to pdf </button></h5><br />
                <Row>
                  <Col lg={8} xl={7}>
                    <div id="divToPrint" style={{ marginLeft: "15px", width: "646.25px" }}>
                      <div className="viewordertemp" style={{ backgroundColor: this.state.BackgroundColor }}>
                        <div class="radios">
                          <label>
                            <input className={this.state.spots[0].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="1" checked={this.state.spots[0].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="tem4" src={this.state.spots[0].occupied ? this.state.spots[0].img : teme1} data-taken="False" />
                          </label>
                          <label>
                            <input className={this.state.spots[1].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="2" checked={this.state.spots[1].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="tem3" src={this.state.spots[1].occupied ? this.state.spots[1].img : teme2} data-taken="False" />
                          </label>
                          <label>
                            <input className={this.state.spots[2].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="3" checked={this.state.spots[2].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="tem2" src={this.state.spots[2].occupied ? this.state.spots[2].img : teme3} data-taken="False" />
                          </label>
                          <label>
                            <input className={this.state.spots[3].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="4" checked={this.state.spots[3].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="tem1" src={this.state.spots[3].occupied ? this.state.spots[3].img : teme4} data-taken="False" />
                          </label>
                          <label>
                            <input className={this.state.spots[4].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="5" checked={this.state.spots[4].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="temv5" src={this.state.spots[4].occupied ? this.state.spots[4].img : teme5} data-taken="False" />
                          </label>
                          <label>
                            <input className={this.state.spots[5].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="6" checked={this.state.spots[5].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="temv6" src={this.state.spots[5].occupied ? this.state.spots[5].img : teme6} data-taken="False" />
                          </label>
                          <label>
                            <input className={this.state.spots[6].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="7" checked={this.state.spots[6].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="temv7" src={this.state.spots[6].occupied ? this.state.spots[6].img : teme7} data-taken="False" />
                          </label>
                          <label>
                            <input className={this.state.spots[7].isActive === true ? 'vieworder-img-active' : ''} type="radio" value="8" checked={this.state.spots[7].checked} onChange={this.onValueChange} />
                            <img alt="alternate" className="temv8" src={this.state.spots[7].occupied ? this.state.spots[7].img : teme8} data-taken="False" />
                          </label>
                          <table style={{ tableLayout: 'fixed', top: '316px', left: '259px' }} className="time">
                            <thead>
                              {this.renderTableHeader()}
                            </thead>
                            <tbody>
                              {this.renderTableData()}
                            </tbody>
                          </table>
                          <img alt="header" src={this.state.headerImage} className="time2" />
                        </div>
                      </div>
                      {/* <br /> */}
                    </div>
                    <button className="ml-3 mb-2 mt-2" style={{
                      backgroundColor: "#428bca", borderColor: "#3b7cb4", color: "white", borderRadius: "2px", padding: "1px 5px", fontSize: "12px", lineHeight: "1.5", display: "inline-block", border: "1px solid transparent", fontWeight: "400", fontFamily: "Arial",
                    }} onClick={this.orderSummary}>Order summary</button>
                  </Col>
                  <Col lg={4} xl={5}>
                    <div>
                      <Row>
                        {/* <Col md={6}> */}

                        {
                          !this.state.showCheckout && !this.state.showDesign && (this.state.showUploadView ?
                            (
                              <Col>
                                <Row style={{ marginLeft: "10px", }}>
                                  <Col><h2 style={{ fontSize: "30px", fontWeight: "700", lineHeight: "1.3", fontFamily: "Arial", color: "#374767", }}>{this.props.location.state.mosqueName}</h2></Col>
                                </Row>
                                {/* <Row>
                          <Col><h2 style={{ fontSize: "14.45px", marginLeft: "60px", lineHeight: "0.3", fontFamily: "Arial" }}>Average Jumma Attendees: {config.AverageJumaAttendees}</h2></Col>
                        </Row>
                        <Row>
                          <Col><h2 style={{ fontSize: "14.45px", marginLeft: "60px", lineHeight: "2.5", fontFamily: "Arial" }}>Total Capacity: {config.MosqueCapacity}</h2></Col>
                        </Row> */}
                                <Row style={{ marginLeft: "10px", }}>
                                  <Col >
                                    <h4 style={{ fontSize: "17px", fontFamily: "Arial", fontWeight: "700", color: "#374767" }}>Iqama Schedule:</h4><span><h2 style={{ fontSize: "17px", color: "#374767", fontFamily: "Arial" }}>{this.state.finalForPeriod}</h2></span>
                                  </Col>
                                </Row>
                                <Row style={{ marginLeft: "10px", }}>
                                  <Col>
                                    <Row >
                                      <Col md={6}>
                                        <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767", }}>Job Closes On:</h4><span style={{ fontFamily: "Arial", fontSize: "17px", color: "#374767", }}>{this.state.adMonth}{this.state.adDate}-{this.state.adYear}</span>
                                      </Col>
                                      <Col md={6}>
                                        <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767", }} >Quantity:</h4>{!this.state.Quantity ? null : <span style={{ fontFamily: "Arial", fontSize: "17px", color: "#374767", }}>{this.state.Quantity}&nbsp;pieces</span>}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                {
                                  this.state.showChoosespot && (
                                    <div style={{ width: "99%" }}>
                                      <Row style={{ marginLeft: "10px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767", }}>Choose a spot</h4>
                                          <select style={{ border: this.state.uploadValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "100%", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", height: "40px" }} id="dropdown" value={this.state.spots.find(i => i.checked === true)?.id} onChange={this.onValueChange}  >
                                            <option value style={{ display: "none" }}>Select Ad Spot</option>
                                            {
                                              this.state.spots.map(
                                                spot => !spot.occupied && (
                                                  <option key={spot.id} value={spot.id}>Ad Spot # {spot.id}</option>
                                                )
                                              )
                                            }
                                          </select>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", width: "200px", color: "#374767", }}>Filler Advertisers</h4>
                                          <div style={{ width: "99%" }}>
                                            <select style={{ border: this.state.upload1ValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "100%", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", height: "40px" }} id="dropdown" value={this.state.filleradvertiser} onChange={this.fillerChange}  >
                                              <option value style={{ display: "none" }}>Choose advertiser</option>
                                              {
                                                this.state.filler?.map((name => (
                                                  <option key={name.id} value={name.id}>{name.BusinessTitle}</option>
                                                )))
                                              }
                                            </select>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row style={{ marginTop: "20px", marginLeft: "10px" }}>
                                        <Col>
                                          <div style={{ width: "250px" }} >
                                            <button disabled={this.state.batchDetails === undefined} style={{ border: "none", background: "transparent" }} onClick={e => this.showUploadView(false, e)}><img alt="alternate" src={upload} /></button> </div>
                                          <h6 style={{ marginTop: "10px", fontWeight: "700", color: "#374767", fontFamily: "WebFonts" }}>Upload your designs</h6>
                                          <p style={{ marginTop: "15px" }}>Put an exisiting design on <br /><div>your choice of card.</div></p>

                                          <button disabled={this.state.batchDetails === undefined} onClick={e => this.showUploadView(false, e)} className="style" style={{ marginLeft: "0", border: "none", background: "transparent" }} >Upload now</button>
                                        </Col>
                                      </Row>

                                    </div>
                                  )
                                }
                                {
                                  this.state.showTransactiondetails && (this.state.transactionid ?

                                    <div style={{ fontFamily: "WebFonts" }}>
                                      <hr />
                                      <Row style={{ marginLeft: "10px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "24px", lineHeight: "2.5", fontWeight: "700", color: "#374767" }}>Transaction</h4>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "24px", lineHeight: "2.5", fontWeight: "700", color: "#374767" }}>{this.state.transactionid}</h4>
                                        </Col>
                                      </Row>

                                      <Row style={{ marginLeft: "10px", }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Business name</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.businessname}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Payment date</h4><span style={{ fontSize: "17px", color: "#374767", }}>{this.state.paymentdate?.split('T')[0]}</span>
                                        </Col>
                                      </Row>

                                      <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Description</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.formonth} - Ad Spot # {this.state.slotnumber}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Status</h4><span style={{ fontSize: "17px", color: "#374767", }}>Payment Processed</span>
                                        </Col>
                                      </Row>


                                      <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Direction</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.direction}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Amount</h4>{!this.state.amount ? null : <span style={{ fontSize: "17px", color: "#374767", }}>${this.state.amount}</span>}
                                        </Col>
                                      </Row>


                                      <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Payment processor</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.paymentprocessor}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Payment reciept</h4><button onClick={this.clickHere} style={{ textDecoration: "none", color: "#558B2F", background: "transparent", border: "none" }}><span style={{ fontSize: "17px", }}>Click Here</span></button>
                                        </Col>
                                      </Row>


                                      <hr />
                                      <Row style={{ marginLeft: "10px", marginTop: "20px" }}>
                                        <Col>
                                          <h4 style={{ fontWeight: "700", color: "#374767", }}>Advertiser details</h4>
                                        </Col>
                                      </Row>



                                      <Row style={{ marginLeft: "10px", marginTop: "10px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Title</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.businessname}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>City</h4><span style={{ fontSize: "17px", color: "#374767", }}>{this.state.city}</span>
                                        </Col>
                                      </Row>


                                      <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>State</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.state}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Zipcode</h4><span style={{ fontSize: "17px", color: "#374767", }}>{this.state.zipcode}</span>
                                        </Col>
                                      </Row>

                                      <hr />

                                      <Row style={{ marginLeft: "10px" }}>
                                        <Col md={6}>
                                          <Row>
                                            <Col>
                                              <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767" }}>Choose a spot</h4>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <select style={{ border: this.state.uploadValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "100%", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", }} id="dropdown" value={this.state.spots.find(i => i.checked === true)?.id} onChange={this.onValueChange}  >
                                                <option value style={{ display: "none" }}>Select Ad Spot</option>
                                                {
                                                  this.state.spots.map(
                                                    spot => spot.occupied && (
                                                      <option key={spot.id} value={spot.id}>Ad Spot # {spot.id}</option>
                                                    )
                                                  )
                                                }
                                              </select>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md={6} >
                                          <Row>
                                            <Col>
                                              <label style={{ color: "transparent" }}>jshsdj</label>
                                            </Col>
                                          </Row>
                                          <button disabled={this.state.batchDetails === undefined} className="danger" onClick={this.deleteSpot}>Delete Spot</button>
                                        </Col>
                                      </Row>

                                      <Row style={{ marginTop: "20px", marginLeft: "10px" }}>
                                        <Col>
                                          <div style={{ width: "250px" }} >
                                            <button disabled={this.state.batchDetails === undefined} style={{ background: "transparent", border: "none" }} onClick={e => this.showUploadView2(false, e)}><img alt="alternate" src={upload} /></button> </div>
                                          <h6 style={{ marginTop: "10px", fontWeight: "700", color: "#374767", fontFamily: "WebFonts" }}>Upload your designs</h6>
                                          <p style={{ marginTop: "15px" }}>Put an exisiting design on <br /><div>your choice of card.</div></p>

                                          <button disabled={this.state.batchDetails === undefined} onClick={e => this.showUploadView2(false, e)} style={{ display: "block", color: "#D21A1A", fontSize: "16px", fontWeight: "700", fontFamily: "Arial", textAlign: "left", background: "transparent", border: "none" }} >Upload now</button>


                                        </Col>
                                      </Row>

                                    </div>

                                    :
                                    <div style={{ fontFamily: "WebFonts" }}>
                                      <hr />
                                      <Row style={{ marginLeft: "10px", marginTop: "20px" }}>
                                        <Col>
                                          <h4 style={{ fontWeight: "700", color: "#374767", }}>Advertiser details</h4>
                                        </Col>
                                      </Row>



                                      <Row style={{ marginLeft: "10px", marginTop: "10px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Title</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.businessname}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>City</h4><span style={{ fontSize: "17px", color: "#374767", }}>{this.state.city}</span>
                                        </Col>
                                      </Row>


                                      <Row style={{ marginLeft: "10px", marginTop: "30px" }}>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>State</h4><span style={{ fontSize: "15px", color: "#374767", }}>{this.state.state}</span>
                                        </Col>
                                        <Col md={6}>
                                          <h4 style={{ fontSize: "17px", fontWeight: "700", color: "#374767" }}>Zipcode</h4><span style={{ fontSize: "17px", color: "#374767", }}>{this.state.zipcode}</span>
                                        </Col>
                                      </Row>

                                      <hr />

                                      <Row style={{ marginLeft: "10px" }}>
                                        <Col md={7}>
                                          <h4 style={{ fontSize: "17px", marginTop: "30px", fontWeight: "700", color: "#374767" }}>Choose a spot</h4>
                                          <select style={{ border: this.state.uploadValidErr ? '1px solid red' : 'solid 2px #e3e3e3', width: "100%", color: "#777", padding: "8px", boxShadow: "none", paddingBottom: "5px", fontFamily: "Arial", fontWeight: "lighter", }} id="dropdown" value={this.state.spots.find(i => i.checked === true)?.id} onChange={this.onValueChange}  >
                                            <option value style={{ display: "none" }}>Select Ad Spot</option>
                                            {
                                              this.state.spots.map(
                                                spot => spot.occupied && (
                                                  <option key={spot.id} value={spot.id}>Ad Spot # {spot.id}</option>
                                                )
                                              )
                                            }
                                          </select>
                                        </Col>
                                        <Col md={5} >
                                          <Row>
                                            <Col>
                                              <label style={{ color: "transparent" }}>jshsdj</label>
                                            </Col>
                                          </Row>
                                          <button disabled={this.state.batchDetails === undefined} className="danger" onClick={this.deleteSpot} >Delete Spot</button>
                                        </Col>
                                      </Row>

                                      <Row style={{ marginTop: "20px", marginLeft: "10px" }}>
                                        <Col>
                                          <div style={{ width: "250px" }} >
                                            <button disabled={this.state.batchDetails === undefined} style={{ border: "none", background: "transparent" }} onClick={e => this.showTransactiondetails(false, e)}><img alt="alternate" src={upload} /></button> </div>
                                          <h6 style={{ marginTop: "10px", fontWeight: "700", color: "#374767", fontFamily: "WebFonts" }}>Upload your designs</h6>
                                          <p style={{ marginTop: "15px" }}>Put an exisiting design on <br /><div>your choice of card.</div></p>

                                          <button disabled={this.state.batchDetails === undefined} onClick={e => this.showTransactiondetails(false, e)} style={{ display: "block", color: "#D21A1A", fontSize: "16px", fontWeight: "700", fontFamily: "Arial", textAlign: "left", background: "transparent", border: "none" }} >Upload now</button>


                                        </Col>
                                      </Row>
                                    </div>
                                  )
                                }
                              </Col>
                            )
                            :
                            (
                              <Col>

                                <div>
                                  {/* <a href="/template"> */}
                                  <button onClick={e => this.showUploadView2(true, e)} className="bk" style={{ fontWeight: "bolder", width: "95px", height: '40px', }}>
                                    <i class="fas fa-arrow-left" style={{ marginRight: "5px" }}></i>Back
                                  </button>
                                  {/* </a> */}
                                  <Col className="h2" >

                                    <h2>Upload Your Image</h2><br />
                                    <ol className="ol">
                                      <li>Accepts Jpg, Jpeg and Png files only.</li>
                                      <li>Image resolution should be at least 300 dpi.</li>
                                    </ol>
                                    <div>
                                      <div className="fil">
                                        <div>
                                          <input type="file" onChange={this.onFileChange} required />
                                          <img alt="" src={this.state.file} />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </div>

                              </Col>
                            ))
                        }
                      </Row>
                    </div>
                  </Col>
                </Row>
                {/* <div style={{ width: "9.25in" }}  >
                  <div id="divToPrint2">
                    <div className="bleed" style={{ padding: "0.125in", background: this.state.BackgroundColor, width: "100%" }}>
                      <div className="final-trim" style={{ background: this.state.BackgroundColor, padding: "0.25in", paddingTop: "0.125in" }}>
                      <div className="bottom">
                          <div style={{ background: "red", height: "3in", width: "2in", }}>
                          <img alt="alternate" height="100%" width="100%" src={this.state.spots[4].occupied ? this.state.spots[4].img : teme5} data-taken="False" />
                          </div>
                          <div style={{ background: "white", height: "3in", width: "2in", marginLeft: "0.17in" }}>
                          <img alt="alternate" height="100%" width="100%" src={this.state.spots[5].occupied ? this.state.spots[5].img : teme6} data-taken="False" />
                          </div>
                          <div style={{ background: "green", height: "3in", width: "2in", marginLeft: "0.17in" }}>
                          <img alt="alternate" height="100%" width="100%" src={this.state.spots[6].occupied ? this.state.spots[6].img : teme7} data-taken="False" />
                          </div>
                          <div style={{ background: "yellow", height: "3in", width: "2in", marginLeft: "0.17in" }}>
                          <img alt="alternate" height="100%" width="100%" src={this.state.spots[7].occupied ? this.state.spots[7].img : teme8} data-taken="False" />
                          </div>
                        </div>
                        
                        <div className="top">
                          <div style={{ float: "left" }}>
                            <div style={{ background: "red", width: "3in", height: "1.95in", }}>
                            <img alt="alternate" height="100%" width="100%" src={this.state.spots[0].occupied ? this.state.spots[0].img : teme1} data-taken="False" />
                            </div>
                            <div style={{ background: "white", width: "3in", height: "1.95in", marginTop: "0.17in" }}>
                            <img alt="alternate" height="100%" width="100%" src={this.state.spots[1].occupied ? this.state.spots[1].img : teme2} data-taken="False" />
                            </div>
                            <div style={{ background: "green", width: "3in", height: "1.95in", marginTop: "0.17in" }}>
                            <img alt="alternate" height="100%" width="100%" src={this.state.spots[2].occupied ? this.state.spots[2].img : teme3} data-taken="False" />
                            </div>
                            <div style={{ background: "yellow", width: "3in", height: "1.95in", marginTop: "0.17in" }}>
                            <img alt="alternate" height="100%" width="100%" src={this.state.spots[3].occupied ? this.state.spots[3].img : teme4} data-taken="False" />
                            </div>
                          </div>
                          <div className="second-half" style={{ float: "right", marginLeft: "0.125in" }}>
                            <div style={{ background: "orange", width: "5.375in", height: "1in", }}>
                              <img alt="header" src={this.state.headerImage} height="100%" width="100%" />
                            </div>
                            <div style={{ height: "7.32in", width: "5.375in", }}>
                              <div className="ttable" style={{ fontSize: "10px", fontWeight: "bold" }}>
                                <div className="thead">
                                  <div className="trow" style={{ height: `${height}px` }}>
                                    <div className="tcol date-col">Date</div>
                                    <div className="tcol day-col">Day</div>
                                    <div className="tcol fajriqama-col">Fajr/<span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                    <div className="tcol sunrise-col">Sunrise</div>
                                    <div className="tcol dhuhr-col">Dhuhr/<span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                    <div className="tcol asr-col">Asr/<span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                    <div className="tcol maghribiqama-col">Maghrib/<span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                    <div className="tcol isha-col">Isha/<span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                  </div>
                                </div>
                                <div className="tbody" style={{ verticalAlign: "middle" }}>
                                  {
                                    this.renderDivData()
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div> */}

                <div style={{ width: "9.25in" }} className="template-hide" >
                  <div id="divToPrint2">
                    <div className="bleed" style={{ padding: "0.125in", background: this.state.BackgroundColor, width: "100%" }}>
                      <div className="final-trim" style={{ background: this.state.BackgroundColor, padding: "0.25in", paddingTop: "0.125in" }}>
                        <div className="top">
                          <div style={{ background: "yellow", width: "2in", height: "3in", }}>
                            <img alt="template" height="100%" width="100%" src={this.state.spots[3].occupied ? this.state.spots[3].img : teme4} data-taken="False" />
                          </div>
                          <div style={{ background: "green", width: "2in", height: "3in", marginLeft: "0.17in" }}>
                            <img alt="template" height="100%" width="100%" src={this.state.spots[2].occupied ? this.state.spots[2].img : teme3} data-taken="False" />
                          </div>
                          <div style={{ background: "white", width: "2in", height: "3in", marginLeft: "0.17in" }}>
                            <img alt="template" height="100%" width="100%" src={this.state.spots[1].occupied ? this.state.spots[1].img : teme2} data-taken="False" />
                          </div>
                          <div style={{ background: "red", width: "2in", height: "3in", marginLeft: "0.17in" }}>
                            <img alt="template" height="100%" width="100%" src={this.state.spots[0].occupied ? this.state.spots[0].img : teme1} data-taken="False" />
                          </div>
                        </div>
                        <div className="bottom">
                          <div style={{ float: "left" }}>

                            <div style={{ background: "red", height: "1.95in", width: "3in", }}>
                              <img alt="template" height="100%" width="100%" src={this.state.spots[4].occupied ? this.state.spots[4].img : teme5} data-taken="False" />
                            </div>
                            <div style={{ background: "white", height: "1.95in", width: "3in", marginTop: "0.17in" }}>
                              <img alt="template" height="100%" width="100%" src={this.state.spots[5].occupied ? this.state.spots[5].img : teme6} data-taken="False" />
                            </div>
                            <div style={{ background: "green", height: "1.95in", width: "3in", marginTop: "0.17in" }}>
                              <img alt="template" height="100%" width="100%" src={this.state.spots[6].occupied ? this.state.spots[6].img : teme7} data-taken="False" />
                            </div>
                            <div style={{ background: "yellow", height: "1.95in", width: "3in", marginTop: "0.17in" }}>
                              <img alt="template" height="100%" width="100%" src={this.state.spots[7].occupied ? this.state.spots[7].img : teme8} data-taken="False" />
                            </div>
                          </div>
                          <div className="second-half" style={{ float: "right", marginLeft: "0.125in" }}>
                            <div style={{ background: "orange", width: "5.375in", height: "1in", }}>
                              <img alt="header" src={this.state.headerImage} height="100%" width="100%" />
                            </div>
                            <div style={{ height: "7.32in", width: "5.375in", }}>
                              <div className="ttable" style={{ fontSize: "10px", fontWeight: "bold" }}>
                                <div className="thead">
                                  <div className="trow" style={{ height: `${height}px` }}>
                                    <div className="tcol date-col">Date</div>
                                    <div className="tcol day-col">Day</div>
                                    <div className="tcol fajriqama-col">Fajr<span style={{ fontSize: "12px", marginLeft: "2px" }}>/</span><span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                    <div className="tcol sunrise-col"><img src={sunrise} alt="sunrise" width="100%" height="100%" style={{ width: "25px", marginBottom: "15px" }} /></div>
                                    <div className="tcol dhuhr-col">Dhuhr<span style={{ fontSize: "12px", marginLeft: "2px" }}>/</span><span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                    <div className="tcol asr-col">Asr<span style={{ fontSize: "12px", marginLeft: "2px" }}>/</span><span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                    <div className="tcol maghribiqama-col"><span style={{ marginLeft: "2px" }}>Maghrib<span style={{ fontSize: "12px", marginLeft: "2px" }}>/</span><span style={{ color: "#D21A1A" }}>Iqama</span></span></div>
                                    <div className="tcol isha-col">Isha<span style={{ fontSize: "12px", marginLeft: "2px" }}>/</span><span style={{ color: "#D21A1A" }}>Iqama</span></div>
                                  </div>
                                </div>
                                <div className="tbody" style={{ verticalAlign: "middle" }}>
                                  {
                                    this.renderDivData()
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img src={calendarTxt} alt="text" style={{ float: "right", width: "150px", marginTop: "8px" }} />
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default withRouter(OrderTemplate2)