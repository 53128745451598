import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './ApprovedMosque.css'
import axios from '../../utils/axios';
import { CONFIG } from '../../utils/constants';
import backgroundimage from '../../images/mountain.jpeg'
// import Footeradmin from '../../Footer/Footeradmin';


import './PendingMosque.css';

import Pendingactivationdetails from './PendingMosqueEdit';
import { withRouter } from 'react-router-dom';
import Adminheader from '../AdminNavBar';
import Headerad from '../../components/pages/headeradmin';


const header = ["Title", "City", "Zip Code", "State", "Status", "Created On", " "];


class PendingActivation1 extends Component {
  state = {
    data: [],
    editID: '',
    flag: false,
  }

  componentDidMount() {
    const apiData = {
      token: localStorage.getItem('token'),
      page: 1
    }
    axios.get(CONFIG.API_END_POINT + 'api/v1/admin/pendingMosque', {
      params : apiData
    })
    .then((response) => {
      if (response.data.success) {
        const data = response.data.success;
        this.setState({ data: data });
      }
      else {
        
      }
    })
  }

  editHanlder = (id) => {
    this.setState({ editedId: id, flag: true })
  }

  render() {
    if (this.state.flag) {
      return <Pendingactivationdetails editedId={this.state.editedId} />

    }
    return (
      <>

        <div className="body" style={{ backgroundImage: { backgroundimage } }}>
          <div style={{ paddingLeft: "95px", backgroundColor: "#EEF2F6", paddingRight: "44px" }}>
            <Headerad />
          </div>
          <div style={{ paddingLeft: "93px", backgroundColor: "white" }}>
            <Adminheader />
          </div>

          <div className="pendingcontainer" style={{ width: "1100px", height: "800px", marginLeft: "200px", marginTop: "5px", backgroundColor: "white",  }}>
            <Container>
              <Row>
                <Col style={{ fontFamily: "Arial", fontSize: "16px", fontWeight: "bold", marginTop: "18px", color: "#374767" }}>Pending Mosques</Col>
              </Row><hr />
              <Row style={{ marginTop: "30px" }}>
                <Col>
                  <table style={{ borderRadius: "10px !important", }}>
                    <thead style={{ backgroundColor: "#f6f6f6", fontSize: "13px", fontWeight: "lighter", fontFamily: "Arial", borderRadius: "5px", }}>
                      <tr style={{ borderRadius: "5px" }}>{header.map((h, i) => <th key={i}>{h}</th>)}</tr>
                    </thead>
                    <tbody >
                      {Object.keys(this.state.data).map((k, i) => {
                        let data = this.state.data[k];
                        return (
                          <tr key={i}>
                            <td style={{ width:"40%", fontFamily: "Arial", fontWeight: "light", fontSize: "13px", paddingLeft: "8px", paddingTop: "0px", paddingBottom: "0px" }}>{data.MosqueName}</td>
                            <td style={{ fontFamily: "Arial", fontWeight: "light", fontSize: "13px", paddingLeft: "8px", paddingTop: "0px", paddingBottom: "0px" }}>{data.city}</td>
                            <td style={{ fontFamily: "Arial", fontWeight: "light", fontSize: "13px", paddingLeft: "8px", paddingTop: "0px", paddingBottom: "0px" }}>{data.zipcode}</td>
                            <td style={{ fontFamily: "Arial", fontWeight: "light", fontSize: "13px", paddingLeft: "8px", paddingTop: "0px", paddingBottom: "0px" }}>{data.state}</td>
                            <td style={{ fontFamily: "Arial", fontWeight: "light", fontSize: "13px", paddingLeft: "8px", paddingTop: "0px", paddingBottom: "0px" }}>{data.status}</td>
                            <td style={{ fontFamily: "Arial", fontWeight: "light", fontSize: "13px", paddingLeft: "8px", paddingTop: "0px", paddingBottom: "0px" }}>{data.createdDate}</td>

                            <td style={{ width: "20px" }}><Button style={{ backgroundColor: "#428bca", borderColor: "#3b7cb4", borderRadius: "2px", color: "white", padding: "1px 5px", fontSize: "12px", lineHeight: "1.5", marginLeft: "10px", }} onClick={() => this.editHanlder(data.id)}>Edit</Button></td>                                                    </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* <Footeradmin /> */}
      </>
    );
  }
}
export default withRouter(PendingActivation1);