import React from 'react';
import Tabs from "./Tabs"; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './tabs.css';
import Newcustomer from './Newcustomer';
import Returningcust from './Returningcust';
import Testing2 from '../components/Testing2';



export default function Demobusiness() {
    return (
        <>
        <div>
            <Testing2 />
        </div>
        <div className="lgin">Checkout</div>
            <div>
                <Tabs>
                    <div label="New Customer">
                        <Newcustomer />
                    </div>
                    <div label="Returning Customer">
                        <Returningcust />
                    </div>
                </Tabs>
            </div>
        </>
    );
}