import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../admin/Logout.css';

function Joinasbutton(props) {
  const token = localStorage.getItem('token') || localStorage.getItem('businesstoken') || localStorage.getItem('mosquetoken');
  const myAccount = () => {
    const role = localStorage.getItem('role');
    const id = localStorage.getItem('mosqueId');
    if(role === 'Mosque') {
      props.history.push('/mosque');
    } else if (role === 'superadmin') {
      props.history.push('/dashboard');
    }else if (role === 'Business'){
      props.history.push('/BusinessOrdersPagination',{
        id: id
      })
    }
  };

  return (
    <>
      {
        token === null ? (
          <Link to='step1'>
            <button className='headerlog myaccount-button' style={{  boxShadow: "none",  fontSize: "13px", fontWeight: "700", lineHeight: "20px", padding: "10px 30px", textShadow: "none", display: "inline-block", borderRadius: "2px", fontFamily: "Helvetica", minWidth: 'max-content' }}>Join as Masjid</button>
          </Link>
        ) : (
            <button className="headerlog myaccount-button" onClick={_ => myAccount()}  style={{  boxShadow: "none",  fontSize: "13px", fontWeight: "700", lineHeight: "20px",padding: "10px 30px",  textShadow: "none", display: "inline-block", borderRadius: "2px", fontFamily: "Helvetica", minWidth: 'max-content', height: '40px',    }}>My Account</button>
          )
      }
    </>
  );
}

export default withRouter(Joinasbutton);