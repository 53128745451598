import React from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";

import { CONFIG } from '../src/utils/constants';
// import './profilemap.css'

Geocode.setApiKey(CONFIG.GOOGLE_MAPS_KEY);
Geocode.enableDebug();

class DefaultMap extends React.Component {
	state = {
		address: '',
		city: '',
		area: '',
		state: '',
		zoom: this.props.zoom,
		height: this.props.height,
		mapPosition: {
			lat: parseFloat(this.props.coords?.lat),
			lng: parseFloat(this.props.coords?.lng),
		},
		markerPosition: {
			lat: parseFloat(this.props.coords?.lat),
			lng: parseFloat(this.props.coords?.lng),
		},
		mapCenter: {
			lat: parseFloat(this.props.coords?.lat),
			lng: parseFloat(this.props.coords?.lng),
		}
	}

	componentDidMount() {
		this.setState({
			mapPosition: {
				lat: parseFloat(this.props.coords?.lat),
				lng: parseFloat(this.props.coords?.lng),
			},
			markerPosition: {
				lat: parseFloat(this.props.coords?.lat),
				lng: parseFloat(this.props.coords?.lng),
			},
			mapCenter: {
				lat: parseFloat(this.props.coords?.lat),
				lng: parseFloat(this.props.coords?.lng),
			}
		})
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				this.setState({
					mapPosition: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
					markerPosition: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
					mapCenter: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					}
				},
					() => {
						Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
							response => {
								const address = response.results[0].formatted_address,
									addressArray = response.results[0].address_components,
									city = this.getCity(addressArray),
									area = this.getArea(addressArray),
									state = this.getState(addressArray);
								this.setState({
									address: (address) ? address : '',
									area: (area) ? area : '',
									city: (city) ? city : '',
									state: (state) ? state : '',
								})
							},
							error => {
								console.error(error);
							}
						);
					})
			});
		} else {
			console.error("Geolocation is not supported by this browser!");
		}
	};

	updateMapCenter = latLng => {
		//console.log(latLng)
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.center !== this.props.center) {
			this.setState({ mapCenter: this.props.center, markerPosition: this.props.center, address: this.props.address })
		}
	}

	getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	onInfoWindowClose = (event) => { };

	onMarkerDragEnd = (event) => {
		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: (address) ? address : '',
					area: (area) ? area : '',
					city: (city) ? city : '',
					state: (state) ? state : '',
					markerPosition: {
						lat: newLat,
						lng: newLng
					},
					mapPosition: {
						lat: newLat,
						lng: newLng
					},
				}, _ => {
					this.props.setMarkerAddress(this.state.address, { lat: newLat, lng: newLng })
				})
			},
			error => {
				console.error(error);
			}
		);
	};

	onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = this.getCity(addressArray),
			area = this.getArea(addressArray),
			state = this.getState(addressArray),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();
		// Set these values in the state.
		this.setState({
			address: (address) ? address : '',
			area: (area) ? area : '',
			city: (city) ? city : '',
			state: (state) ? state : '',
			markerPosition: {
				lat: latValue,
				lng: lngValue
			},
			mapPosition: {
				lat: latValue,
				lng: lngValue
			},
		})
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.center !== this.props.center) {
			return true;
		}
		if (isNaN(this.state.mapCenter.lat)) {

			return true;
		}
		if (this.state.mapCenter.lat === null) {
			return true;
		}
		return false;
	}

	render() {
		const AsyncMap = withScriptjs(
			withGoogleMap(
				props => (
					this.state.mapCenter.lat === isNaN && this.state.mapCenter.lng === isNaN ?
						<>

							<div>Please refresh the page to load the map properly</div>
						</>
						:
						<GoogleMap
							defaultZoom={this.state.zoom}
							defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
							center={this.state.mapCenter}
						>
							<Marker
								google={this.props.google}
								name={'Dolores park'}
								draggable={true}
								onDragEnd={this.onMarkerDragEnd}
								position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
							/>

							{
								!this.state.position ?
									null
									:
									<InfoWindow
										onClose={this.onInfoWindowClose}
										position={{ lat: (this.state?.markerPosition?.lat + 0.0018), lng: this.state?.markerPosition?.lng }}
									>
										<div>
											<span style={{ padding: 0, margin: 0, fontWeight: "bolder", fontSize: "15px" }}>Drag the marker to fill the address field</span>
										</div>
									</InfoWindow>
							}
							<Marker />
						</GoogleMap>
				)
			)
		);

		return (
			this.state?.mapPosition?.lat === 0 && this.state?.mapPosition?.lng === 0 ?
				<div style={{ maxWidth: 'auto', maxHeight: 'auto' }} >Please refre the page to load the map properly</div>
				:
				<div style={{ maxWidth: 'auto', maxHeight: 'auto' }} >
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_MAPS_KEY}&libraries=places`}
						loadingElement={
							<div style={{ height: `auto` }} />
						}
						containerElement={
							<div style={{ height: this.state.height }} />
						}
						mapElement={
							<div style={{ height: "100%" }} />
						}
					/>
				</div>
		)
	}
}

export default DefaultMap;