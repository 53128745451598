import React, { Component } from 'react';
import '../../admin/Logout.css'
import { Redirect } from 'react-router-dom';
import { LogoutAll } from '../../utils/Helper';
// import LoaderGif from '../../assets/images/loader3.gif'
import { LoadingOutlined } from '@ant-design/icons';




class Logouticon extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: JSON.parse(localStorage.getItem('email')),
        }


        this.handleLogout = this.handleLogout.bind(this)
    }



    handleLogout = () => {
        this.setState({ isLoading: true }, () => {
            LogoutAll()
        })

    }

    render() {
        const {

            logoutStatus


        } = this.state;
        if (logoutStatus === 'logout successfully') {
            return <Redirect to="/" />;
        }
        return (
            <>
                {/* {
                    this.state?.isLoading &&
                    <img alt="" src={LoaderGif} style={{ top: "10%", position: "absolute", left: "40%", height: "200px", width: "500px", zIndex: "1000", }} /> // background: 'rgb(255,255,255,0.6)'
                } */}
                <div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "20rem" }}>
                    {this.state?.isLoading &&
                        <LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
                    }
                </div>
                <div >

                    <i id="logout-icon" onClick={this.handleLogout} class="fas fa-power-off"></i>
                </div>
            </>
        );
    }

}

export default Logouticon;