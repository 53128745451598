import React, { Component } from 'react'
import { Loginbutton } from './Button';
import { Link, withRouter } from 'react-router-dom';
import axios from '../utils/axios';
import { CONFIG } from '../utils/constants';
import pclogo from '../images/pc-logo.png'
// import logosearch from '../images/Screenshot16.png'
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Joinasbutton from './Joinasbutton';
import './Navbar.css'
import { Row } from 'reactstrap'

class SiteHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      mapCenter: ''
    }
  }
  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        // update center state
        this.setState({ mapCenter: latLng });
        if (window.location.href !== CONFIG.PORTAL_URI) {
          this.props.history.push('/', {
            coOrdinates: latLng
          })
        } else {
          this.props.getMapCenterValue(latLng);
        }
      })
      .catch(error => console.error('Error', error));
  };

  handleGoSelect = async _ => {
    try {
      const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.address}&key=${CONFIG.GOOGLE_API_KEY}`);
      const data = res.data;
      if (data.results[0]?.geometry?.location) {
        let coOrds = data.results[0].geometry.location;
        if (window.location.href !== CONFIG.PORTAL_URI) {
          this.props.history.push('/', {
            coOrdinates: coOrds
          })
        } else {
          this.props.getMapCenterValue(coOrds)
        }
      }
    } catch (err) {
      console.log(err)
    }
  };

  render() {
    return (
      // <div>

      //   <nav className='navbarr' >
      //     <Row>
      //       <Col md={3}>
      //         <Link to='/' className='navbarr-logo' onClick={this.closeMobileMenu} >
      //           <img alt="alternate" src={pclogo} style={{ marginLeft: "80px", height: "63px", marginBottom: "10px", paddingRight: "50px", marginTop: "12px" }} />
      //         </Link>
      //       </Col>
      //       <Col md={9}>
      //         <div className='menu-icon' onClick={this.handleClick}>
      //           <i className='fas fa-bars' />
      //         </div>
      //         <div>
      //         </div>
      //         <ul className='nav-menu active' >
      //           <ul>
      //             <img src={logosearch} alt="logo" className="search-logo" />
      //           </ul>
      //           <li className='map-search' >
      //             <PlacesAutocomplete
      //               value={this.state.address}
      //               onChange={this.handleChange}
      //               onSelect={this.handleSelect}
      //             // className="map-search1"
      //             >
      //               {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      //                 <div>
      //                   <button onClick={this.handleGoSelect} style={{ background: "none", color: "#D21A1A", paddingRight: "10px", paddingLeft: "0px", position: "absolute", textAlign: "center", width: "40px", fontSize: "16px", border: "none", fontFamily: "Arial", fontWeight: "bold", marginTop: "10px", marginLeft: "500px" }}>Go</button>
      //                   <input
      //                     className="searchinput"
      //                     style={{ borderColor: "#e1e1e1 ", borderStyle: "solid", marginLeft: "0px", width: "540px", height: "40px", fontFamily: "Arial", boxSizing: "border-box", animationName: "endBrowserAutofill", }}
      //                     {...getInputProps({
      //                       placeholder: 'Enter an Address, City, or Zipcode',
      //                       className: 'location-search-input',
      //                     })}
      //                   />
      //                   <div className="autocomplete-dropdown-container" style={{ borderColor: "#e1e1e1 ", marginLeft: "0px", width: "530px", fontFamily: "Arial", fontSize: "18px", fontWeight: "lighter" }}>
      //                     {loading && <div>Loading...</div>}
      //                     {suggestions.map(suggestion => {
      //                       const className = suggestion.active
      //                         ? 'suggestion-item--active'
      //                         : 'suggestion-item';
      //                       // inline style for demonstration purpose
      //                       const style = suggestion.active
      //                         ? { backgroundColor: '#fafafa', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px" }
      //                         : { backgroundColor: '#ffffff', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px" };
      //                       return (
      //                         <div
      //                           {...getSuggestionItemProps(suggestion, {
      //                             className,
      //                             style,
      //                           })}
      //                         >
      //                           <span>{suggestion.description}</span>
      //                         </div>
      //                       );
      //                     })}
      //                   </div>
      //                 </div>
      //               )}
      //             </PlacesAutocomplete>
      //           </li>
      //         <li className="navi-btn">
      //           <Loginbutton />
      //           < Joinasbutton />
      //         </li>
      //         <li className='nav-button' style={{ marginTop: "4px" }}>
      //         </li>
      //         <li className="nav-button1">
      //           <div className="dropdown">
      //           </div>
      //         </li>
      //       </ul>
      //       </Col>
      //     </Row>
      //   </nav>

      // </div>
      <>
        <div className="newheader">
          <nav className="container">
            <Row  >

              <div className="search-logo d-flex col-md-12 align-items-center flex-wrap flex-md-nowrap my-3 my-md-0 justify -content-center">
                <div className="masajid-logo mr-md-3 mx-auto" >
                  <a href='/' className='navbarr-logo' onClick={this.closeMobileMenu} >
                    <img alt="alternate" className="logoimage" src={pclogo} />
                  </a>
                </div>
                <div className="search-wrapper d-flex mt-3 order-1 align-items-center">
                <i class="fas fa-search mx-3"></i>
                  <div className="header-search">
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                    // className="map-search1"
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>

                          <input
                            className="focus"
                            // className="searchinput"
                            style={{ animationName: "endBrowserAutofill", fontFamily: "Arial", fontSize: "18px" }}
                            {...getInputProps({
                              // placeholder: 'Enter an Address, City, or Zipcode',
                              placeholder: "Zoom Out or Enter City or Zipcode to find participating Masjids"
                              // className: 'location-search-input',
                            })}
                          />

                          {/* <button className="gobutton" onClick={this.handleGoSelect} style={{ background: "transparent", color: "#D21A1A",  position: "absolute", textAlign: "center",  fontSize: "16px", border: "none", fontFamily: "Arial", fontWeight: "bold",  }}>Go</button> */}
                          <div className="autocomplete-dropdown-container" style={{ borderColor: "#e1e1e1 ", marginLeft: "0px", width: "100%", fontFamily: "Arial", fontSize: "17px", fontWeight: "lighter", position: "absolute", top: "40px", zIndex: "1", textAlign: "left" }}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px", }
                                : { backgroundColor: '#ffffff', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px", };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <button onClick={this.handleGoSelect} className="butt-go">Go</button>
                </div>
                <div className="log-join-butt d-flex mt-3 order-0 order-md-3 mx-auto mx-md-0" >
                  <Loginbutton />
                  < Joinasbutton />
                  <Link to="/servicesoptions">
                    <button className=' myaccount-button' style={{ boxShadow: "none", marginLeft: "15px", fontSize: "13px", fontWeight: "700", lineHeight: "20px", padding: "10px 30px", textShadow: "none", display: "inline-block", borderRadius: "2px", fontFamily: "Helvetica", minWidth: 'max-content' }}>Print Your Own</button>
                  </Link>
                </div>
              </div>


              {/* <Col md={1} style={{textAlign: "right", paddingTop: "40px"}}>
              <img style={{ height: "40px", width: "50px", marginLeft: "150px" }} src={logosearch} alt="logo" />
              </Col> */}
              {/* <Col md={5} style={{ textAlign: "center", }}>
                <div style={{paddingTop: "40px"}}>
                <PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                // className="map-search1"
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      
                      <input
                        // className="searchinput"
                        style={{ borderColor: "#e1e1e1 ", borderStyle: "solid", width: "540px", height: "40px", fontFamily: "Arial", boxSizing: "border-box", animationName: "endBrowserAutofill", float: "right" }}
                        {...getInputProps({
                          placeholder: 'Enter an Address, City, or Zipcode',
                          // className: 'location-search-input',
                        })}
                      />
                      <button className="gobutton" onClick={this.handleGoSelect} style={{ background: "transparent", color: "#D21A1A",  position: "absolute", textAlign: "center",  fontSize: "16px", border: "none", fontFamily: "Arial", fontWeight: "bold",  }}>Go</button>
                      <div className="autocomplete-dropdown-container" style={{ borderColor: "#e1e1e1 ", marginLeft: "0px", width: "530px", fontFamily: "Arial", fontSize: "18px", fontWeight: "lighter", }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px" }
                            : { backgroundColor: '#ffffff', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                </div>
              </Col> */}

            </Row>
          </nav>
        </div>
      </>
    )
  }
}
export default withRouter(GoogleApiWrapper({
  apiKey: (CONFIG.GOOGLE_API_KEY)
})(SiteHeader));
