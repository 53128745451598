import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import Logouticon from './Logouticon';
import pclogo from '../../images/pc-logo.png'
import { LogoutAll } from '../../utils/Helper';
import { CONFIG } from '../../utils/constants';
import { Container } from 'reactstrap'
import './home.css';
import './Header.css'

class Headerad extends Component {
  handleLogout = _ => {
    localStorage.removeItem('mosqueId');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    LogoutAll()
  };

  handleHome = e => {
    e.preventDefault();
    if (window.location.href.indexOf('/mosque/') > -1) {
      window.location = CONFIG.PORTAL_URI
    }
    this.props.history.push('/', {
      id: this.state?.id
    })
    // window.location.href = `${CONFIG.PORTAL_URI}`;
  };

  render() {
    const role = localStorage.getItem('role')
    return (
      <div className="header">
        <Container>
          {/* <nav className="headad2">
          {
            role === 'superadmin' ?
              <Link to='/dashboard' onClick={this.closeMobileMenu} >
                <img alt="alternate" src={pclogo} style={{ paddingLeft: "9.3%", height: "45px", marginBottom: "0px", paddingRight: "0px", float: "left", marginTop: "7px" }} />
              </Link>
              :
              role === 'Mosque' ?
                <Link to='/OrdersPagination' onClick={this.closeMobileMenu} >
                  <img alt="alternate" src={pclogo} style={{ paddingLeft: "9.3%", height: "45px", marginBottom: "0px", paddingRight: "0px", float: "left", marginTop: "7px" }} />
                </Link>
                :
                <Link to='/BusinessOrdersPagination' onClick={this.closeMobileMenu} >
                  <img alt="alternate" src={pclogo} style={{ paddingLeft: "9.3%", height: "45px", marginBottom: "0px", paddingRight: "0px", float: "left", marginTop: "7px" }} />
                </Link>
          }
          {
            role === 'superadmin' ?
              <div style={{ paddingLeft: "81%", paddingTop: "1.3%" }}>
                <Link onClick={this.handleHome} to='/' style={{ color: "#374767", display: "inline-block", paddingRight: "20px" }}>
                  <i style={{ width: "32px", height: "32px", fontSize: "20px", display: "block", border: "solid 1px #ddd", padding: "3px" }} className="fas fa-home"></i>
                </Link>
                <div className="headad">
                  <Logouticon />
                </div>
              </div>
              :
              role === 'Mosque' ?
                <div style={{ paddingLeft: "81%", paddingTop: "1.3%" }}>
                  <Link onClick={this.handleHome} to='/' style={{ color: "#374767", display: "inline-block", paddingRight: "20px" }}>
                    <i style={{ width: "32px", height: "32px", fontSize: "20px", display: "block", border: "solid 1px #ddd", padding: "3px" }} className="fas fa-home"></i>
                  </Link>
                  <div className="headad">
                    <Logouticon />
                  </div>
                </div>
                :
                <div style={{ paddingLeft: "81%", paddingTop: "1.3%" }} >
                  <Link onClick={this.handleHome} to='/' style={{ color: "#374767", display: "inline-block", paddingRight: "20px" }}>
                    <i style={{ width: "32px", height: "32px", fontSize: "20px", display: "block", border: "solid 1px #ddd", padding: "3px" }} className="fas fa-home"></i>
                  </Link>
                  <div className="headad">
                    <Logouticon />
                  </div>
                </div>
          }
        </nav> */}
          <nav className="navbar navbar-expand-lg">
            {
              role === 'superadmin' ?
                <Link to='/' onClick={this.closeMobileMenu} >
                  <img alt="logo" src={pclogo} height="45px" />
                </Link>
                :
                role === 'Mosque' ?
                  <Link to='/' onClick={this.handleHome} >
                    <img alt="logo" src={pclogo} height="45px" />
                  </Link>
                  :
                  <Link to='/' onClick={this.handleHome} >
                    <img alt="logo" src={pclogo} height="45px" />
                  </Link>
            }
            {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button> */}
            <ul class="navbar-links ml-auto" >
              <li class="nav-item active">
                <Link class="nav-link" to="">
                  {/* {
                      role === 'superadmin' ? */}
                  <div >
                    <Link onClick={this.handleHome} to='' >
                      <i id="home-icon" className="fas fa-home"></i>
                    </Link>
                  </div>
                  {/* :
                        role === 'Mosque' ?
                          <div style={{ paddingLeft: "81%", paddingTop: "1.3%" }}>
                            <Link onClick={this.handleHome} to='/' style={{ color: "#374767", display: "inline-block", paddingRight: "20px" }}>
                              <i style={{ width: "32px", height: "32px", fontSize: "20px", display: "block", border: "solid 1px #ddd", padding: "3px" }} className="fas fa-home"></i>
                            </Link>
                          </div>
                          :
                          <div style={{ paddingLeft: "81%", paddingTop: "1.3%" }} >
                            <Link onClick={this.handleHome} to='/' style={{ color: "#374767", display: "inline-block", paddingRight: "20px" }}>
                              <i style={{ width: "32px", height: "32px", fontSize: "20px", display: "block", border: "solid 1px #ddd", padding: "3px" }} className="fas fa-home"></i>
                            </Link>
                          </div>
                    } */}
                </Link>
              </li>
              <li class="nav-item">
                {/* <Link class="nav-link" to="" > */}
                  {
                    <div >
                      <Logouticon />
                    </div>
                  }
                {/* </Link> */}
              </li>
            </ul>
            {/* <div class="collapse navbar-collapse" id="navbarNav">
              
            </div> */}
          </nav>
        </Container>
      </div>
    )
  }
}
export default withRouter(Headerad);