import React from 'react'
import { Tab } from 'semantic-ui-react';
import ToMosque from './ToMosque';
import 'bootstrap/dist/css/bootstrap.min.css';
import ToAdvertiser from './ToAdvertiser';
const style = <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/semantic-ui@2.4.1/dist/semantic.min.css'/>

const panes = [
  { menuItem: 'To Mosque', render: () => <Tab.Pane ><ToMosque /></Tab.Pane> },
  { menuItem: 'To Advertiser', render: () => <Tab.Pane ><ToAdvertiser /></Tab.Pane> },
]

const TabMakePayment = () => <Tab panes={panes}style={{ marginTop: "10px", width: "560px", boxShadow: "0 3px rgba(48,48,48,.1)",}} />

export default () => (<div>{style}<TabMakePayment/></div>)