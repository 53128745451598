import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Row,Col} from 'reactstrap';
import './footer1.css'

class Footeradmin extends Component {
    render() {
        return (
            <>
            
                <div className="admnfoot">
                    <Row>
                        <Col style={{textAlign: "right",marginLeft: "20%"}}>
                            <div className="txt"><a target="_blank" href="/Termsofservices" style={{ color: "#0b66c1" }}>Terms of Service </a>|<strong>  Copyright © 2021 </strong><Link to="/" style={{ color: "#0b66c1" }}><strong>Prayer Calendars.</strong></Link></div>
                        </Col>
                        <Col style={{textAlign: "right"}}>
                            <div style={{color: "#0b66c1"}}>
                                <strong><a rel="noopener noreferrer" target="_blank" href="https://www.btrees.in/" style={{textDecoration: "none", color: "inherit"}}>Developed By BTrees Technologies</a></strong>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
export default Footeradmin;