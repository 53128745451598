import axios from 'axios';
// import { LogoutAll } from './Helper';

const instance = axios.create({
  baseURL: 'URL/api'
});

const token = JSON.parse(localStorage.getItem('token')) || localStorage.getItem('mosquetoken') || localStorage.getItem('businesstoken');
if (token) {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

instance.interceptors.request.use(
  config => {
    const token = JSON.parse(localStorage.getItem('token')) || localStorage.getItem('mosquetoken') || localStorage.getItem('businesstoken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });
// instance.interceptors.response.use(
//   response => {
//     return response
//   },
//   error => {
//     const originalRequest = error.config;
//     if (error?.response?.status === 401 && !originalRequest._retry) {
//       LogoutAll();
//     }
//     return error
//   }
// );

export default instance;