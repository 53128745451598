import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Form, } from 'reactstrap';
import './ViewOrder.css'
import './MakePayment.css'
import TabMakePayment from './MakeTabs';

const MakePayment = () => {
    
  
    const [modal, setModal] = useState(false);
    // const [unmountOnClose, setUnmountOnClose] = useState(false);
  
    const toggle = () => setModal(!modal);
    // const changeUnmountOnClose = e => {
    //     let value = e.target.value;
    //     setUnmountOnClose(JSON.parse(value));
    // }
  
    return (
        <div>
            <Form inline onSubmit={(e) => e.preventDefault()}  >
                {/* <FormGroup>
                    <Label for="unmountOnClose">UnmountOnClose value</Label>{' '}
                    <Input type="select" name="unmountOnClose" id="unmountOnClose" onChange={changeUnmountOnClose}>
                        <option value="true">true</option>
                        <option value="false">false</option>
                    </Input>
                </FormGroup> */}
                {' '}
                <button className="makerecv" onClick={toggle}>Make Payment</button>
            </Form>
            <Modal isOpen={modal} toggle={toggle} contentClassName="modal"  unmountOnClose={false} >
                <ModalHeader toggle={toggle} style={{color: "#374767", fontFamily: 'WebFonts', fontSize: "20px",}} className="tog1">Make Payment</ModalHeader>
                <TabMakePayment />
                <ModalBody >
                    {/* <Example /> */}
                    {/* <Input type="textarea" placeholder="Write something (data should remain in modal if unmountOnClose is set to false)" rows={5} /> */}
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter> */}
            </Modal>
        </div>
    );
}

export default MakePayment;