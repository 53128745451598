import React, { Component } from 'react';
import axios from 'axios';
import '../../admin/Mosque/NewMosque.css';
import { Row, Col, Label, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import temp1 from '../../assets/images/PRAYERTIME5.png'
import temp2 from '../../assets/images/PRAYERTIME6.png'
import temp3 from '../../assets/images/PRAYERTIME1.png'
import temp4 from '../../assets/images/PRAYERTIME2.png'
import temp5 from '../../assets/images/Template1.png'
import temp6 from '../../assets/images/Template2.png'
import temp7 from '../../assets/images/Template3.png'
import temp8 from '../../assets/images/Template4.png';
import Adminheader from '../AdminNavBar';
import Headerad from '../../components/pages/headeradmin';
import Footeradmin from '../../Footer/Footeradmin';
import { message, Input } from 'antd';

import PlacesAutocomplete from 'react-places-autocomplete';
import { CONFIG } from '../../utils/constants';
import { GoogleApiWrapper } from 'google-maps-react';
import DefaultMap from '../../DefaultMap';
import { LoadingOutlined } from '@ant-design/icons';
//import LoaderGif from '../../assets/images/loader3.gif'


//Prayertimes section
import '../../masjiddashboard/prayertimes.css';
import '../../masjiddashboard/settings/mosquesetting.css'
import '../Advertisers/NewAdvertiser.css'

import { TimePicker } from 'antd';







class Pendingactivationdetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			// data: {
			// 	lat: '',
			// 	long: '',
			// },
			MosqueName: '',
			schoolOfPrayerTimes: "Hanafi",
			juristicForPrayerTimes: "Umm Al-Qura University makkah",
			MosqueCapacity: "",
			AverageJumaAttendees: "",
			Address: "",
			FirstName: "",
			LastName: "",
			phoneNumber: "",
			email: "",
			state: "",
			city: "",
			zipcode: "",
			// password: "",
			commisionType: "Is Amount",
			commisionValue: "",
			priceFactor: "",
			BankName: "",
			AccountTitle: "",
			RoutingNo: "",
			AccountNo: "",
			Quantity: "100",
			BackgroundColor: "#ffff",
			selecTedtemplate: "",
			HeaderImage: "",
			file: null,
			i_agree: false,
			googleAddress: '',
			googleFormattedAddress: '',
			isLoading: false,
			isHijriCalendar: 'no',
			isRamadanCalendar: 'no',
			isNoIqamaCalendar: 'no',


			//Prayertimes section
			addMoreCount: 0,
			nodes: [],
			prayerTimes: [],
			month: new Date().getMonth() + 2,
			year: new Date().getFullYear(),
			id: '',
			mosqueId: this.props.location?.state?.id,
			formData: [],
			setCount: 2,
			maxdate: 0,
			// isLoading: false,
			bgcolor: ['#d6ed17ff', '#adefd1ff'],
			hideButton: true,
			repeatedSet: true,


		}
		this.handleLogin = this.handleLogin.bind(this)
	}









	//Prayertimes section starts here
	monthhandler = (event) => {
		this.setState({
			month: event.target.value,
			nodes: [],
			formData: [],
			maxdate: 0,
			setCount: 0
		}, _ => this.setState({ nodes: [this.addMoreSet(1)] }))
	}

	convertMonth = month => {
		let months = ["January", "February", "March", "April", "May", "June", "July",
			"August", "September", "October", "November", "December"];
		let mth = '';
		if (month) {
			mth = months[month - 1]
		}
		return mth;
	}

	yearhandler = (event) => {
		this.setState({
			year: event.target.value
		})
	}

	fromdatehandler = (event) => {
		this.setState({
			fromdate: event.target.value
		})
	}

	todatehandler = (event) => {
		this.setState({
			todate: event.target.value
		})
	}

	fajriqamahandler = (event) => {
		this.setState({
			fajriqama: event.target.value
		})
	}




	getPrayertimes = () => {
		this.setState({ isLoading: true }, () => {
			const mosqueId = this.props.location?.state?.id;
			axios({
				method: 'get',
				url: `${CONFIG.API_END_POINT}api/v1/common/getPrayerTimes`,
				params: { id: mosqueId, year: this.state.year, month: this.state.month }
			}).then((response) => {
				this.setState({
					prayerTimes: response.data.success,
					isLoading: false
				});
			})
		})
	}

	// componentDidMount() {
	//   this.setState({ isLoading: true, nodes: [this.addMoreSet(1)] }, () => {
	//     this.getPrayertimes()
	//   })
	// }

	handleSubmit = (event) => {
		window.scrollTo(0, 0)
		event.preventDefault()
		const mosqueId = this.props.location?.state?.id;
		let obj = {}
		obj.year = this.state.year
		obj.month = this.state.month
		obj.fajrIqama = this.state.fajriqama
		obj.ArrayofDates = this.state.arrayofdates
		this.setState({ isLoading: true }, () => {
			axios({
				method: 'post',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				url: `${CONFIG.API_END_POINT}api/v1/common/setPrayerTime`,
				data: obj,
				params: { id: mosqueId }
			}).then((response) => {
				if (response.data?.success) {
					alert("Prayer times added successfully")
					this.getPrayertimes()
				} else {
					alert("Prayer times could not be set")
				}
				this.setState({ isLoading: false })
			})
			this.Close();
		})
	}

	resetTimings = async () => {
		window.scrollTo(0, 0)
		this.setState({ isLoading: true }, async () => {
			try {
				const res = await axios.post(`${CONFIG.API_END_POINT}api/v1/common/resetPrayerTimes`, {
					id: this.state.mosqueId,
					year: this.state.year,
					month: this.state.month
				})
				this.setState({ isLoading: false }, () => {
					setTimeout(() => {
						if (res.data?.success) {
							alert("Prayer timings reset successfully.")
							this.getPrayertimes();
						}
					}, 100)
				})
			} catch (err) {
				console.log(err)
			}
		})
	};

	options = (limit, unChanged, isCapped) => {
		const options = [];
		if (unChanged) {
			options.push(<option key={'unchanged'} value={'unchanged'}>0 Minutes</option>)
		}
		let min = 1
		if (isCapped && Number(this.state?.maxdate)) {
			min = this.state?.maxdate
		}
		let newLimit = limit;
		if (newLimit === 31) {
			newLimit = new Date(this.state.year, this.state.month, 0).getDate();
			for (let i = min; i <= newLimit; i++) {
				options.push(<option key={i} value={i}>{`${i}`}</option>);
			}
		} else {
			for (let i = min; i <= newLimit; i++) {
				options.push(<option key={i} value={i}>{`${i} ${unChanged ? 'Minutes' : ''}`}</option>);
			}
		}
		return options;
	};

	setDropdownValue = (e, index) => {
		const name = e.target.name;
		const value = e.target.value;
		const idx = index;
		const obj = this.state.formData.find(obj => obj.id === idx);
		if (obj) {
			const formData = this.state.formData.map(
				instance => {
					if (instance.id === obj.id) {
						return {
							...instance,
							[name]: value
						};
					}
					return instance;
				}
			)
			let maxdate = this.state.maxdate
			if (name === "ToDate") {
				maxdate = Number(value) + 1
			}
			this.setState({ formData: formData, maxdate })
		} else {
			const obj = {
				"FromDate": this.state.maxdate === 0 ? "1" : this.state.maxdate?.toString(),
				"ToDate": "1",
				"fajrIqama": "unchanged",
				"dhuhrIqama": "unchanged",
				"asrIqama": "unchanged",
				"maghribIqama": "unchanged",
				"ishaIqama": "unchanged",
				"jummaIqama1": "unchanged",
				"jummaIqama2": "unchanged",
				"jummaIqama3": "unchanged",
				[name]: value,
				id: idx
			};
			let maxdate = this.state.maxdate
			if (name === "ToDate") {
				maxdate = Number(value) + 1
			}
			this.setState(prevState => ({
				formData: [...prevState.formData, obj], maxdate
			}))
		}
	}


	setTime = (time) => {
		this.setState({
			value: time
		})
	}

	setTimevalue = (e, index, name, timeobj) => {
		const time = e.slice(0, 5)
		// const name = name;
		// const value = e.target.value;
		const idx = index;
		const obj = this.state.formData.find(obj => obj.id === idx);
		if (obj) {
			const formData = this.state.formData.map(
				instance => {
					if (instance.id === obj.id) {
						return {
							...instance,
							[name]: time,
							[`${name}-t`]: timeobj ?? null
						};
					}
					return instance;
				}
			)
			this.setState({ formData: formData })
		} else {
			const obj = {
				"FromDate": "1",
				"ToDate": "1",
				"fajrIqama": "unchanged",
				"dhuhrIqama": "unchanged",
				"asrIqama": "unchanged",
				"maghribIqama": "unchanged",
				"ishaIqama": "unchanged",
				"jummaIqama1": "unchanged",
				"jummaIqama2": "unchanged",
				"jummaIqama3": "unchanged",
				[name]: time,
				id: idx,
				[`${name}-t`]: timeobj ?? null
			};
			this.setState(prevState => ({
				formData: [...prevState.formData, obj]
			}))
		}
	}

	iqamaChangeHandler = e => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value })
	}

	test = (e) => {
		//
	}

	removeFieldSet = index => {
		//Remove fieldset
		let nodes = this.state.nodes;
		delete nodes[index - 1]
		// nodes.splice(index - 1, 1);


		//Remove object from formData
		const formData = this.state.formData;
		const idx = formData.findIndex(d => d.id === index);

		if (idx > -1) {
			formData.splice(idx, 1);
		}

		this.setState({ nodes, formData })
		console.log(index)
	};

	addMoreSet = (ind = 1) => {
		const idx = ind;
		return (
			<div key={idx + Math.random()} >
				{/* {
          this.state?.isLoading &&
          <img alt="" src={LoaderGif} style={{ top: "10%", position: "absolute", left: "45%", height: "200px", width: "500px", zIndex: "1000", }} /> // background: 'rgb(255,255,255,0.6)'
        } */}
				{/* <div> */}

				<div class="repeated-set" style={{ marginBottom: "1rem", padding: "10px" }} >
					{
						this.state.nodes.length > 0 && (
							<div class="row">
								<div class="col-md-12 text-right" ><div style={{ color: "#d21a1a" }} class="close-repeated-set"><i class="fas fa-times" style={{ cursor: 'pointer' }} onClick={_ => this.removeFieldSet(ind)} ></i></div></div>
							</div>
						)
					}
					<div class="row">
						<div class="col-md-3 p-2" >
							<div className="time-col" style={{ background: "#ffa6ae" }}>
								<div class="row">
									<div class="mb-3 col-md-12">
										<label for="fromday" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>From {this.convertMonth(this.state.month)}</label>
										<select name="FromDate" onChange={e => this.setDropdownValue(e, idx)} placeholder="1" >
											{this.options(31, null, true)}
										</select>
									</div>
								</div>
								<div class="row">
									<div class="mb-3 col-md-12">
										<label for="fromday" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>To {this.convertMonth(this.state.month)}</label>
										<select name="ToDate" onChange={e => this.setDropdownValue(e, idx)} placeholder="1" >
											{this.options(31, null, true)}
										</select>
									</div>
								</div>
							</div><br />
							{/* {
                this.state.hideButton === true ? 
                <Row>
              <Col md={12}>
                <Row>
                  <Col><span style={{ float: "left", color: "#D21A1A", fontWeight: "bold", fontSize: "0.8vw" }}>Click on the button below to add more Iqama time fields<i class="fa fa-hand-point-down" style={{ marginLeft: '2px', height: "20px", width: "20px", fontSize: "0.8vw" }}></i></span></Col>
                </Row>
                <button style={{ padding: "6px 12px", border: "solid 1px #ccc", backgroundColor: "white", color: "#333", float: "left", }} type="submit" onClick={this.increseCounter}>Add more dates</button>
              </Col>
              </Row>
              : 
              null
              } */}
						</div>

						<div class="col-md-6 p-2" >
							<div className="time-col" style={{ background: "#76eaff" }}>
								<div class="firstrow row mb-3">

									<div class="col-md-12 d-flex flex-md-row flex-column align-items-md-end align-items-center">
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)" }}>Set Fajr Iqama after</label>
											<select style={{ height: "40px", width: "100%" }} name="fajrIqama" onChange={e => this.setDropdownValue(e, idx)} >
												{this.options(59, true)}
											</select>
										</div>
										<div style={{ margin: "10px 10px 12px", fontSize: "12px", fontWeight: "bold", color: "#707070" }} >OR</div>
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px", }}>Enter Fixed Time</label>
											<TimePicker id="timepicker" name="fajrIqama" onChange={(e, time) => this.setTimevalue(time, idx, 'fajrIqama')} style={{ width: "100%", padding: "7px 12px", display: "inline-block", height: "40px", border: "solid 1px black" }} use12Hours format="h:mm a" />
										</div>
									</div>

									{/* <div className="col-md-5">
                    <label for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)" }}>Set Fajr Iqama after</label>
                    <select style={{ height: "40px", width: "100%" }} name="fajrIqama" onChange={e => this.setDropdownValue(e, idx)} >
                      {this.options(59, true)}
                    </select>
                  </div>
                  <div className="col-md-1 text-center">
                    <label></label>
                    OR
                  </div>
                  <div className="col-md-5">
                    <label for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px", }}>Enter Fixed Time</label>
                    <TimePicker id="timepicker" name="fajrIqama" onChange={(e, time) => this.setTimevalue(time, idx, 'fajrIqama')} style={{ width: "100%", padding: "7px 12px", display: "inline-block", height: "40px", border: "solid 1px black" }} use12Hours format="h:mm a" />
                  </div> */}


								</div>



								<div class="firstrow row mb-3">
									<div class="col-md-12 d-flex flex-md-row flex-column align-items-md-end align-items-center">
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", }}>Set Dhuhr Iqama after</label>
											<select style={{ height: "40px", width: "100%" }} name="dhuhrIqama" onChange={e => this.setDropdownValue(e, idx)} >
												{this.options(59, true)}
											</select>
										</div>
										<div style={{ margin: "10px 10px 12px", fontSize: "12px", fontWeight: "bold", color: "#707070" }} >OR</div>
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>Enter Fixed Time</label>
											<TimePicker id="timepicker" name="dhuhrIqama" onChange={(e, time) => this.setTimevalue(time, idx, 'dhuhrIqama')} style={{ width: "100%", padding: "7px 12px", display: "inline-block", height: "40px", border: "solid 1px black" }} use12Hours format="h:mm a" />
										</div>
									</div>
								</div>

								<div class="firstrow row mb-3">
									<div class="col-md-12 d-flex flex-md-row flex-column align-items-md-end align-items-center">
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)" }}>Set Asr Iqama after</label>
											<select style={{ height: "40px", width: "100%" }} name="asrIqama" onChange={e => this.setDropdownValue(e, idx)} >
												{this.options(59, true)}
											</select>
										</div>
										<div style={{ margin: "10px 10px 12px", fontSize: "12px", fontWeight: "bold", color: "#707070" }} >OR</div>
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>Enter Fixed Time</label>
											<TimePicker id="timepicker" name="asrIqama" onChange={(e, time) => this.setTimevalue(time, idx, 'asrIqama')} style={{ width: "100%", padding: "7px 12px", display: "inline-block", height: "40px", border: "solid 1px black" }} use12Hours format="h:mm a" />
										</div>
									</div>
								</div>

								<div class="firstrow row mb-3">
									<div class="col-md-12 d-flex flex-md-row flex-column align-items-md-end align-items-center">
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", }}>Set Maghrib Iqama after</label>
											<select style={{ height: "40px", width: "100%" }} name="maghribIqama" onChange={e => this.setDropdownValue(e, idx)} >
												{this.options(59, true)}
											</select>
										</div>
										<div style={{ margin: "10px 10px 12px", fontSize: "12px", fontWeight: "bold", color: "#707070" }} >OR</div>
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>Enter Fixed Time</label>
											<TimePicker id="timepicker" name="maghribIqama" onChange={(e, time) => this.setTimevalue(time, idx, 'maghribIqama')} style={{ width: "100%", padding: "7px 12px", display: "inline-block", height: "40px", border: "solid 1px black" }} use12Hours format="h:mm a" />
										</div>
									</div>
								</div>

								<div class="firstrow row mb-3">
									<div class="col-md-12 d-flex flex-md-row flex-column align-items-md-end align-items-center">
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)" }}>Set Isha Iqama after</label>
											<select style={{ height: "40px", width: "100%" }} name="ishaIqama" onChange={e => this.setDropdownValue(e, idx)} >
												{this.options(59, true)}
											</select>
										</div>
										<div style={{ margin: "10px 10px 12px", fontSize: "12px", fontWeight: "bold", color: "#707070" }} >OR</div>
										<div className="select-iqama">
											<label className="select-label" for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>Enter Fixed Time</label>
											<TimePicker id="timepicker" name="ishaIqama" onChange={(e, time) => this.setTimevalue(time, idx, 'ishaIqama')} style={{ width: "100%", padding: "7px 12px", display: "inline-block", height: "40px", border: "solid 1px black" }} use12Hours format="h:mm a" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3 p-2" >
							<div className="time-col" style={{ background: "#ffbd54" }}>
								<div class="row mb-3">
									<div class="col">
										<label for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>
											Set Juma1 Iqama
										</label><br />
										<TimePicker id="timepicker" defaultValue={(this.state?.formData[0]?.jummaIqama1 === 'unchanged') ? null : this.state?.formData[0]?.[`jummaIqama1-t`]} style={{ width: "100%", height: "40px", border: "solid 1px black" }} name="jummaIqama1" onChange={(e, time) => this.setTimevalue(time, idx, 'jummaIqama1', e)} use12Hours format="h:mm a" />
									</div>
								</div>

								<div class="row mb-3">
									<div class="col">
										<label for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>
											Set Juma2 Iqama
										</label><br />
										<TimePicker id="timepicker" defaultValue={(this.state?.formData[0]?.jummaIqama2 === 'unchanged') ? null : this.state?.formData[0]?.[`jummaIqama2-t`]} style={{ width: "100%", height: "40px", border: "solid 1px black" }} name="jummaIqama2" onChange={(e, time) => this.setTimevalue(time, idx, 'jummaIqama2', e)} use12Hours format="h:mm a" />
									</div>
								</div>

								<div class="row mb-3">
									<div class="col">
										<label for="setasriqamaSetJuma1Iqamaafter(x)minutesofDhuhrafter" style={{ color: "rgb(55, 71, 103)", fontWeight: "700", marginBottom: "5px" }}>
											Set Juma3 Iqama
										</label><br />
										<TimePicker id="timepicker" defaultValue={(this.state?.formData[0]?.jummaIqama3 === 'unchanged') ? null : this.state?.formData[0]?.[`jummaIqama3-t`]} style={{ width: "100%", height: "40px", border: "solid 1px black" }} name="jummaIqama3" onChange={(e, time) => this.setTimevalue(time, idx, 'jummaIqama3', e)} use12Hours format="h:mm a" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* </div> */}

			</div>
		);
	}

	formatTimeValue = time => {
		let output = '';
		if (time) {
			const timeArr = time.split(':');


			// console.log('iiii', timeArr[0][0])
			if (timeArr[0].length === 1 && timeArr[1]?.length === 1) {
				const hh = `${timeArr[0]}`
				const mm = `0${timeArr[1]}`
				output = hh + ":" + mm;
			} else {
				if (timeArr[0][0] === '0') {
					const hh = timeArr[0].slice(1)
					const mm = timeArr[1]
					output = hh + ":" + mm;
				} else {
					const hh = `${timeArr[0]}`
					const mm = timeArr[1]
					output = hh + ":" + mm;
				}
			}

			// const hh = (timeArr[0]?.length === 1) ? `${timeArr[0]}` : timeArr[0].slice(1);
			// const mm = (timeArr[1]?.length === 1) ? `0${timeArr[1]}` : timeArr[1];
			// output = hh + ":" + mm;
		}
		return output;
	};

	applyChanges = async e => {
		window.scrollTo(0, 0)
		e.preventDefault();
		const apiData = {};
		apiData.id = this.state.mosqueId;
		apiData.year = this.state.year;
		apiData.month = this.state.month;
		if (this.state.jummaIqama1 !== 'unchanged')
			apiData.jummaIqama1 = this.state.jummaIqama1;
		if (this.state.jummaIqama2 !== 'unchanged')
			apiData.jummaIqama2 = this.state.jummaIqama2;
		if (this.state.jummaIqama3 !== 'unchanged')
			apiData.jummaIqama3 = this.state.jummaIqama3;
		apiData.ArrayofDates = this.state.formData.map(
			item => {
				Object.keys(item).map(
					obj => {
						if (obj === "jummaIqama1" || obj === "jummaIqama2" || obj === "jummaIqama3") {
							if (item[obj] === 'unchanged') {
								item[obj] = this.state?.formData[0]?.[obj]
								// console.log("working", item[obj]) 
							}
						}
						if (item[obj] === 'unchanged') {
							delete item[obj];
						}
						if (typeof item[obj] === 'string' && item[obj].includes(':') && item[obj].split(':')[0].length === 1) {
							item[obj] = `0${item[obj]}`.trim();
						}
						return null;
					}
				)
				delete item.id;
				return item;
			}
		)
		this.setState({ isLoading: true }, async () => {
			try {
				const res = await axios.post(`${CONFIG.API_END_POINT}api/v1/common/updatePrayerTimes`, apiData);
				this.setState({ isLoading: false }, () => {
					setTimeout(() => {
						if (res.data.success) {
							this.setState({ formData: [], maxdate: 0, setCount: 0 }, _ => {
								setTimeout(() => {
									this.setState({ nodes: [this.addMoreSet(1)] })
								}, 1000)
							})
							alert("Changes saved successfully.");
							// Swal.fire({
							//   showCancelButton: true,
							//   confirmButtonText: 'Back to profile',
							//   cancelButtonText: 'Cancel',
							//   cancelButtonColor: "red",
							//   text: "Changes saved successfully. Please click on Back to Profile to enter bank information to receive your share of revenues from your local advertisers."
							// }).then((result) => {
							//   if (result.isConfirmed) {
							//     this.props.history.push('/settings/profile')
							//   } else {
							//     return false
							//   }
							// })
							this.getPrayertimes();
						} else {
							message.error(res.data.error)
						}
					}, 100)
				})
			} catch (err) {
				console.log(err)
			}
		})
	};

	returnMonth = month => {
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		if (month) {
			var Month = months[month]
		}
		return Month
	}


	increseCounter = () => {
		this.setState({ closable: true }, () => {
			this.setState(prevState => ({ addMoreCount: prevState.addMoreCount + 1, nodes: [...prevState.nodes, this.addMoreSet(prevState.setCount)] }),
				() => {
					this.setState(prevState => ({ setCount: prevState.setCount + 1 }))
				})
		})
	};



	handleJumma = e => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value });
	};

	//Prayertimes section ends here























	// onFileChange = event => {
	// 	// Update the state 
	// 	this.setState({ image: event.target.files[0] });
	// 	this.setState({ imagePath: URL.createObjectURL(event.target.files[0]) })
	// };


	onFileChange = (event) => {
		const reader = new FileReader();
		const fileTarget = event.target.files[0];
		if (!fileTarget?.name.match(/\.(jpg|jpeg|png)$/)) {
			alert("Only JPG, JPEG and PNG files are allowed");
		} else {
			reader.readAsDataURL(fileTarget);
			reader.onload = () => {
				const img = new Image();
				const file = URL.createObjectURL(fileTarget);
				img.src = file;
				img.onload = () => {
					const imgAspectRatio = img.width / img.height;
					const validAspectRatio = 1575 / 300;
					const validAspectObj = {
						min: validAspectRatio - (0.1 * validAspectRatio),
						max: validAspectRatio + (0.1 * validAspectRatio)
					};

					if (img.width < 1575 || img.height < 300) {
						alert("Please check minimum resolution");
					} else {
						const imageDPI = img.height / 1;
						// const fileAspectRatio = img.width / img.height;
						if (imageDPI < 300) {
							// alert(`Image aspect ratio must be between ${validAspectRatio.min} and ${validAspectRatio.max}`);
							alert("Image is less than 300 DPI, please upload image greater than 300 DPI or work with designer");
						} if (imgAspectRatio < validAspectObj.min || imgAspectRatio > validAspectObj.max) {
							alert(`Aspect ratio of the image not as expected. Image will not print correctly. Please work with the designer to get the best image`)
							this.setState({ file: fileTarget })
							this.setState({ imagePath: URL.createObjectURL(fileTarget) })
							message.success('Image uploaded successfully');
						} else {
							this.setState({ file: fileTarget })
							this.setState({ imagePath: URL.createObjectURL(fileTarget) })
							message.success('Image uploaded successfully');
						}
					}
				};
			};
		}
		// this.setState({selectedFile: event.target.files[0]})
		// this.setState({file: URL.createObjectURL(event.target.files[0])})
	}


	mosquenamehandler = (event) => {
		this.setState({
			MosqueName: event.target.value
		})
	}
	firstnamehandler = (event) => {
		this.setState({
			FirstName: event.target.value
		})
	}
	lastnamehandler = (event) => {
		this.setState({
			LastName: event.target.value
		})
	}
	emailhandler = (event) => {
		this.setState({
			email: event.target.value
		})
	}
	phonenumberhandler = (event) => {
		this.setState({
			phoneNumber: event.target.value
		})
	}
	// passwordhandler = (event) => {
	//     this.setState({
	//         password: event.target.value
	//     })
	// }
	// confirmpasswordhandler = (event) => {
	//     this.setState({
	//         confirmpassword: event.target.value
	//     })
	// }
	addressline1handler = (event) => {
		this.setState({
			Address: event.target.value
		})
	}
	statehandler = (event) => {
		this.setState({
			state: event.target.value
		})
	}
	cityhandler = (event) => {
		this.setState({
			city: event.target.value
		})
	}
	zipcodehandler = (event) => {
		this.setState({
			zipcode: event.target.value
		})
	}
	selectprayertimeshandler = (event) => {
		this.setState({
			schoolOfPrayerTimes: event.target.value
		})
	}
	selectjuristicprayertimeshandler = (event) => {
		this.setState({
			juristicForPrayerTimes: event.target.value
		})
	}
	mosquecapacityhandler = (event) => {
		this.setState({
			MosqueCapacity: event.target.value
		})
	}
	averagejumaattendeeshandler = (event) => {
		this.setState({
			AverageJumaAttendees: event.target.value
		})
	}
	commissiontypehandler = (event) => {
		this.setState({
			commisionType: event.target.value
		})
	}
	commissionvaluehandler = (event) => {
		this.setState({
			commisionValue: event.target.value
		})
	}
	pricefactorhandler = (event) => {
		this.setState({
			priceFactor: event.target.value
		})
	}
	banknamehandler = (event) => {
		this.setState({
			BankName: event.target.value
		})
	}
	accounttitlehandler = (event) => {
		this.setState({
			AccountTitle: event.target.value
		})
	}
	routingnumberhandler = (event) => {
		this.setState({
			RoutingNo: event.target.value
		})
	}
	accountnumberhandler = (event) => {
		this.setState({
			AccountNo: event.target.value
		})
	}
	quantityhandler = (event) => {
		this.setState({
			Quantity: event.target.value
		})
	}
	selectprintbackgroundhandler = (event) => {
		this.setState({
			BackgroundColor: event.target.value
		})
	}

	selectColor = val => {
		const visible = val;
		this.setState({ showSelectBtn: visible });
	};

	checkRamadan = e => {
    if(e.target.value === 'yes') {
			this.setTemplate('RTemplate1');
      for (let i = 0; i < 8; i++) {
        document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'pointer';
        document.getElementById(`test${i + 1}`).style.pointerEvents = 'auto';
      }
    }
    
    let checkboxes = document.getElementsByName('ramadanCheck');
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });
    this.setState({ isRamadanCalendar: e.target.value, isNoIqamaCalendar: 'no', isHijriCalendar: 'no' });
  }

  checkNoIqama = e => {
    if(e.target.value === 'yes') {
      this.setTemplate('Template3');
      for (let i = 0; i < 8; i++) {
        document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'not-allowed';
        document.getElementById(`test${i + 1}`).style.pointerEvents = 'none';
      } 
    }else {
			this.setTemplate(this.state.backendTemplate);
              for (let i = 0; i < 8; i++) {
                document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'pointer';
                document.getElementById(`test${i + 1}`).style.pointerEvents = 'auto';
              }
    }

    let checkboxes = document.getElementsByName('noIqamaCheck');
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });
    this.setState({ isNoIqamaCalendar: e.target.value }, _ => {
      if(this.state.isNoIqamaCalendar === 'yes') {
      this.setState({isHijriCalendar: "yes"})
      } else {
        this.setState({isHijriCalendar: "no"})
      }
    });
  }

  checkHijri = e => {
    const isHijri = e.target.value;
    if(isHijri === 'yes') {
      this.setTemplate('Template3');
      for (let i = 0; i < 8; i++) {
        document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'not-allowed';
        document.getElementById(`test${i + 1}`).style.pointerEvents = 'none';
      } 
    }else {
      this.setTemplate(this.state.backendTemplate);
              for (let i = 0; i < 8; i++) {
                document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'pointer';
                document.getElementById(`test${i + 1}`).style.pointerEvents = 'auto';
              }
    }
    
    let checkboxes = document.getElementsByName('hijriCheck');
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });

    this.setState({ isHijriCalendar: isHijri });
  }

  convertStringToBoolean = string => {
    let boolean;

    if(string === 'yes') {
      boolean = true;
    } else if(string === 'no') {
      boolean = false;
    };

    return boolean;
  }

	// checkOne = e => {
	// 	const value = e.target.value;
	// 	this.setState({ isHijriCalendar: value });
	// 	if (this.state.isRamadanCalendar === 'yes') {
	// 		if (value === 'yes') {
	// 			this.setTemplate('RTemplate1');
	// 			for (let i = 0; i < 1; i++) {
	// 				document.getElementById(`rtemp-wrap${i + 1}`).style.cursor = 'not-allowed';
	// 				document.getElementById(`rtest${i + 1}`).style.pointerEvents = 'none';
	// 			}
	// 		} else {
	// 			this.setTemplate(this.state.backendTemplate);
	// 			// this.setState({
	// 			//   selecTedtemplate: this.state.selecTedtemplate,
	// 			//   showBorder: false,
	// 			//   showColor: false,
	// 			// })
	// 			for (let i = 0; i < 1; i++) {
	// 				document.getElementById(`rtemp-wrap${i + 1}`).style.cursor = 'pointer';
	// 				document.getElementById(`rtest${i + 1}`).style.pointerEvents = 'auto';
	// 			}
	// 		}
	// 	} else {
	// 		if (value === 'yes') {
	// 			this.setTemplate('Template3');
	// 			for (let i = 0; i < 8; i++) {
	// 				document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'not-allowed';
	// 				document.getElementById(`test${i + 1}`).style.pointerEvents = 'none';
	// 			}
	// 		} else {
	// 			this.setTemplate(this.state.backendTemplate);
	// 			// this.setState({
	// 			//   selecTedtemplate: this.state.selecTedtemplate,
	// 			//   showBorder: false,
	// 			//   showColor: false,
	// 			// })
	// 			for (let i = 0; i < 8; i++) {
	// 				document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'pointer';
	// 				document.getElementById(`test${i + 1}`).style.pointerEvents = 'auto';
	// 			}
	// 		}
	// 	}

	// 	let checkboxes = document.getElementsByName('hijriCheck');
	// 	checkboxes.forEach((item) => {
	// 		if (item !== e.target) item.checked = false;
	// 	})
	// };

	setTemplate = template => {
		this.setState({
			selecTedtemplate: template,
			showBorder: true,
			showColor: true,
		}, () => {
			if (this.state.selecTedtemplate === 'Template1') {
				this.setState({
					selecTedtemplateFile: temp1
				})
			}
			if (this.state.selecTedtemplate === 'Template2') {

				this.setState({
					selecTedtemplateFile: temp4
				})
			}
			if (this.state.selecTedtemplate === 'Template3') {

				this.setState({
					selecTedtemplateFile: temp2
				})
			}
			if (this.state.selecTedtemplate === 'Template4') {

				this.setState({
					selecTedtemplateFile: temp3
				})
			}
			if (this.state.selecTedtemplate === 'Template5') {

				this.setState({
					selecTedtemplateFile: temp7
				})
			}
			if (this.state.selecTedtemplate === 'Template6') {

				this.setState({
					selecTedtemplateFile: temp6
				})
			}
			if (this.state.selecTedtemplate === 'Template7') {

				this.setState({
					selecTedtemplateFile: temp8
				})
			}
			if (this.state.selecTedtemplate === 'Template8') {

				this.setState({
					selecTedtemplateFile: temp5
				})
			}
			if (this.state.selecTedtemplate === 'RTemplate1') {

				this.setState({
					selecTedtemplateFile: temp2
				})
			}
		})

	}

	handlePlaces = googleAddress => {
		this.setState({ googleAddress }, () => {
			if (googleAddress?.length > 5) {
				this.sendLatlong()
			}
		})
	};


	sendLatlong = () => {
		axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.googleAddress}&key=${CONFIG.GOOGLE_API_KEY}`).then((response) => {
			const data = response?.data?.results[0]
			const lat = data?.geometry?.location?.lat
			const long = data?.geometry?.location?.lng
			this.setState({
				...this.state,
				lat: lat, long: long, mapCenter: { lat: lat, lng: long }
			})
		})
	}

	handleSearch = async e => {
		// e.preventDefault();
		try {
			const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.googleAddress}&key=${CONFIG.GOOGLE_API_KEY}`);
			const data = res.data;
			if (data.results[0]?.geometry?.location) {
				const coOrds = data.results[0].geometry.location;
				const address = data.results[0].formatted_address;
				this.setState({ mapCenter: coOrds, googleFormattedAddress: address, Address: address, googleAddress: address })
				// this.props.getMapCenterValue(coOrds)
				const addressDetails = data.results[0].address_components;
				const stateData = {};
				addressDetails.forEach(item => {
					if (item.types.includes('postal_code')) {
						stateData.zipcode = item.long_name;
					} else if (item.types.includes('locality') || item.types.includes('administrative_area_level_2')) {
						stateData.city = item.long_name;
					} else if (item.types.includes('administrative_area_level_1')) {
						stateData.state = item.long_name;
					}
				})
				this.setState(prevState => ({ ...prevState, ...stateData }))
			}
		} catch (err) {
			console.log(err)
		}
	};

	handleSelect = googleAddress => {
		this.setState({ googleAddress }, () => {
			axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.googleAddress}&key=${CONFIG.GOOGLE_API_KEY}`)
				.then(response => {
					const data = response.data?.results[0]
					const lat = data.geometry?.location?.lat
					const long = data.geometry?.location?.lng
					const formatAd = data?.formatted_address;
					this.setState({
						...this.state,
						lat: lat, long: long, googleFormattedAddress: formatAd,
					})
					// return getLatLng(response.data?.results[0])
					// this.handleSearch()
				})
				// .then(latLng => {
				//   // update center state
				//   this.setState({ mapCenter: latLng });
				// })
				.catch(error => {
					console.error('Error', error)
				})
		});
	};

	updateAddressByMarker = (Address, coordinates) => {
		this.setState({
			Address,
			...this.state,
			lat: coordinates.lat,
			long: coordinates.lng,
			googleAddress: Address
		})
	};

	handleChange = (e) => {
		this.setState({ i_agree: !this.state.i_agree });
	}


	Checkemail = () => {
		const email = this.state.email
		if (email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
			document.getElementById('emailmessage').innerHTML = '';
			document.getElementById('emailmessage').style.color = 'green';
		} else {
			document.getElementById('emailmessage').innerHTML = 'Please enter a valid email';
			document.getElementById('emailmessage').style.color = 'Red';
		}

	}

	Checkphoneno = () => {
		const email = this.state.phoneNumber
		if (email.match(/[0-9]{3,14}$/)) {
			document.getElementById('phonenumbermessage').innerHTML = '';
			document.getElementById('phonenumbermessage').style.color = 'green';
		} else {
			document.getElementById('phonenumbermessage').innerHTML = 'Please enter a valid phone number';
			document.getElementById('phonenumbermessage').style.color = 'Red';
		}

	}

	handleLogin = (event) => {
		window.scrollTo(0, 0)
		event.preventDefault();
		const formData = new FormData();
		const month = new Date().getMonth() + 1;
		let m = '';
		if (month) {
			if (month[0] !== '0') {
				m = `0${month}`
			} else {
				m = month;
			}
		}
		const year = new Date().getFullYear();

		if (this.state.oldAddress === this.state.googleAddress && this.state.oldSchool === this.state.schoolOfPrayerTimes && this.state.oldJuristic === this.state.juristicForPrayerTimes) {
			// console.log('same')
		} else {
			axios.get(CONFIG.API_END_POINT + 'api/v1/common/generatePrayerTimesOnMosqueEdit', {
				params: {
					id: this.state.id,
					month: m,
					year: year,
					lat: this.state.lat,
					long: this.state.long,
					school: this.state.schoolOfPrayerTimes,
					juristic: this.state.juristicForPrayerTimes
				}
			}).then(() => {
				setTimeout(() => { message.success('New prayer times have been generated please update your prayer timings') }, 2000)
			})
		}

		formData.append("id", this.state.id)
		formData.append("MosqueName", this.state.MosqueName);
		formData.append("schoolOfPrayerTimes", this.state.schoolOfPrayerTimes);
		formData.append("juristicForPrayerTimes", this.state.juristicForPrayerTimes);
		formData.append("MosqueCapacity", this.state.MosqueCapacity);
		formData.append("AverageJumaAttendees", this.state.AverageJumaAttendees);
		formData.append("Address", this.state.googleAddress);
		formData.append("FirstName", this.state.FirstName);
		formData.append("LastName", this.state.LastName);
		formData.append("phoneNumber", this.state.phoneNumber);
		formData.append("email", this.state.email);
		// // formData.append("password", 12345);
		formData.append("commisionType", this.state.commisionType);
		formData.append("commisionValue", this.state.commisionValue);
		formData.append("priceFactor", this.state.priceFactor);
		formData.append("BankName", this.state.BankName);
		formData.append("AccountTitle", this.state.AccountTitle);
		formData.append("RoutingNo", this.state.RoutingNo);
		formData.append("AccountNo", this.state.AccountNo);
		formData.append("Quantity", this.state.Quantity);
		formData.append("BackgroundColor", this.state.BackgroundColor);
		formData.append("selecTedtemplate", this.state.selecTedtemplate);
		formData.append("isactive", this.state.i_agree);
		formData.append("state", this.state.state)
		formData.append("city", this.state.city)
		formData.append("zipcode", this.state.zipcode)
		formData.append("lat", this.state.lat)
		formData.append("long", this.state.long)
		formData.append("headerImage", this.state.file)
		formData.append('isHijriCalendar', this.state.isHijriCalendar)
		formData.append('isRamadanCalendar', this.state.isRamadanCalendar)
		formData.append('isNoIqamaCalendar', this.state.isNoIqamaCalendar)

		if (!this.state.file || !this.state.selecTedtemplate || !this.state.Quantity || !this.state.BackgroundColor) {
			if (!this.state.selecTedtemplate) {
				alert("Please select a template")
			} else if (!this.state.Quantity) {
				alert("Please select the quanity")
			} else if (!this.state.BackgroundColor) {
				alert("Please select the calendar background color")
			} else {
				alert("Please upload header image")
			}
		} else {
			const token = JSON.parse(localStorage.getItem('token'));
			this.setState({ isLoading: true }, () => {
				if ((!this.state.BankName || !this.state.AccountNo || !this.state.AccountTitle || !this.state.RoutingNo) && !(!(this.state.BankName || this.state.AccountNo || this.state.AccountTitle || this.state.RoutingNo))) {
					alert("All 4 fields of the Bank Details are mandatory")
					this.setState({ isLoading: false })
				} else {
					axios({
						method: 'post',
						headers: {
							'Accept': 'application/json, text/plain, */*',
							'Content-Type': 'application/json',
							Authorization: 'Bearer ' + token //the token is a variable which holds the token
						},
						url: CONFIG.API_END_POINT + 'api/v1/admin/MosqueApproveByAdmin',
						data: formData
						// params:{truckSizeId:"10002"}        
					}).then((response) => {
						this.setState({ isLoading: false }, () => {
							setTimeout(() => {
								if (response.data.error === 'This Mosque user already exists with this email' || response.data.error) {
									if (response.data.error === 'This Mosque user already exists with this email') {
										message.error("Masjid could not be updated because the email already exists")
									} else {
										message.error("Details could not be modified")
									}
								} else {
									if (!this.state.i_agree) {
										alert("Masjid has been updated")
										this.props.history.push('/PendingMosquePagination')
									} else {
										alert("Masjid has been updated")
										this.props.history.push('/ApprovedMosquePagination')
									}
								}
							}, 100)
						})
					})
				}
			})
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		const token = JSON.parse(localStorage.getItem('token'));
		this.setState({ isLoading: true }, () => {
			axios({
				method: 'get',
				url: CONFIG.API_END_POINT + 'api/v1/admin/MosqueDetails',
				// data: obj
				headers: {
					Authorization: 'Bearer ' + token //the token is a variable which holds the token
				},
				params: { id: this.props.editedId || this.props.location.state.id }
			}).then((response) => {
				const data = response.data.success;
				const mosque = data.MosqueDetails[0]

				this.setState({
					oldAddress: mosque.Address,
					oldSchool: mosque.schoolOfPrayerTimes,
					oldJuristic: mosque.juristicForPrayerTimes
				});

				this.setState({

					id: mosque.id,
					MosqueName: mosque.MosqueName,
					schoolOfPrayerTimes: mosque.schoolOfPrayerTimes,
					juristicForPrayerTimes: mosque.juristicForPrayerTimes,
					MosqueCapacity: mosque.MosqueCapacity,
					AverageJumaAttendees: mosque.AverageJumaAttendees,
					googleAddress: mosque.Address,
					FirstName: mosque.FirstName,
					LastName: mosque.LastName,
					phoneNumber: mosque.phoneNumber,
					email: mosque.email,
					city: mosque.city,
					state: mosque.state,
					zipcode: mosque.zipcode,
					image: mosque.image,
					imagePath: mosque.imagePaths,
					pass: mosque.pass,


					print: data?.print,
					Quantity: data?.print?.Quantity,
					BackgroundColor: data?.print?.BackgroundColor,
					selecTedtemplate: data?.print?.selecTedtemplate,
					selecTedtemplateFile: this.getSelectedTemplateFile(data?.print?.selecTedtemplate),
					showBorder: true,
					isHijriCalendar: data?.print?.isHijriCalendar || 'no',
					isRamadanCalendar: data?.print?.isRamadanCalendar || 'no',
					isNoIqamaCalendar: data?.print?.isNoIqamaCalendar || 'no',
					backendTemplate: data?.print?.selecTedtemplate,

					bank: data?.bankDatails,
					BankName: data?.bankDatails?.BankName,
					AccountTitle: data?.bankDatails?.AccountTitle,
					RoutingNo: data?.bankDatails?.RoutingNo,
					AccountNo: data?.bankDatails?.AccountNo,

					commision: data?.commision,
					commisionType: data?.commision?.commisionType,
					commisionValue: data?.commision?.commisionValue,
					priceFactor: data?.commision?.priceFactor,

					lat: data?.locations?.loc?.coordinates[1],
					long: data?.locations?.loc?.coordinates[0],

					mapCenter: { lat: data?.locations?.loc?.coordinates[1], lng: data?.locations?.loc?.coordinates[0] },

					isLoading: false,

				}, async () => {
					this.sendLatlong();
					if (this.state.imagePath) {
						let file = await fetch(this.state.imagePath)
							.then(r => r.blob())
							.then(blobFile => new File([blobFile], "jpg", { type: "image/jpg" }))
						this.setState({
							file: file,
						})
					}
				});


				if (this.state.isHijriCalendar === 'yes') {
					this.setTemplate('Template3');
					for (let i = 0; i < 8; i++) {
						document.getElementById(`temp-wrap${i + 1}`).style.cursor = 'not-allowed';
						document.getElementById(`test${i + 1}`).style.pointerEvents = 'none';
					}
				}

				if (this.state.isRamadanCalendar === 'yes') {
					this.setTemplate('RTemplate1');
					for (let i = 0; i < 1; i++) {
						document.getElementById(`rtemp-wrap${i + 1}`).style.cursor = 'not-allowed';
						document.getElementById(`rtest${i + 1}`).style.pointerEvents = 'none';
					}
				}

				//Prayertimes section
				this.setState({ isLoading: true, nodes: [this.addMoreSet(1)] }, () => {
					this.getPrayertimes()
				})

			}).catch(error => {
				console.log(error.response);
			})
		})
	}

	getSelectedTemplateFile = template => {
		switch (template) {
			case 'Template1':
				return temp1;
			case 'Template2':
				return temp4;
			case 'Template3':
				return temp2;
			case 'Template4':
				return temp3;
			case 'Template5':
				return temp7;
			case 'Template6':
				return temp6;
			case 'Template7':
				return temp8;
			case 'Template8':
				return temp5;
			case 'RTemplate1':
				return temp2;
			default:
				return null;
		}
	};

	render() {
		return (
			<>
				<div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "50%" }}>
					{this.state?.isLoading &&
						<LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
					}
				</div>
				{/* {
					this.state?.isLoading &&
					<img alt="" src={LoaderGif} style={{ top: "40%", position: "absolute", left: "33%", height: "200px", width: "500px", zIndex: "1000", }} /> // background: 'rgb(255,255,255,0.6)'
				} */}
				<div >
					<Headerad />
				</div>
				<div >
					<Adminheader />
				</div>
				<div className="bodyy">
					<div className="container">
						<form onSubmit={this.handleLogin}>
							<div className="newmosque-cont main-card">
								<div>
									<h5 className="card-header d-flex justify-content-between align-items-center">Edit Mosque</h5>
								</div>
								<div class="form-conteniar" style={{ marginTop: "20px", padding: "13px" }}>

									<div >
										<fieldset className="fieldset">
											<legend className="legend">Mosque details</legend>
											<Row>
												<Col md={6}>
													<Row>
														<Col >
															<label className="label">Mosque Name</label>
															<input type="text" class="input name" placeholder={this.state.MosqueName} value={this.state.MosqueName} onChange={this.mosquenamehandler} pattern="^[a-zA-Z-][a-zA-Z -]*$" title="Mosque Name cannot be more than 20 characters " required />
														</Col>
													</Row> <br />
													<Row>
														<Col > <label className="label">Phone No.</label><input type="text" class="input name" placeholder={this.state.phoneNumber} value={this.state.phoneNumber} onChange={this.phonenumberhandler} pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}?" title="Please enter a valid phone number" required onKeyUp={this.Checkphoneno} /><span id="phonenumbermessage"></span></Col>
														<Col >  <label className="label">Email</label><input disabled type="email" class="input name" placeholder={this.state.email} value={this.state.email} onChange={this.emailhandler} required onKeyUp={this.Checkemail} /><span id="emailmessage"></span></Col>
													</Row><br />
													<Row>
														<Col md={10}> <label className="label">Address Line</label>

															<PlacesAutocomplete
																disabled
																className="name"
																value={this.state.googleAddress}
																onChange={this.handlePlaces}
																onSelect={this.handleSelect}
																required
															// className="map-search1"
															>
																{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
																	<div>
																		<input
																			disabled
																			className="custom-input"
																			{...getInputProps({
																				// placeholder: 'Enter an Address, City, or Zipcode',
																				// disabled: 'true'
																			})}
																			placeholder={this.state.googleAddress}
																			style={{ borderStyle: "solid", borderColor: "#cccccc", width: "100%", height: "30px", borderWidth: "1px" }}
																		/>
																		<div className="autocomplete-dropdown-container" style={{ borderColor: "#e1e1e1 ", marginLeft: "0px", width: "auto", fontSize: "18px", fontWeight: "lighter", }}>
																			{loading && <div>Loading...</div>}
																			{suggestions.map(suggestion => {
																				const className = suggestion.active
																					? 'suggestion-item--active'
																					: 'suggestion-item';
																				// inline style for demonstration purpose
																				const style = suggestion.active
																					? { backgroundColor: '#fafafa', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px" }
																					: { backgroundColor: '#ffffff', cursor: 'pointer', borderColor: " solid #e1e1e1", borderRadius: "0px" };
																				return (
																					<div
																						{...getSuggestionItemProps(suggestion, {
																							className,
																							style,
																						})}
																					>
																						<span>{suggestion.description}</span>
																					</div>
																				);
																			})}
																		</div>
																	</div>
																)}
															</PlacesAutocomplete>
														</Col>
														<Col md={2}>

															<button
																onClick={this.handleSearch}
																style={{ marginTop: "25px", padding: "4px 5px 5px 5px" }} className="btn-secondary btn">Search</button>
														</Col>
													</Row>
													<br />
													<Row>
														<Col>
															<label className="label">State</label>
															<input type="text" class="input name" placeholder={this.state.state} value={this.state.state} onChange={this.statehandler} title="Please enter a valid address" required />

														</Col>
													</Row><br />
													<Row>
														<Col> <label className="label">City</label>  <input type="text" class="input name" placeholder={this.state.city} value={this.state.city} onChange={this.cityhandler} title="Please enter a valid city" required /></Col>
														<Col> <label className="label">Zip code</label>  <input maxLength="5" type="text" class="input name" placeholder={this.state.zipcode} value={this.state.zipcode} onChange={this.zipcodehandler} pattern="[0-9]{5}$" title="Please enter a valid zipcode" required /></Col>
													</Row><br />
												</Col>
												<Col md={6}>
													<Row>
														<Col >
															<label className="label">First Name</label>
															<input type="text" class="input name" placeholder={this.state.FirstName} value={this.state.FirstName} onChange={this.firstnamehandler} title="Please enter a valid first name" required />
														</Col>
														<Col >
															<label className="label">Last Name</label>
															<input type="text" class="input name" placeholder={this.state.LastName} value={this.state.LastName} onChange={this.lastnamehandler} title="Please enter a valid last name" required />
														</Col>
													</Row>
													<Row style={{ marginTop: "1.25rem" }}>
														<Col >
															<label className="label">Password</label>
															<div className="input-pass">
																<Input.Password maxLength="0" placeholder={this.state.pass} value={this.state.pass} />
															</div>
														</Col>
													</Row><br /><br />
													<Row>
														<Col>
															<div>
																<DefaultMap coords={{ lat: this.state.lat, lng: this.state.long }} center={this.state.mapCenter} address={this.state.googleFormattedAddress} setMarkerAddress={this.updateAddressByMarker} zoom={15} height={270} />
															</div>
														</Col>
													</Row>
												</Col>
											</Row>
											<Row>
												<Row style={{ marginLeft: "3px", marginRight: "3px" }}>
													<Col >
														<label className="label">School</label>
														<select style={{ height: "30px", borderRadius: "2px", padding: "5px 0px 5px 0px", fontFamily: "Arial", fontWeight: "lighter", paddingTop: "5px", paddingBottom: "5px" }} class="name" defaultValue="" value={this.state.schoolOfPrayerTimes} onChange={this.selectprayertimeshandler} required>
															{
																!this.state.schoolOfPrayerTimes ?
																	<option value="Select Juristic for Prayer Times" style={{ display: "none" }}>Select School of Prayer Times</option>
																	:
																	<option hiddenValue="" style={{ display: "none" }}>{this.state.schoolOfPrayerTimes}</option>
															}
															{/* <option hiddenValue="" style={{ display: "none" }}>{this.state.schoolOfPrayerTimes}</option> */}
															<option >Hanafi</option>
															<option >Shafi'i</option>
															<option >Maliki</option>
															<option >Hanbali</option>
														</select>
													</Col>
													<Col>
														<label className="label">Juristic</label>
														<select style={{ height: "30px", borderRadius: "2px", padding: "5px 0px 5px 0px", fontFamily: "Arial", fontWeight: "lighter", paddingTop: "5px", paddingBottom: "5px" }} class="name" defaultValue="" value={this.state.juristicForPrayerTimes} onChange={this.selectjuristicprayertimeshandler} required>
															{
																!this.state.juristicForPrayerTimes ?
																	<option value="Select Juristic for Prayer Times" style={{ display: "none" }}>Select Juristic for Prayer Times</option>
																	:
																	<option hiddenValue="" style={{ display: "none" }}>{this.state.juristicForPrayerTimes}</option>
															}
															{/* <option hiddenValue="" style={{ display: "none" }}>{this.state.juristicForPrayerTimes}</option> */}
															<option value="Umm Al-Qura University makkah">Umm Al-Qura University makkah</option>
															<option value="Islamic society of North America">Islamic society of North America</option>
															<option value="Diyanet Isleri Baskanligi, Turkey">Diyanet Isleri Baskanligi, Turkey</option>
															<option value="Egyptian General authority of Survey">Egyptian General authority of Survey</option>
															<option value="Gulf Region">Gulf Region</option>
															<option value="Institute of Geophysics , University of Tehram">Institute of Geophysics , University of Tehram</option>
															<option value="Muslim World League">Muslim World League</option>
															<option value="Majlis Ugama Islam Singapura, Singapore">Majlis Ugama Islam Singapura, Singapore</option>
															<option value="Qatar">Qatar</option>
															{/* <option value="Spiritual Administration of Muslims of Russia">Spiritual Administration of Muslims of Russia</option> */}
															<option value="Shia Ithna-Ansari">Shia-Ithna-Asheri</option>
															<option value="university of Islamic Science Karachi">University of Islamic Science Karachi</option>
															{/* <option value="Union Organisation Islamic de France">Union Organisation Islamic de France</option> */}
														</select>
													</Col>
													<Col >
														<label className="label">Mosque Capacity</label>
														<input type="text" class="input name" placeholder={this.state.MosqueCapacity} value={this.state.MosqueCapacity} onChange={this.mosquecapacityhandler} title="Mosque Capacity cannot be a character and more than 3 digit " required />
													</Col>
													<Col > <label className="label">Average jumma attendees</label>  <input type="text" class="input name" placeholder={this.state.AverageJumaAttendees} value={this.state.AverageJumaAttendees} onChange={this.averagejumaattendeeshandler} title="Average jumma attendees cannot be a character and more than 3 digits" required />
													</Col>
												</Row>
											</Row>
										</fieldset>
									</div>
									<fieldset className="fieldset">
										<legend className="legend">Commision Configurations</legend>
										<Row>
											<Col md={4}>
												<label className="label">Commision Type</label>
												<select style={{ height: "30px", borderRadius: "2px", padding: "5px 0px 5px 0px", fontFamily: "Arial", fontWeight: "lighter", paddingTop: "5px", paddingBottom: "5px" }} class="name" value={this.state.commisionType} onChange={this.commissiontypehandler} >
													<option >Is Percentage</option>
													<option >Is Amount</option>
												</select>
											</Col>
											<Col md={8}>
												{
													this.state.commisionType === 'Is Percentage' ?
														<div>
															<Row>
																<Col md={6}>
																	<label className="label">Commision Value</label>
																	<input type="text" className="input name" value={this.state.commisionValue} onChange={this.commissionvaluehandler} title="Please enter a valid commision value" disabled="true" style={{ width: "100%" }} />

																</Col>
																<Col md={6}>
																	<label className="label">Commission Percentage</label>
																	<input type="text" className="input name" value={this.state.priceFactor} onChange={this.pricefactorhandler} title="Please enter a valid price factor" style={{ width: "105%" }} />
																</Col>
															</Row>
														</div>
														:
														<div>
															<Row>
																<Col md={6}>
																	<label className="label">Commision Value</label>
																	<input type="text" className="input name" value={this.state.commisionValue} onChange={this.commissionvaluehandler} title="Please enter a valid commision value" style={{ width: "105%" }} />

																</Col>
																<Col md={6}>
																	<label className="label">Commission Percentage</label>
																	<input type="text" className="input name" value={this.state.priceFactor} onChange={this.pricefactorhandler} title="Please enter a valid price factor" disabled="true" style={{ width: "100%" }} />
																</Col>
															</Row>
														</div>
												}
											</Col>
											{/* <Col md={4}>
											<label className="label">Commision Value</label>
											<input type="text" class="input name" value={this.state.commisionValue} onChange={this.commissionvaluehandler} title="Please enter a valid commision value" required />
										</Col>
										<Col md={4}>
											<label className="label">Price Factor</label>
											<input type="text" class="input name" value={this.state.priceFactor} onChange={this.pricefactorhandler} title="Please enter a valid price factor" required />
										</Col> */}
										</Row>
									</fieldset>
									<fieldset className="fieldset">
										<legend className="legend">Bank Details</legend>
										<Row>
											<Col md={6}>
												<label className="label">Bank Name and address</label>
												<input type="text" class="input name" value={this.state.BankName} onChange={this.banknamehandler} title="Please enter a valid bank name" />
											</Col>
											<Col md={6}>
												<label className="label" >Account Name</label>
												<input type="text" class="input name" value={this.state.AccountTitle} onChange={this.accounttitlehandler} title="Please enter a valid account name" />
											</Col>
										</Row><br />
										<Row>
											<Col md={6}> <label className="label">Routing Number</label><input type="text" class="input name" value={this.state.RoutingNo} onChange={this.routingnumberhandler} pattern="^[0-9]+$" title="Please enter a valid routing number" /></Col>
											<Col md={6}>
												<label className="label">Account Number</label>
												<input type="text" class="input name" value={this.state.AccountNo} onChange={this.accountnumberhandler} pattern="^[0-9]+$" title="Please enter a valid account number" />
											</Col>
										</Row>
									</fieldset>
									<fieldset className="fieldset">
										<legend className="legend">Print Settings</legend>
										<Row>
											<Col md={4}>
												<label className="label">Quantity</label>
												<select style={{ height: "30px", borderRadius: "2px", padding: "5px 0px 5px 0px", fontFamily: "Arial", fontWeight: "lighter", paddingTop: "5px", paddingBottom: "5px" }} class="name" value={this.state.Quantity} onChange={this.quantityhandler} required>
													<option value="100" style={{ display: "none" }}>100</option>
													<option value="100">100</option>
													<option value="150">150</option>
													<option value="200">200</option>
													<option value="250">250</option>
													<option value="300">300</option>
													<option value="350">350</option>
													<option value="400">400</option>
													<option value="450">450</option>
													<option value="500">500</option>
													<option value="550">550</option>
													<option value="600">600</option>
													<option value="650">650</option>
													<option value="700">700</option>
													<option value="750">750</option>
													<option value="800">800</option>
													<option value="850">850</option>
													<option value="900">900</option>
													<option value="950">950</option>
													<option value="1000">1000</option>
												</select>
											</Col>
										</Row><br />
										<Row>
											<Col md={4}>
												<label className="label">Select Calendar Background Color</label><br />
												<input onClick={this.selectColor.bind(true)} type="color" placeholder={this.state.BackgroundColor} value={this.state.BackgroundColor} onChange={this.selectprintbackgroundhandler} style={{ height: "30px", paddingBottom: "5px", paddingTop: "4px", width: "100%" }} required />

											</Col>
											{
												this.state.showSelectBtn && (
													<Col md={2}>
														<Button onClick={_ => this.selectColor(false)} className="btn btn-secondary" style={{ marginTop: "27px" }} >Select</Button>
													</Col>
												)
											}
										</Row><br />
										<Row>
											<Col md={4}>
												<label className="label">Ramadan Calendar</label><br />
												<div>
													<input name="ramadanCheck" checked={this.state.isRamadanCalendar === 'yes' ? true : false} onClick={this.checkRamadan} type="checkbox" value="yes" style={{ marginRight: "5px" }} /><label style={{ fontSize: "15px", marginRight: "10px" }}>Yes</label> <input name="ramadanCheck" checked={this.state.isRamadanCalendar ? this.state.isRamadanCalendar === 'no' ? true : false : true} onClick={this.checkRamadan} style={{ marginRight: "5px" }} type="checkbox" value="no" /><label style={{ fontSize: "15px" }}>No</label>
												</div>
											</Col>
										</Row><br />
										{
                        this.state.isRamadanCalendar === 'no' &&
                        <div>
                          <Row>
													<Col md={4}>
                          <label className="label">Calendar without Iqama</label><br />
                          <div>
                            <input name="noIqamaCheck" onClick={this.checkNoIqama} type="checkbox" value="yes" checked={this.state.isNoIqamaCalendar === 'yes'} style={{ marginRight: "5px" }} /><label style={{ fontSize: "15px", marginRight: "10px" }}>Yes</label> <input name="noIqamaCheck" defaultChecked onClick={this.checkNoIqama} style={{ marginRight: "5px" }} type="checkbox" value="no" checked={this.state.isNoIqamaCalendar === 'no'} /><label style={{ fontSize: "15px" }}>No</label>
                          </div>
                        </Col>
                      </Row><br />
                      <Row>
                        <Col md={4}>
                          <label className="label">Calendar with Hijri Dates</label><br />
                          <div>
                            <input name="hijriCheck" onClick={this.checkHijri} type="checkbox" value="yes" checked={this.state.isNoIqamaCalendar === 'yes' ? true : this.convertStringToBoolean(this.state.isHijriCalendar)} disabled={this.state.isNoIqamaCalendar === 'yes' ? true : false} style={{ marginRight: "5px" }} /><label style={{ fontSize: "15px", marginRight: "10px" }}>Yes</label> <input name="hijriCheck" defaultChecked checked={this.state.isNoIqamaCalendar === 'yes' ? false : !this.convertStringToBoolean(this.state.isHijriCalendar)}  disabled={this.state.isNoIqamaCalendar === 'yes' ? true : false} onClick={this.checkHijri} style={{ marginRight: "5px" }} type="checkbox" value="no" /><label style={{ fontSize: "15px" }}>No</label>
                          </div>
                        </Col>
                      </Row><br />
                        </div> 
                      }
										<Row>
											<Col md={7}>
												<label className="label">Choose a template <span style={{ color: "red" }}>*</span></label>
												{/* <Row>
													<Col>
														<label className="template-image-wrapper-mosque" >
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template1' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template1' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template1')} src={temp1} />

														</label>
														<label className="template-image-wrapper-mosque">
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template2' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template2' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template2')} src={temp4} />
														</label>


														<label className="template-image-wrapper-mosque" >
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template3' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template3' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template3')} src={temp2} />
														</label>
														<label className="template-image-wrapper-mosque">
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template4' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template4' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template4')} src={temp3} />
														</label>


														<label className="template-image-wrapper-mosque">
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template5' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template5' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template5')} src={temp7} />
														</label>
														<label className="template-image-wrapper-mosque">
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template6' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template6' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template6')} src={temp6} />
														</label>
														<label className="template-image-wrapper-mosque">
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template7' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template7' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template7')} src={temp8} />
														</label>
														<label className="template-image-wrapper-mosque">
															<input type="radio" name="test" value="small" checked />
															<img alt="template" className="template-images-mosque" style={{ border: this.state.selecTedtemplate === 'Template8' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template8' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template8')} src={temp5} />
														</label>
													</Col>
												</Row> */}

												{
													this.state.isRamadanCalendar === 'no' ?
														<Row>
															<Col>
																<label id="temp-wrap1" className="template-image-wrapper-mosque" >
																	<input type="radio" name="test" value="small" checked />
																	<img id="test1" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template1' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template1' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template1')} src={temp1} />

																</label>
																<label id="temp-wrap2" className="template-image-wrapper-mosque">
																	<input type="radio" name="test" value="small" checked />
																	<img id="test2" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template2' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template2' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template2')} src={temp4} />
																</label>


																<label id="temp-wrap3" className="template-image-wrapper-mosque" >
																	<input type="radio" name="test" value="small" checked />
																	<img id="test3" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template3' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template3' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template3')} src={temp2} />
																</label>
																<label id="temp-wrap4" className="template-image-wrapper-mosque">
																	<input type="radio" name="test" value="small" checked />
																	<img id="test4" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template4' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template4' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template4')} src={temp3} />
																</label>


																<label id="temp-wrap5" className="template-image-wrapper-mosque">
																	<input type="radio" name="test" value="small" checked />
																	<img id="test5" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template5' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template5' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template5')} src={temp7} />
																</label>
																<label id="temp-wrap6" className="template-image-wrapper-mosque">
																	<input type="radio" name="test" value="small" checked />
																	<img id="test6" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template6' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template6' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template6')} src={temp6} />
																</label>
																<label id="temp-wrap7" className="template-image-wrapper-mosque">
																	<input type="radio" name="test" value="small" checked />
																	<img id="test7" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template7' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template7' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template7')} src={temp8} />
																</label>
																<label id="temp-wrap8" className="template-image-wrapper-mosque">
																	<input type="radio" name="test" value="small" checked />
																	<img id="test8" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'Template8' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'Template8' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('Template8')} src={temp5} />
																</label>
															</Col>
														</Row>
														:
														<Row>
															<Col>
																<label id="rtemp-wrap1" className="template-image-wrapper-mosque" >
																	<input type="radio" name="test" value="small" checked />
																	<img id="rtest1" alt="template" className="template-images-mosque" style={{ cursor: 'pointer', border: this.state.selecTedtemplate === 'RTemplate1' ? '3px solid white' : '', boxShadow: this.state.selecTedtemplate === 'RTemplate1' ? '0 1px 10px rgb(48 48 48 / 50%)' : '' }} onClick={() => this.setTemplate('RTemplate1')} src={temp2} />
																</label>
															</Col>
														</Row>
												}





												{/* <Row>
												<Col>
													<label>
														<input type="radio" name="test" value="small" checked />
														<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template5' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template5')} src={temp7} />
													</label>
													<label>
														<input type="radio" name="test" value="small" checked />
														<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template6' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template6')} src={temp6} />
													</label>
													<label>
														<input type="radio" name="test" value="small" checked />
														<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template7' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template7')} src={temp8} />
													</label>
													<label>
														<input type="radio" name="test" value="small" checked />
														<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template8' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template8')} src={temp5} />
													</label>
												</Col>
											</Row> */}
											</Col>
											<Col md={5} >
												<Label style={{ color: "#374767", fontSize: "16px", }}>Template Preview</Label>
												<Row>
													<Col style={{ paddingTop: "8px" }}>
														{
															this.state.showBorder && (
																<img alt="" src={this.state.selecTedtemplateFile} className="mosque-temp-width" style={{ padding: '5px', background: `${this.state.BackgroundColor}`, borderStyle: "solid 5px", }} />
															)
														}
													</Col>
												</Row>
											</Col>
										</Row>
										{/* <Row>
										<Col>

											<Row>
												<Row>
													<Col style={{ marginLeft: "18px" }}><label className="label">Choose a template <span style={{ color: "red" }}>*</span></label></Col>
													<Col md={12} style={{ marginTop: "10px" }}>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template1' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template1')} src={temp1} />
														</label>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template2' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template2')} src={temp4} />
														</label>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template3' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template3')} src={temp2} />
														</label>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template4' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template4')} src={temp3} />
														</label>
													</Col>
												</Row>
											</Row>
											<Row>
												<Row>
													<Col md={12} style={{ marginTop: "10px" }}>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template5' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template5')} src={temp7} />
														</label>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template6' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template6')} src={temp6} />
														</label>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template7' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template7')} src={temp8} />
														</label>
														<label style={{ marginLeft: '1rem' }}>
															<input type="radio" name="test" value="small" checked />
															<img alt="template" style={{ height: "200px", width: "160px", border: this.state.selecTedtemplate === 'Template8' ? '2px solid black' : '' }} onClick={() => this.setTemplate('Template8')} src={temp5} />
														</label>
													</Col>
												</Row>
											</Row>
										</Col>
										<Col md={2} style={{ marginRight: "200px" }}>
											<Label style={{ color: "#374767", fontSize: "16px", }}>Template Preview</Label>

											<div style={{ background: `${this.state.BackgroundColor}`, width: "340px" }}>
												{
													this.state.showBorder && (
														<img alt="template" src={this.state.selecTedtemplateFile} style={{ width: "350px", padding: '5px', background: `${this.state.BackgroundColor}`, borderStyle: "solid 5px", }} />
													)
												}
											</div>
										</Col>
									</Row> */}
										<div class="input-name">
											<h3>Header Image</h3>
											<p style={{ fontSize: "14px", color: "#374767", fontWeight: "light" }}>Header Image size should be width(5.25"), height(1"), Image resolution quality (Minimum 300 dpi).</p>
											<div className="header-image-wrapper">
												<img className="calendar-header" alt="" style={{ width: "370px", height: "60px" }} src={this.state.imagePath} required /><br />
											</div>
											<label for="file-upload" class="custom-file-upload" style={{ border: "1px solid #ccc", display: "inline-block", backgroundColor: "white", padding: "3px 3px", marginTop: "5px", fontWeight: "lighter", color: "black", fontSize: "15px" }}>
												Upload header Image</label>
											<input id="file-upload" type="file" onChange={this.onFileChange} style={{ display: "none" }} />
										</div>
									</fieldset>
									{/* <Row style={{ marginTop: "20px", backgroundColor: "#EEF2F6 " }}>
									<Col>
										<div class="input-name">
											<label style={{ color: "#374767", fontWeight: "lighter", fontFamily: "Arial", fontSize: "13px" }}>
												<input
													type="checkbox"
													defaultChecked={this.state.i_agree}
													onChange={this.handleChange}

												/> Is Active
											</label>
											<br />
										</div>
									</Col>
									<Col >
										<div class="input-name">
											<input style={{ color: "white", backgroundColor: "#558B2F", borderColor: "#3C6F18", width: "76px", float: "right", fontSize: "14px", fontFamily: "Arial", borderRadius: "2px" }} class="button" type="submit" value="Submit" />
										</div>
									</Col>
								</Row> */}

								</div>


								<div style={{ backgroundColor: "#f7f9fa", height: "57px", borderTop: "1px solid #e1e6ef", padding: "10px" }}>

									<label style={{ color: "#374767", fontWeight: "lighter", fontFamily: "Arial", fontSize: "13px", marginTop: "10px" }}>
										<input
											type="checkbox"
											defaultChecked={this.state.i_agree}
											onChange={this.handleChange}

										/> Is Active
									</label>
									<button className="buttonn btn-primary btn" type="submit" style={{ float: "right", }}>Submit</button>


								</div>
							</div>
						</form>
					</div>
				</div>


				{/* Prayertimes section starts here */}
				<div className="bodyy">
					<div className="container">
						<div className="prayer-cont main-card">

							{/* <div style={{ width: "103%", backgroundColor: "white", border: "solid 1px white" }}>
          <h5 style={{ color: "#374767", padding: "10px", borderBottom: "solid 1px #e4e5e7", fontSize: "18px", fontFamily: "WebFonts", fontWeight: "medium" }}>Adjust Prayer Timings</h5>
        </div> */}
							<div>
								<h5 className="card-header d-flex justify-content-between align-items-center">Adjust Prayer Times</h5>
							</div>
							<div style={{ backgroundColor: "#f1f3f6", padding: "10px" }}>
								<Row>
									<Col md={3}>
										<label style={{ color: "#374767", fontWeight: "700", marginBottom: "5px" }} for="month">Month</label>
										<select value={this.state.month} defaultValue="" onChange={this.monthhandler} onClick={this.getPrayertimes}>
											<option hiddenValue="" style={{ display: "none" }}>{this.returnMonth(new Date().getMonth() + 1)}</option>
											<option value="1">January</option>
											<option value="2">February</option>
											<option value="3">March</option>
											<option value="4">April</option>
											<option value="5">May</option>
											<option value="6">June</option>
											<option value="7">July</option>
											<option value="8">August</option>
											<option value="9">September</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</select>
									</Col>
									<Col md={3}>
										<label style={{ color: "#374767", fontWeight: "700", marginBottom: "5px" }} for="year">Year</label>

										<select value={this.state.year} onChange={this.yearhandler} onClick={this.getPrayertimes}>
											{/* <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option> */}
											<option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
											<option value={new Date().getFullYear() + 1} >{new Date().getFullYear() + 1}</option>
											<option value={new Date().getFullYear() + 2} >{new Date().getFullYear() + 2}</option>
										</select>
									</Col>
									{/* <Col md={4}>
                      <label style={{ color: "#374767", fontWeight: "700", marginBottom: "5px" }} for="button"></label>
                      <button  type="submit" style={{ borderRadius: "2px", border: "solid 1px #3C6F18", width: "100%", padding: "2px", marginTop: "26px", backgroundColor: "#558B2F", color: "white", padding: "6px 12px", height: "42px" }}>Show Calendar </button>
                    </Col> */}
								</Row><br />
								<h5 style={{ color: "#374767", padding: "10px", borderBottom: "solid 1px #e4e5e7", fontSize: "16px", marginBottom: "0px", paddingLeft: "0px" }}>Adjust Iqama Time</h5>





								{
									this.state.repeatedSet === true ?
										<Row>
											<Col md={12}>
												{
													this.state.nodes.map(
														item => item
													)
												}

												{/* <Row>
                      <Col><span style={{ float: "left", color: "#D21A1A", fontWeight: "bold", fontSize: "0.8vw" }}>Click on the button below to add more Iqama time fields<i class="fa fa-hand-point-down" style={{ marginLeft: '2px', height: "20px", width: "20px", fontSize: "0.8vw" }}></i></span></Col>
                    </Row>
                    <button style={{ padding: "6px 12px", border: "solid 1px #ccc", backgroundColor: "white", color: "#333", float: "left", }} type="submit" onClick={this.increseCounter}>Add more dates</button> */}
											</Col>
										</Row>
										:
										null
								}


								<Row >
									<Col className="text-center mb-3" >
										<Row>
											<Col><span style={{ float: "left", color: "#D21A1A", fontWeight: "bold", fontSize: "0.8vw" }}></span></Col>
										</Row>
										<button style={{ padding: "6px 12px", border: "solid 1px #ccc", backgroundColor: "white", color: "#333" }} type="submit" onClick={this.increseCounter}><i class="fas fa-plus mr-2"></i>Add Iqama Time Fields</button>
									</Col>
								</Row>



								<Row style={{ marginTop: '10px', }}>
									{/* <Col md={8} >
                  </Col> */}
									<Col md={12} style={{ textAlign: "right" }}>
										{/* Reset  */}
										{/* <img alt="" src={resettimings} width="60px" height="60px" /> */}
										<button onClick={this.applyChanges} className="btn-primary btn mr-2" type="submit">Save</button>
										<button className="mr-2 btn-secondary btn" onClick={this.resetTimings} type="submit">Reset</button>
									</Col>
								</Row>


								{/* <div style={{position: "absolute", top: "75%", left: "14.3%"}}>
                  <div>
                  <span style={{ color: "#D21A1A", fontWeight: "bold", fontSize: "0.8vw" }}>Click on the button below to add more Iqama time fields<i class="fa fa-hand-point-down" style={{ marginLeft: '2px', height: "20px", width: "20px", fontSize: "0.8vw" }}></i></span>
                  </div>
                  <div>
                  <button style={{ padding: "6px 12px", border: "solid 1px #ccc", backgroundColor: "white", color: "#333", }} type="submit" onClick={this.increseCounter}>Add more dates</button>
                  </div>
                </div> */}
							</div>
							<div className="col-xs-12">
								<div className="prayertime-table" >
									<table className="prayertimes-table" style={{ minWidth: "1000px" }}>
										<tr style={{ fontSize: "16px", display: "table", tableLayout: "fixed", margin: "10px 0px 10px 0px", width: "100%", textAlign: "center" }}>
											<th style={{ border: "none", color: "black" }}>
												Date
											</th>
											<th style={{ border: "none", color: "black" }}>
												Day
											</th>
											<th style={{ border: "none", color: "black" }}>
												Fajr<span class="thIqama" style={{ color: "rgb(210, 26, 26)" }}><span>&nbsp;/&nbsp;</span>Iqama</span>
											</th>
											<th style={{ border: "none", color: "black" }}>
												Sunrise
											</th>
											<th style={{ border: "none", color: "black" }}>
												Dhuhr<span class="thIqama" style={{ color: "rgb(210, 26, 26)" }}><span>&nbsp;/&nbsp;</span>Iqama</span>
											</th>
											<th style={{ border: "none", color: "black" }}>
												Asr<span class="thIqama" style={{ color: "rgb(210, 26, 26)" }}><span>&nbsp;/&nbsp;</span>Iqama</span>
											</th>
											<th style={{ border: "none", color: "black" }}>
												Maghrib<span class="thIqama" style={{ color: "rgb(210, 26, 26)" }}><span>&nbsp;/&nbsp;</span>Iqama</span>
											</th>
											<th style={{ border: "none", color: "black" }}>
												Isha<span class="thIqama" style={{ color: "rgb(210, 26, 26)" }}><span>&nbsp;/&nbsp;</span>Iqama</span>
											</th>
										</tr>
										<tbody style={{ overflow: "auto", maxHeight: '500px', display: "block" }}>
											{
												this.state.prayerTimes?.map(
													(time, idx) => (
														<tr style={{ color: "black", borderTop: "1px solid #ddd", display: "table", tableLayout: "fixed", width: "100%", textAlign: "center" }}>

															<td style={{ border: "none", fontSize: "14px", }}>
																<div style={{ padding: "6px 12px", fontWeight: '500', fontFamily: "Arial", }}>
																	{time.Date}
																</div>
															</td>

															<td style={{ border: "none", fontSize: "14px", }}>
																<div style={{ padding: "6px 12px", fontWeight: '500', fontFamily: "Arial" }}>
																	{time.Day}
																</div>
															</td>

															<td style={{ border: "none", fontSize: "14px" }}>
																{/* <div class="input-group">
                                  <span class="input-group-addon"> */}
																<div style={{ backgroundColor: "#eee", padding: "6px 12px", fontWeight: '500', fontFamily: "Arial", }}>
																	<span>
																		<span style={{ color: "black" }}>{time.fajrTime}</span>
																		<span style={{ fontWeight: "bolder" }}>&nbsp;/&nbsp;</span>
																		<span style={{ color: "rgb(210, 26, 26)" }}>{this.formatTimeValue(time.fajrIqama)}</span>
																	</span>
																</div>
																{/* </span>
                                </div> */}
															</td>
															<td style={{ border: "none", fontSize: "14px" }}>
																{/* <div class="input-group"> */}
																<div style={{ backgroundColor: "#eee", padding: "6px 12px", fontWeight: '500', fontFamily: "Arial", }}>
																	{this.formatTimeValue(time.sunriseTime)}
																</div>
																{/* </div> */}
															</td>
															<td style={{ border: "none", fontSize: "14px" }}>
																{/* <div class="input-group"> */}
																<div style={{ backgroundColor: "#eee", padding: "6px 12px", fontWeight: '500', fontFamily: "Arial" }}>
																	{
																		time.Day === 'Fri' ?
																			(<div className="juma-scroll">
																				<span>
																					<span style={{ color: "#bd00ff" }}>{this.formatTimeValue(time.jummaIqama1)}</span>
																					{!time.jummaIqama2 ? null : <><span style={{ fontWeight: "medium", }}>&nbsp;&&nbsp;</span></>}
																					<span style={{ color: "#5A17EF" }}>{this.formatTimeValue(time.jummaIqama2)}</span>
																					{!time.jummaIqama3 ? null : <><span style={{ fontWeight: "medium", }}>&nbsp;&&nbsp;</span></>}
																					<span style={{ color: "#079F0B" }}>{this.formatTimeValue(time.jummaIqama3)}</span>
																				</span>
																			</div>) :
																			(<span>
																				<span style={{ color: "black" }}>{time.dhuhrTime}</span>
																				<span style={{ fontWeight: "bolder" }}>&nbsp;/&nbsp;</span>
																				<span style={{ color: "rgb(210, 26, 26)" }}>{this.formatTimeValue(time.dhuhrIqama)}</span>
																			</span>)
																	}
																</div>
																{/* </div> */}
															</td>

															<td style={{ border: "none", fontSize: "14px" }}>
																{/* <div class="input-group"> */}
																<div style={{ backgroundColor: "#eee", padding: "6px 12px", fontWeight: '500', fontFamily: "Arial", }}>
																	<span>
																		<span style={{ color: "black" }}>{time.asrTime}</span>
																		<span style={{ fontWeight: "bolder" }}>&nbsp;/&nbsp;</span>
																		<span style={{ color: "rgb(210, 26, 26)" }}>{this.formatTimeValue(time.asrIqama)}</span>
																	</span>
																</div>
																{/* </div> */}
															</td>

															<td style={{ border: "none", fontSize: "14px" }}>
																{/* <div class="input-group"> */}
																<div style={{ backgroundColor: "#eee", padding: "6px 12px", fontWeight: '500', fontFamily: "Arial", }}>
																	<span><span style={{ color: "black" }}>{time.maghribTime}</span>
																		<span style={{ fontWeight: "bolder" }}>&nbsp;/&nbsp;</span>
																		<span style={{ color: "rgb(210, 26, 26)" }}>{this.formatTimeValue(time.maghribIqama)}</span>
																	</span>
																</div>
																{/* </div> */}
															</td>

															<td style={{ border: "none", fontSize: "14px" }}>
																{/* <div class="input-group"> */}
																<div style={{ backgroundColor: "#eee", padding: "6px 12px", fontWeight: '500', fontFamily: "Arial", }}>
																	<span>
																		<span style={{ color: "black" }}>{time.ishaTime}</span>
																		<span style={{ fontWeight: "bolder" }}>&nbsp;/&nbsp;</span>
																		<span style={{ color: "rgb(210, 26, 26)" }}>{this.formatTimeValue(time.ishaIqama)}</span>
																	</span>
																</div>
																{/* </div> */}
															</td>
														</tr>
													)
												)
											}

										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Prayertimes section ends here */}
				<Footeradmin />
			</>
		);
	}
}
export default withRouter(GoogleApiWrapper({
	apiKey: (CONFIG.GOOGLE_API_KEY)
})(Pendingactivationdetails));