var constants = (function() {
    return {
        PORTAL_URI: 'http://localhost:3000/',
        API_END_POINT: 'http://localhost:8888/',
        GOOGLE_API_KEY: 'AIzaSyBdLclv5TxmFtRrXM7ZEqXZ5DOv8rxXra4'
    };
});

export default constants();

export const CONFIG = {
    //LOCALHOST PORTAL URI
    // PORTAL_URI: 'http://localhost:3000/',

    // VIJAYAPUR.INFO PORTAL URI
    // PORTAL_URI: 'https://vijayapur.info/',

    //PRAYERCALENDARS PORTAL URI
     PORTAL_URI: 'https://prayercalendars.com/',

    //LOCALHOST API_END_POINT
    //  API_END_POINT: 'http://localhost:8888/',

    //PREVIOUS SERVER WITH HTTP API_END_POINT
    // API_END_POINT: 'http://3.140.169.245:8888/',

    //CURRENT SERVER WITH HTTPS API_END_POINT
    //API_END_POINT: 'https://3.140.169.245:2021/',

    //PRAYERCALENDARS SERVER API_END_POINT
    // API_END_POINT: 'https://prayercalendars.com:8888/',
    API_END_POINT: 'https://prayercalendars.net/',

    //VIJAYAPUR.INFO HTTPS SERVER API_END_POINT
    // API_END_POINT: 'https://3.140.169.245:8888/',
    // API_END_POINT: 'https://vijayapur.info/',

    //PC_Vijayapur New Server
    // PORTAL_URI: 'http://13.58.100.186/',
    // API_END_POINT: 'http://13.58.100.186:8888/',
    
    //GOOGLE API AND MAP KEYS
    GOOGLE_API_KEY: 'AIzaSyBdLclv5TxmFtRrXM7ZEqXZ5DOv8rxXra4',
    GOOGLE_MAPS_KEY: 'AIzaSyBdLclv5TxmFtRrXM7ZEqXZ5DOv8rxXra4'
};