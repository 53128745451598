import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import '../../App.css';
import './home.css';
import logo1 from '../../images/logo1.jpg'
import { Row, Col } from 'reactstrap';

import MapContainer from './GoogleMap';

import Testing2 from '../Testing2';
import Footer1 from '../../Footer/Footer1'
import { Form } from 'reactstrap';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapCenter: '',
      visibleMosques: [],
      editID: '',
      flag: false,
      isToggleActive: false
    }
  }

  setVisibleMosques = mosques => {
    this.setState({ visibleMosques: mosques })
  }

  getMapCenterValue = (data, address) => {
    this.setState({ mapCenter: data, address: address })
  }

  editHanlder = (id) => {
    this.setState({ editedId: id, flag: true })
    // this.props.history.push(`/pendingactivationdetails/:${id}`)

  }

  handleFormSubmit = async (e, id) => {
    e.preventDefault();
    const mosque = this.state.visibleMosques.filter(mos => mos.id === id)[0];
    // const template = mosque.selecTedtemplate;
    this.props.history.push('/brochure', {
      id: mosque.id,
      template: mosque.selecTedtemplate,
      name: mosque.title,
      slots: mosque.slots
    });
  }

  static getDerivedStatesFromProps(nextProps, prevState) {
    if (prevState.mapCenter !== nextProps?.location?.state?.coOrdinates) {
      return {
        mapCenter: nextProps.location.state.coOrdinates
      };
    }
    return null;
  }

  // componentDidUpdate(prevState, prevProps) {
  //   if (prevState.mapCenter !== this.props?.location?.state?.coOrdinates) {
  //     console.log(prevState.mapCenter, this.props?.location?.state?.coOrdinates)
  //     // this.setState({mapCenter: this.props?.location?.state?.coOrdinates})
  //   }
  // }

  componentDidMount() {
    if (this.props?.location?.state?.coOrdinates) {
      this.getMapCenterValue(this.props.location.state.coOrdinates)
    }
  }

  toggleCollapse = _ => {
    this.setState(prevState => ({ isToggleActive: !prevState.isToggleActive }))
  };

  render() {
    const { visibleMosques, isToggleActive } = this.state;
    return (
      <>
        <div>
          <Testing2 getMapCenterValue={this.getMapCenterValue} />
          <Row>
            {
              !isToggleActive && (
                <Col md={5} xl={3}>
                  <div
                    style={{ padding: '5px', margin: '5px', height: "75vh", overflow: "auto", width: "100%" }}
                  //  className='masjid1'
                  >
                    <Col>
                      <div>
                        {
                          visibleMosques?.length === 0
                            ? (
                              <div style={{ textAlign: 'center', background: '#eef2f6', fontSize: "15px", lineHeight: "21px", padding: '8px 0', fontWeight: '700', fontFamily: "Arial" }}>
                                <span>No results found</span> <br />
                                <span>Try expanding your search</span>
                              </div>
                            )
                            : (
                              visibleMosques.map(
                                mosque => (
                                  <Row className="cardd">
                                    {/* <Col md={2}></Col> */}
                                    <Col md={12} className="d-flex">
                                    <img alt="logo" className="cardd-logo" src={logo1} />
                                      <Form action="#" onSubmit={e => this.handleFormSubmit(e, mosque.id)} className="card-form">
                                        <Row >
                                          <Col style={{ color: " #D21A1A", fontSize: "20px", textTransform: "capitalize", fontWeight: "700" }} name="address" ><h5 className="sidebar-card-title">{mosque.title}</h5></Col>
                                        </Row>
                                        <Row >
                                          <Col style={{ marginTop: "0px", overflow: "hidden", textOverflow: "ellipsis", color: "#696c70", fontWeight: "lighter", fontFamily: "Helvetica", fontSize: "12px" }} name="address"  ><i class="fas fa-map-marker-alt" style={{ marginRight: "3px" }}></i>{mosque.address}</Col><br />
                                        </Row>
                                        <Row >
                                          <Col style={{ fontFamily: "Helvetica", fontWeight: "lighter" }} >Available Spot: {mosque.availablespot}/{mosque.slots}</Col>
                                        </Row>
                                        <Row>
                                          <Col md={12}>
                                            <button style={{ float: 'right', marginTop: '10px', fontWeight: "700", fontFamily: "Arial", fontSize: "13px", }} className="btnhome" onClick={() => this.editHanlder(mosque.id)}>Advertise here</button>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </Col>
                                  </Row>
                                )
                              )
                            )
                        }
                      </div>
                    </Col>
                  </div>

                  {/* <div className='masjid3'>

                  </div> */}
                </Col>
              )
            }

            <Col md={isToggleActive ? 12 : 7} style={{ display: 'flex' }} xl={isToggleActive ? 12 : 9}>

              <div onClick={this.toggleCollapse} style={{ width: '2%', display: 'flex', background: '#f4f4f4' }} className="custom-toggle hidden">
                {
                  isToggleActive ? (
                    <span style={{ margin: 'auto', fontWeight: '700', fontSize: '18px' }}> <i class="fa fa-caret-right" aria-hidden="true"></i></span>
                  ) : (
                      <span style={{ margin: 'auto', fontWeight: '700', fontSize: '18px' }}> <i class="fa fa-caret-left" aria-hidden="true"></i></span>
                    )
                }
              </div>
              <div className="map-wrapper">
                <MapContainer setMosques={this.setVisibleMosques} mapCenter={this.state.mapCenter} />
              </div>

            </Col>
          </Row>
        </div >
        <Footer1 />
      </>
    )
  }
}

export default withRouter(Home);