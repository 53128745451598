import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import Whitelogo from '../images/logo-white.png'
import './footer1.css'
// import footerbefore from '../assets/images/footerbefore.png'

class Footer1 extends Component {
    render() {
        return (
            <>
                <div className="back-color-foot">
                    <div className="foot">

                        <Row>
                            <div className="col-md-6 col-lg-2 mb-4">
                                <img alt="logo" src={Whitelogo} style={{width: "100%", maxWidth: "160px"}}></img>
                            </div>
                            <div className="col-md-6 col-lg-2 mb-4">
                                <h5 className="abt termser">Welcome</h5>
                                {/* <img alt="" src={footerbefore} className="wel-img" /> */}
                                <div><Link className="sup" to="/support">Support</Link><br /></div>
                                <div><a target="_blank" className="sup" href="/termsofservices">Terms of Services</a></div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-4" >
                                <h5 className="abt termser">About Us</h5>
                                {/* <img alt="" src={footerbefore} className="foot-img" /> */}
                                <div className="sup">Our mission at Prayer Calendars is to establish an advertising platform to help Muslim businesses to directly market to the muslim community. Advertise locally or nationally to any Masjid on our platform for an affordable price.</div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-4" >
                                <h5 className="abt termser">Contact Us</h5>
                                {/* <img alt="" src={footerbefore} className="foot-img" /> */}
                                {/* <div className="sup">Phone: (214) 205 - 2908</div>
                                <div className="sup">Address: 9720 Coit Road, Suite 220-203, Plano, TX 75025</div> */}
                                <div className="sup">Email: <a href="mailto:admin@prayercalendars.com" style={{ color: "inherit", textDecoration: "none" }}>admin@prayercalendars.com</a></div>
                            </div>
                        </Row>

                    </div>
                    <div className="copy">
                        <Row className="btrees-foot">
                            <div className="col-lg-8 text-center text-lg-left" >
                                Copyright © Prayer Calendars., 2021
                        </div>
                            <div className="col-lg-4 text-center text-lg-right">
                                <div>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.btrees.in/" style={{ textDecoration: "none", color: "inherit" }}>Developed By BTrees Technologies</a>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}
export default Footer1;