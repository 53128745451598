import { CONFIG } from './constants';
import axios from './axios';




export const LogoutAll = () => {
  const mosqueEmail = localStorage.getItem('email');
  const businessEmail = localStorage.getItem('businessemail')
  const MosqueEmail = localStorage.getItem('mosqueemail')
  let obj = {}
  obj.email = JSON.parse(mosqueEmail) || businessEmail || MosqueEmail
  axios({
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    url: CONFIG.API_END_POINT + 'api/v1/common/logout',
    data: obj
    // params:{truckSizeId:"10002"}        
  }).then((response) => {
    if (response.data.success) {
      // alert("You have been successfully logged out.")
      localStorage.removeItem('token');
      localStorage.removeItem('businesstoken');
      localStorage.removeItem('email');
      localStorage.removeItem('businessemail');
      localStorage.removeItem('mosqueemail');
      localStorage.removeItem('mosquetoken');
      localStorage.removeItem('role');
      localStorage.removeItem('mosqueId');
      localStorage.removeItem('id');
      localStorage.removeItem('FirstName');
      localStorage.removeItem('LastName');
      localStorage.removeItem('BusinessTitle');
      window.location.href = CONFIG.PORTAL_URI
    }else{
      alert("Could not logout! Try agian later")
      // this.setState({isLoading: false})
    } 
  }).catch(error => {
    console.log(error.posRes);
  })
}

export const getImgBase64 = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }));