import React, {Component} from 'react';
import {Row, Col, Form} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CONFIG } from '../../../../utils/constants';
 import axios from '../../../../utils/axios'

class ToAdvertiser extends Component {
    constructor(props){
        super(props)
        this.state = {
            advertiser: "",
            amount: "",
            description: "",
            Paymentprocessor: "",
        }
    }
    advertiserhandler = (event) => {
        this.setState({
            advertiser: event.target.value
        })
    }
    amounthandler = (event) => {
        this.setState({
            amount: event.target.value
        })
    }
    descriptionhandler = (event) => {
        this.setState({
            description: event.target.value
        })
    }
    Paymentprocessorhandler = (event) => {
        this.setState({
            Paymentprocessor: event.target.value
        })
    }
  

    handleSubmit = (event) => {
        event.preventDefault()
        let obj = {}
        obj.advertiser = this.state.advertiser
        obj.amount = this.state.amount
        obj.Paymentprocessor = this.state.Paymentprocessor
        obj.description = this.state.description

        axios.post(CONFIG.API_END_POINT + '')
    }

    render() {
        return(
           <>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <label for="advertiser" className="mos">Advertiser</label><br />
                            <input className="mos1 que" placeholder="Select Advertiser" value={this.state.advertiser} onChange={this.advertiserhandler} list="browsers" name="myBrowser" required />

                            <datalist id="browsers">
                                <option value="Jamia Masjid" />
                                <option value="Test" />

                            </datalist>
                        </Col>
                        <Col>
                            <label for="amount" className="mos">Amount</label><br />
                            <input className="mos1" placeholder="0" val={this.state.amount} onChange={this.amounthandler}  name="amount" required />
                        </Col>
                    </Row><br />
                    <Row>
                        <Col>
                            <label for="payementprocessor" className="mos">Payment processor</label>
                            <input className="mos1 que1" placeholder="Authorize.Net" value={this.state.Paymentprocessor} onChange={this.Paymentprocessorhandler} list="browsers1" name="myBrowser" required />

                            <datalist id="browsers1">
                                <option value="Authorize.Net" />
                                {/* <option value="Ramadan Calendar" /> */}

                            </datalist>
                        </Col>
                    </Row><br />
                    <Row>
                        <Col>
                        <label className="mos2">Description</label>
                      <textarea className="mos3" rows="3" val={this.state.description} onChange={this.descriptionhandler}  required></textarea>                        
                        </Col>
                    </Row><br /><br />
                    <Row>
                        <Col><input className="tog" type="submit" value="Submit" /></Col>
                    </Row>
                </Form>
           </>
        );
    }
}
export default ToAdvertiser;