import React, { Component } from 'react';
import { render } from 'react-dom';
import { CONFIG } from '../../utils/constants';

import { GoogleApiWrapper } from 'google-maps-react';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import axios from 'axios';
import Geocode from "react-geocode";
import './home.css';
import { withRouter } from 'react-router-dom';
// import MosqueMarker from '../../assets/icons/Mosque.png';
import preview from '../../images/Spinner1.gif'
import { Col, Row } from 'reactstrap';
import logo1 from '../../images/logo1.jpg'
import { Alert } from 'antd';

Geocode.setApiKey("AIzaSyDoaqhx4UKA-c0b69T3kgo_N2j9DK42jxU");
Geocode.enableDebug();

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      loading: true,
      lat: 1,
      long: 1,
      map: null,
      markersList: [],
      mapCenter: {},
      locPermisssion: ''
    };
  }

  handleChange = address => {
    this.setState({ address });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (typeof nextProps.mapCenter == "object") {
      if (nextProps.mapCenter !== prevState.mapCenter) {
        if (prevState.map) {
          prevState.map.setCenter({ lat: nextProps.mapCenter.lat, lng: nextProps.mapCenter.lng });
          return {
            ...prevState,
            mapCenter: nextProps.mapCenter
          };
        } else {
          return {
            unInitcords: nextProps.mapCenter
          };
        }
      }
    }
    return null;
  }

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        // update center state
        this.setState({ mapCenter: latLng });
      })
      .catch(error => console.error('Error', error));
  };



  componentDidMount() {
    navigator.geolocation.getCurrentPosition(() => {
      this.setState({ locPermisssion: 'granted' })
    }, (error) => {
      if (error.code == error.PERMISSION_DENIED) {
        this.setState({ locPermisssion: 'denied' })
      }
    });




    // Get location of user
    const success = position => {

      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.getLocation(latitude, longitude)
      this.setState({
        lat: latitude,
        long: longitude
      });
    };

    const error = () => {

    };

    navigator.geolocation.getCurrentPosition(success, error);
  }

  handleAdvertiseHere = item => {
    this.props.history.push('/brochure', {
      id: item.id,
      template: item.selecTedtemplate,
      name: item.loc.MosqueName
    })
  }

  getLocation = (longitude, latitude) => {
    axios.get(`${CONFIG.API_END_POINT}api/v1/common/locations?lat=${latitude}&long=${longitude}`, {
      lat: latitude,
      long: longitude
    })
      .then((response) => {
        if (response.data.success) {
          const data = response.data.success;
          this.setState({ data: data, loading: false });
          this.setState({
            map: new window.google.maps.Map(document.getElementById('#map-container'), {
              center: new window.google.maps.LatLng(longitude, latitude),
              zoom: 12,
              mapTypeId: window.google.maps.MapTypeId.ROADMAP,
              mapTypeControl: true,
              zoomControl: true,
              zoomControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_CENTER
              },
              streetViewControl: true,
              streetViewControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_BOTTOM,
              },
            })
          }, () => {

            const markers = [];
            const image = `${CONFIG.API_END_POINT}public/marker.png`;
            data.forEach(item => {
              const latLng = item.loc.coordinates;
              const contentString = '<div id="info-window_' + item.id + '"> Test value</div>';
              const infowindow = new window.google.maps.InfoWindow({
                content: contentString,
                maxWidth: 300,
              });
              infowindow.addListener('domready', e => {
                render(<>
                  <Row style={{ marginTop: "0px", }}>
                    <Col md={2}><img alt="alternate" style={{ borderRadius: "50px", height: "70px", width: "70px" }} src={logo1} width="260%" /></Col>
                    <Col md={10}>

                      <Row style={{ marginLeft: "15px" }}>
                        <Col style={{ color: " #D21A1A", fontSize: "20px", textTransform: "capitalize", fontWeight: "700" }} name="address" ><h5 className="sidebar-card-title" >{item.loc.MosqueName}</h5></Col>
                      </Row>
                      <Row style={{ marginLeft: "15px", overflow: "hidden", textOverflow: "ellipsis" }}>
                        <Col style={{ marginTop: "0px", overflow: "hidden", textOverflow: "ellipsis", color: "#696c70", fontWeight: "lighter", fontFamily: "Helvetica", fontSize: "12px" }} name="address"  ><i class="fas fa-map-marker-alt" style={{ marginRight: "3px" }}></i>{item.loc.Address}</Col><br />
                      </Row>
                      <Row style={{ marginLeft: "15px" }}>
                        <Col style={{ fontFamily: "Helvetica", fontWeight: "lighter" }} >Available Spot: {item.availableslot}/{item.slot}</Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <button style={{ float: 'right', marginTop: '10px', fontWeight: '700', fontSize: "13px" }} className="btnhome" onClick={() => this.handleAdvertiseHere(item)}>Advertise here</button>
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                </>
                  , document.getElementById("info-window_" + item.id))
              });
              let marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(latLng[1], latLng[0],),
                id: item.id,
                slots: item.slot,
                availablespot: item.availableslot,
                title: item.loc.MosqueName,
                address: item.loc.Address,
                icon: image,
                ...item,
                // title: "Uluru (Ayers Rock)",
              })
              marker.setMap(this.state.map);
              markers.push(marker)
              // ReactDOM.render(React.Children.only(infoHTML),document.getElementById(`#info_${item.id}`))
              marker.addListener("click", () => {
                infowindow.open(this.state.map, marker);
              });
            })
            if (this.state.unInitcords) {
              this.state.map.setCenter({ ...this.state.unInitcords })
            }
            this.setState({ markers: markers })
            window.google.maps.event.addListener(this.state.map, 'idle', this.showVisibleMarkers);
          })
        }
        else {

        }
      })
  }

  showVisibleMarkers = () => {
    let bounds = this.state.map.getBounds();
    const visbileMarkers = this.state.markers?.filter(
      marker => {
        if (bounds.contains(marker.getPosition())) {
          return marker;
        }
        return null
      }
    )
    this.props.setMosques(visbileMarkers)
  };

  render() {
    // let bounds = this.props.google.maps;
    if (this.state.locPermisssion === 'denied') {
      return (
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Alert
            message={<div style={{ fontSize: "25px", color: "red" }}>Error !!!</div>}
            description={<div style={{ fontSize: "20px" }}>Please allow the location permission to load the map properly</div>}
            type="error"
          />
        </div>
      )
    } else {
      if (this.state.loading) {
        return (
          <div style={{ textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img alt="alternate" src={preview} />
          </div>
        )
      }
    }
    // if (!this.state.loading) {
    //   this.state.data.map(x => (
    //     console.log('location', x.loc.coordinates[0])
    //   ))
    // }
    return (
      <div id='googleMaps'>
        <div id="#map-container" style={{ width: '100%', height: '77vh', }}></div>
      </div>
    )
  }
}

export default withRouter(GoogleApiWrapper({
  apiKey: (CONFIG.GOOGLE_API_KEY)
})(MapContainer));