import React, {Component} from 'react';
import fifty from '../../src/images/50.png';
import daleelo from '../../src/images/daleelo-logo.png';
import appScreenshot from '../../src/images/app-screenshots.png';
import andriod from '../../src/images/andriod.png';
import appStore from '../../src/images/appstore.png';
import Rating from '../../src/images/rating.png'
import { LoadingOutlined } from '@ant-design/icons';
import Testing2 from '../components/Testing2';
import Footer1 from '../Footer/Footer1';
// import axios from '../utils/axios';
// import { CONFIG } from '../utils/constants';

class PaymentSuccessfull extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false
        }
      }


      componentDidMount() {
        console.log(this.props);
      }
    
    
    

    // getBusinessTransactionalDetails = () => {
    //         this.setState({ isLoading: true }, () => {
    //           const id = localStorage.getItem('mosqueId')
    //           axios.get(CONFIG.API_END_POINT + 'api/v1/common/BusinessPaymentHistory', {
    //             params: {
    //                   page: 1,
    //                   id: id,
    //             }
    //           })
    //                 .then((response) => {
    //                     if (response.data.success) {
    //                         const data = response?.data?.success
    //                         this.setState({
    //                             transactionid: data.transactionID,
    //                             businessname: data.BusinessTitle,
    //                             paymentdate: data.paymentDate,
    //                             slotnumber: data.slotNumber,
    //                             amount: data.amount,
    //                             paymentprocessor: data.PaymentProcessor,
    //                             city: data.city,
    //                             state: data.state,
    //                             zipcode: data.zipCode,
    //                             isLoading: false
    //                         })
    //                     } else {
    //                         alert("No Transaction details")
    //                     }
    //                 })
    //         })
    // }
              

    render () {
        return (
            <>
                <div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "50%" }}>
                    {this.state?.isLoading &&
                        <LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
                    }
                </div>
                <Testing2 />
                <div classNameName="container">
                    <div style={{ fontFamily: "WebFonts" }}>
                        <h2 style={{ paddingTop: "2rem", color: "#374767", fontWeight: "700", textAlign: "center" }}>Thank you for advertising with us! Your payment has been successful.</h2>
                        
                        <div className="row">
                            <div className="offset-md-2 col-md-8">
                                <h4 style={{ paddingTop: "1.2rem", color: "#374767", fontWeight: "700" }}>Transaction details:</h4>
                                <table>
                                    <tr>
                                        <th style={{ color: "#374767" }}>Transaction</th>
                                        <td style={{ color: "#374767" }}>{this.props?.location?.state?.transactionID}</td>
                                        <th style={{ color: "#374767" }}>Business name</th>
                                        <td style={{ color: "#374767" }}>{this.props?.location?.state?.BusinessTitle}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ color: "#374767" }}>Payment date</th>
                                        <td style={{ color: "#374767" }}>{this.props?.location?.state?.paymentDate?.split('T')[0]}</td>
                                        <th style={{ color: "#374767" }}>Description</th>
                                        <td style={{ color: "#374767" }}> - Ad Spot # {this.props?.location?.state?.slotNumber}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ color: "#374767" }}>Status</th>
                                        <td style={{ color: "#374767" }}>Payment Processed</td>
                                        <th style={{ color: "#374767" }}>Amount</th>
                                        <td style={{ color: "#374767" }}>${this.props?.location?.state?.amount}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ color: "#374767" }}>Payment processor</th>
                                        <td style={{ color: "#374767" }}>{this.props?.location?.state?.PaymentProcessor}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className="offer-wrapper px-2 px-md-4 px-lg-0">
                            <div className="row">
                                <div className="col-md-6 offset-md-3 mt-5">
                                <div className="offer-box">
                                    <div className="row">
                                        <div className="col-md-12 text-center"><h5 style={{fontWeight: "800", fontSize: "30px", color: "white"}}>Enjoy a special offer from PrayerCalendars.com</h5></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 my-md-2 text-center">
                                            <img className="50%" src={fifty} style={{width: "100%", maxWidth: "200px"}} alt="" />
                                            <p className="px-lg-4" style={{fontWeight: "600", fontSize: "17px", color: "#374767"}}>Now only $4/month</p>
                                        </div>
                                        <div className="col-md-7 col-md-7 my-md-2 pt-md-4 pl-lg-3 text-center text-md-left my-md-2 pl-lg-4 text-center">
                                            <p style={{fontWeight: "800", fontSize: "25px", marginBottom: "6px", lineHeight: "1.2", color: "#1a6ba8"}}>When you list your business with</p>
                                            <img className="daleelo-logo" src={daleelo} style={{width: "100%", maxWidth: "200px"}} alt="" />
                                            <p style={{fontWeight: "800", fontSize: "22px", lineHeight: "1.2", marginBottom: "10px", color: "#dfb20a"}}>The #1 Community App</p>
                                        </div>
                                        <div class="col-12 text-center"><p style={{fontWeight: "800", fontSize: "17px", color: "rgb(210, 26, 26)", marginBottom: "0px", borderTop: "1px solid #d5d5d5", padding: "10px"}}>USE PROMO
                                         CODE PC 50 <a href="https://daleelo.com/Avment.aspx" target="_blank" rel="noopener noreferrer" style={{fontWeight: "800", fontSize: "17px", color: "rgb(210, 26, 26)"}}>CLICK NOW</a></p></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="marketing-banner">
                                        <div className="row">
                                            <div className="col-lg-6 text-center">
                                                <img id="playstore-image" alt="playstore" src={appScreenshot} style={{padding: "30px 25px", width: "100%", maxWidth: "500px"}} />
                                            </div>
                                            <div className="col-lg-6"style={{paddingTop: "2rem"}}>
                                                <div className="playstore-text" id="playstore-text">
                                                    <h2>Take <strong>Daleelo</strong> with you. It's free!</h2>
                                                    <p style={{fontSize: "17px"}}>You can search thousands of local &amp; national business on the go. Everything you need is in one app</p>
                                                </div>
                                                <div className="playstore-links">
                                                    <a href="https://play.google.com/store/apps/details?id=com.daleelo"  rel="noopener noreferrer" target="_blank"><img alt="playstore" src={andriod} style={{width: "133px"}}/></a>
                                                    <a href="https://itunes.apple.com/au/app/daleelo-your-muslim-community-app/id536783455?mt=8" rel="noopener noreferrer" target="_blank"><img alt="playstore" src={appStore} style={{width: "130px"}} /></a>
                                                </div>
                                                <div id="playstore-text-center" style={{width: "100%"}}>
                                                    <img alt="rating" src={Rating} style={{width: "120px", marginLeft: "10px"}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <a target="_blank" href="/paymentdone"><button>payment</button></a> */}  
                    </div>
                </div>
                <Footer1 />
            </>
        );
    }
}
export default PaymentSuccessfull;