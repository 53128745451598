import React, { lazy, Suspense } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';












import Checkbox from './business_user/Checkbox';
import Demobusiness from './business_user/demobusiness';
import DemoTemplate from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/DemoprintTemplate'
import RealTemplate1 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template4'
import RealTemplate2 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template3'
import RealTemplate3 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template2'
import RealTemplate4 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template1'
import Template5 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template5'
import Template6 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template6'
import Template7 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template7'
import Template8 from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/template8'
import PendingActivation1 from './admin/Mosque/PendingMosque';
import MakePayment from './admin/PaymentHistoryPag/PaymentHistoryExpandPag/ViewOrderPag/MakePayment'
import ReceivePayment from './admin/PaymentHistoryPag/PaymentHistoryExpandPag/ViewOrderPag/ReceivePayment'



import './App.css';
import './components/Navbar.css';
import './components/pages/login.css';
import './App1.css';
import PaymentSuccessfull from './business_user/PaymentSuccessful';









//Masjid imports
const UserForm = lazy(() => import('../src/masjid/Step1'));  //import UserForm from '../src/masjid/Step1';
const MasjidTerms = lazy(() => import('./Footer/MasjidTerms&Conditions'))

const VerifyEmail = lazy(() => import('./components/pages/VerifyRegisterEmail'))

//Masjid login imports
const MosqueLayout = lazy(() => import('./masjiddashboard')); //import MosqueLayout from './masjiddashboard';


//Business imports
const  Businessuserr = lazy(() => import('./business_user/businessuser')); //import Businessuserr from './business_user/businessuser';
const  BusinessAdmin = lazy(() => import('./businessdashboard/businessdashboard')); //import BusinessAdmin from './businessdashboard/businessdashboard';
const  BusinessSettings = lazy(() => import('./businessdashboard/businesssettings')); //import BusinessSettings from './businessdashboard/businesssettings';
const  Newcustomer = lazy(() => import('./business_user/Newcustomer')); //import Newcustomer from './business_user/Newcustomer';
const  TabExampleBasic = lazy(() => import('./business_user/Reacttab')); //import TabExampleBasic from './business_user/Reacttab';
const BrochureMaster = lazy(() => import('./brochure'))   //import BrochureMaster from './brochure';
const BusinessTerms = lazy(() => import('./Footer/BusinessTerms&Conditions')) //import BusinessTerms from './Footer/BusinessTerms&Conditions';
const PrintownTerms = lazy(() => import('./Footer/PrintOwnTerms&Conditions')) //import BusinessTerms from './Footer/BusinessTerms&Conditions';





//Business login imports
const  BusinessOrders = lazy(() => import('./components/pages/BusinessOrdersPag/BusinessOrdersPag')); //import BusinessOrders from './components/pages/BusinessOrdersPag/BusinessOrdersPag';
const  BusinessPayment = lazy(() => import('./components/pages/BusinessPaymentPag/BusinessPaymentPag')); //import BusinessPayment from './components/pages/BusinessPaymentPag/BusinessPaymentPag';
const  NewsLetters = lazy(() => import('./components/pages/NewsLetterPag/NewsLetterPag'));
const  NewsletterTemplate1 = lazy(() => import('./components/pages/NewsLetter/NewsletterTemplates/NewsletterTemplate1'));
const  NewsLetterDetailTemplate1 = lazy(() => import('./components/pages/NewsLetter/NewsletterTemplates/NewsLetterDetailTemplate1'));
const  PrayerTemplate1 = lazy(() => import('./components/pages/BusinessPrayertimes/GeneratePrayerTimesTemplates/PrayerTimesTemplate1'));
const  GeneratePrayers = lazy(() => import('./components/pages/BusinessPrayertimes/PrayertimesPag/GeneratePrayerPag'));
const  DesignerLetter = lazy(() => import('./components/pages/NewsLetter/WorkWithDesignerLetter'))
const DesignerPrayer = lazy (() => (import('./components/pages/BusinessPrayertimes/WorkWithDesignerPrayertimes')))
const  CreateAccountSettings = lazy(() => import('./components/pages/BusinessSettings/CreateAccountSettings')); //import CreateAccountSettings from './components/pages/BusinessSettings/CreateAccountSettings'
const PrayerTimesDetailTemplate1 = lazy(() => import('./components/pages/BusinessPrayertimes/GeneratePrayerTimesTemplates/PrayerTimesDetailTemplate1'));


//Home imports
const Formlog = lazy(() => import('./components/pages/Login')) //import Formlog from './components/pages/Login';
const  Forgot = lazy(() => import('./components/pages/Forgotpassword')); //import Forgot from './components/pages/Forgotpassword';
const  Confirm = lazy(() => import('./components/pages/Confirmforgot')); //import Confirm from './components/pages/Confirmforgot';
const  Create = lazy(() => import('./components/pages/Createaccount')); //import Create from './components/pages/Createaccount';
const  Reset = lazy(() => import('./components/pages/resetpassword')); //import Reset from './components/pages/resetpassword';


//Footer imports
const  Termsofservices = lazy(() => import('./Footer/Termsofservices')); //import Termsofservices from './Footer/Termsofservices';
const  Support = lazy(() => import('./Footer/Support')); //import Support from './Footer/Support';

//Payment link imports
const paymentLink = lazy(() => import('./admin/Payment_Link/paymentLink'));
const Payment_page = lazy(() => import('./admin/Payment_Link/Payment_page'))

//Admin imports
const  Pendingactivationdetails = lazy(() => import('./admin/Mosque/PendingMosqueEdit')); // import Pendingactivationdetails from './admin/Mosque/PendingMosqueEdit';
const  Approvedactivationdetails = lazy(() => import('./admin/Mosque/ApprovedMosqueEdit')); //import Approvedactivationdetails from './admin/Mosque/ApprovedMosqueEdit';
const  FilleradvertiserEdit = lazy(() => import('./admin/Advertisers/FillerAdvertiserEdit')); //import FilleradvertiserEdit from './admin/Advertisers/FillerAdvertiserEdit'
const  PayingadvertiserEdit = lazy(() => import('./admin/Advertisers/PayingAdvertiserEdit')); //import PayingadvertiserEdit from './admin/Advertisers/PayingAdvertiserEdit'
const  Ledger = lazy(() => import('./admin/Advertisers/PayingAdvertiserPag/LedgerPag/LedgerPag')); //import Ledger from './admin/Advertisers/PayingAdvertiserPag/LedgerPag/LedgerPag';
const  DashboardAdmin = lazy(() => import('./admin/Dashboard')); //import DashboardAdmin from './admin/Dashboard';
const  ActivebatchExpand = lazy(() => import('./admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActivebatchExpandPag')); //import ActivebatchExpand from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActivebatchExpandPag';
const  BatchesInPrintingExpand = lazy(() => import('./admin/Orders/BatchesInPrintingPag/BatchesInPrintingExpandPag/BatchesInPrintingExpandPag')); //import BatchesInPrintingExpand from './admin/Orders/BatchesInPrintingPag/BatchesInPrintingExpandPag/BatchesInPrintingExpandPag';
const  PreprintExpand = lazy(() => import('./admin/Orders/PreprintBatchPag/PreprintExpandPag/PreprintExpandPag')); //import PreprintExpand from './admin/Orders/PreprintBatchPag/PreprintExpandPag/PreprintExpandPag';
const  BatchHistoryExpand = lazy(() => import('./admin/Orders/BatchHistoryPag/BatchHistoryExpandPag/BatchHistoryExpandPag')); //import BatchHistoryExpand from './admin/Orders/BatchHistoryPag/BatchHistoryExpandPag/BatchHistoryExpandPag';
const  PaymentHistoryExpand = lazy(() => import('./admin/PaymentHistoryPag/PaymentHistoryExpandPag/PaymentHistoryExpandPag')); //import PaymentHistoryExpand from './admin/PaymentHistoryPag/PaymentHistoryExpandPag/PaymentHistoryExpandPag';
const  ViewOrder = lazy(() => import('./admin/PaymentHistoryPag/PaymentHistoryExpandPag/ViewOrderPag/ViewOrderPag')); //import ViewOrder from './admin/PaymentHistoryPag/PaymentHistoryExpandPag/ViewOrderPag/ViewOrderPag';
const  Ad_Approve = lazy(() => import('../src/admin/Mosque/ApprovedMosquePag/ApprovedMosquePag')); //import Ad_Approve from '../src/admin/Mosque/ApprovedMosquePag/ApprovedMosquePag';
const  PendingActivation = lazy(() => import('../src/admin/Mosque/PendingMosquePag/PendingMosquePag')); //import PendingActivation from '../src/admin/Mosque/PendingMosquePag/PendingMosquePag';
const  FillerAdvertiser = lazy(() => import('./admin/Advertisers/FillerAdvertiserPag/FillerAdvertiserPag')); //import FillerAdvertiser from './admin/Advertisers/FillerAdvertiserPag/FillerAdvertiserPag'
const  PayingAdvertiser = lazy(() => import('./admin/Advertisers/PayingAdvertiserPag/PayingAdvertiserPag')); //import PayingAdvertiser from './admin/Advertisers/PayingAdvertiserPag/PayingAdvertiserPag'
const  NewMosq = lazy(() => import('../src/admin/Mosque/Newmosque')); //import NewMosq from '../src/admin/Mosque/Newmosque';
const  Newadvertiser = lazy(() => import('./admin/Advertisers/NewAdvertiser')); //import Newadvertiser from './admin/Advertisers/NewAdvertiser';
const  ActiveBatch = lazy(() => import('./admin/Orders/ActiveBatchPag/ActiveBatchPag')); //import ActiveBatch from './admin/Orders/ActiveBatchPag/ActiveBatchPag';
const  BatchHistory = lazy(() => import('./admin/Orders/BatchHistoryPag/BatchHistoryPag')); //import BatchHistory from './admin/Orders/BatchHistoryPag/BatchHistoryPag';
const  PaymentHistory = lazy(() => import('./admin/PaymentHistoryPag/PaymentHistoryPag')); //import PaymentHistory from './admin/PaymentHistoryPag/PaymentHistoryPag';
const  Newbatch = lazy(() => import('./admin/Orders/NewBatch')); //import Newbatch from './admin/Orders/NewBatch';
const  Payhisadmin = lazy(() => import('./admin/PaymentHistory')); //import Payhisadmin from './admin/PaymentHistory';
const  PreprintBatch = lazy(() => import('./admin/Orders/PreprintBatchPag/PreprintBatchPag')); //import PreprintBatch from './admin/Orders/PreprintBatchPag/PreprintBatchPag';
const  BatchesInPrinting = lazy(() => import('./admin/Orders/BatchesInPrintingPag/BatchesInPrintingPag')); //import BatchesInPrinting from './admin/Orders/BatchesInPrintingPag/BatchesInPrintingPag';
const  AdminSettings = lazy(() => import('./admin/AdminSettings/AdminSettings')); //import AdminSettings from './admin/AdminSettings/AdminSettings';
const  OrderBrochureMaster = lazy(() => import('./admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder')); ////import OrderBrochureMaster from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder';
const  Droppped = lazy(() => import('./admin/Orders/DroppedPag/DroppedPag')); //import Droppped from './admin/Orders/DroppedPag/DroppedPag';
const  Completed = lazy(() => import('./admin/Orders/CompletedPag/CompletedPag')); //import Completed from './admin/Orders/CompletedPag/CompletedPag';
const  OrderSummary = lazy(() => import('./admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/OrderSummaryPag/OrderSummaryPag')); //import OrderSummary from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/OrderSummaryPag/OrderSummaryPag';
const  TransactionDetail = lazy(() => import('./admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/TransactionDetail')); //import TransactionDetail from './admin/Orders/ActiveBatchPag/ActiveBatchExpandPag/ActiveBatchVieworder/TransactionDetail'
const PendingNewsLetters = lazy(() => import('./admin/AdminNewsletterTab/PendingNewsletterPag'))
const ApprovedNewsLetters = lazy(() => import('./admin/AdminNewsletterTab/ApprovedNewsletterPag'))
const RejectedNewsLetters = lazy(() => import('./admin/AdminNewsletterTab/RejectedNewsletterPag'))
const PendingNewsLettersExpand = lazy(() => import('./admin/AdminNewsletterTab/PendingNewsletterExpandPag'))
const ApprovedNewsLettersExpand = lazy(() => import('./admin/AdminNewsletterTab/ApprovedNewsletterExpandPag'))
const RejectedNewsLettersExpand = lazy(() => import('./admin/AdminNewsletterTab/RejectedNewsletterExpandPag'))
const OrderNewsletterMaster = lazy(() => import('./admin/AdminNewsletterTab/NewsletterTemplates'))
const PendingPrayerTimes = lazy(() => import('./admin/AdminPrayerTimesTab/PendingPrayerTimesPag'))
const ApprovedPrayerTimes = lazy(() => import('./admin/AdminPrayerTimesTab/ApprovedPrayerTimesPag'))
const RejectedPrayerTimes = lazy(() => import('./admin/AdminPrayerTimesTab/RejectedPrayerTimesPag'))
const PendingPrayerTimesExpand = lazy(() => import('./admin/AdminPrayerTimesTab/PendingPrayerTimesExpandPag'))
const ApprovedPrayerTimesExpand = lazy(() => import('./admin/AdminPrayerTimesTab/ApprovedPrayerTimesExpandPag'))
const RejectedPrayerTimesExpand = lazy(() => import('./admin/AdminPrayerTimesTab/RejectedPrayerTimesExpandPag'))
const OrderPrayertimesMaster = lazy(() => import('./admin/AdminPrayerTimesTab/PrayertimesTemplates'))

const Checkout_OrderNewsletterMaster = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Newsletter/CheckoutNewsletterTemplates'))
const Checkout_OrderPrayertimesMaster = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Prayertimes/CheckoutPrayertimesTemplates'))

const PendingNewsLetters_Checkout = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Newsletter/PendingNewsletterPag'))
const RejectedNewsLetters_Checkout = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Newsletter/RejectedNewsletterPag'))
const ApprovedNewsletter_Checkout = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Newsletter/ApprovedNewsletterPag'))

const PendingPrayerTimes_Checkout = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Prayertimes/PendingPrayerTimesPag'))
const RejectedPrayerTimes_Checkout = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Prayertimes/RejectedPrayerTimesPag'))
const ApprovedPrayerTimes_Checkout = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Prayertimes/ApprovedPrayerTimesPag'))
// const PendingNewsLettersExpand_Checkout = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Checkout_Newsletter/PendingNewsletterExpandPag'))

const LetterTemplate1 = lazy(() => import('./admin/AdminNewsletterTab/NewsletterTemplates/LetterTemplate1'))
const LetterTemplate2 = lazy(() => import('./admin/AdminNewsletterTab/NewsletterTemplates/LetterTemplate2'))

const PrintYourOwn = lazy(() => import('./admin/PrintYourOwnTab/PrintOwnPag'))
const PrintYourOwnExpand = lazy(() => import('./admin/PrintYourOwnTab/PrintOwnExpand'))
const OtherPaymentHistory = lazy(() => import('./admin/OtherPayments/OtherPaymentPag'))
const OtherPaymentExpand = lazy(() => import('./admin/OtherPayments/OtherPaymentExpand'))

//Newsletter& PrayerTabs
// const TabExampleNewsletter = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Newsletter_Tab'))
const Pending_Checkout_Business_Letter = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Pending_Checkout_Business_Letter'))
const Approve_Checkout_Business_Letter = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/ApproveNewsletter_returning_checkout'))
const Reject_Checkout_Business_Letter = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/RejectNewsletter_returning_checkout'))

const Pending_Checkout_Business_Prayertimes = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/Pending_Checkout_Business_Prayertimes'))
const Approve_Checkout_Business_Prayertimes = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/ApprovePrayertimes_returning_checkout'))
const Reject_Checkout_Business_Prayertimes = lazy(() => import('./admin/Checkout_NewsLetter_Prayertimes/RejectPrayertimes_returning_checkout'))

//Services
const PrintOnYourOwn = lazy(() => import('./components/PrintOnYourOwn'))
const  NewsletterPayment = lazy(() => import('./components/pages/NewsLetter/NewsletterPaymentPage'))
const  PrayertimesPayment = lazy(() => import('./components/pages/BusinessPrayertimes/PrayertimesPaymentPage'))
const ServicesDesignOptions = lazy(() => import('./components/ServicesDesignOptions'))
const ChooseFromOurDesign = lazy(() => import('./components/ChooseFromOurDesign'))
const WorkWithDesignerService =  lazy(() => import('./components/WorkWithDesignerService'))

//Checkout from choose from our design
const Checkout_Newsletter = lazy(() => import('./components/CheckoutNewsletter_Prayertimes/Checkout_Newsletter'));
const Checkout_Prayertimes = lazy(() => import('./components/CheckoutNewsletter_Prayertimes/Chekout_Prayertimes'));
const Checkout_NewsletterPayment = lazy(() => import('./components/CheckoutNewsletter_Prayertimes/Checkout_NewsletterPayment'));
const Checkout_PrayertimesPayment = lazy(() => import('./components/CheckoutNewsletter_Prayertimes/Checkout_PrayertimesPayment')) 

//Pcr
const Pcr = lazy(() => import('./components/pages/Pcr.jsx'));

//Ramdan
const RamadanTemplate = lazy(() => import("./components/pages/RamdanTemp"));

function App() {
	return (
		<Suspense fallback={<LoadingOutlined style={{ fontSize: 100, color: "#D21A1A", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20%", }} spin />}>
			<Router>

				<Switch>

				<Route exact path='/lettertemplate1/:id' component={LetterTemplate1} />
				<Route exact path='/lettertemplate2/:id' component={LetterTemplate2} />
					{/* <Route exact path='/prayertimesTemplate1/:id' component={GeneratePrayertimesTemplate1} />
					<Route exact path='/prayertimesTemplate2/:id' component={GeneratePrayertimesTemplate2} /> */}

					{/*Payment link routes*/}
					<Route exact path='/paymentLink' component={paymentLink} />
					<Route exact path='/payment_linkpage' component={Payment_page} />

					{/*Admin routes*/}
					<Route exact path='/pendingactivationdetails/:id' component={Pendingactivationdetails} />
					<Route exact path='/ledger/:id' component={Ledger} />
					<Route exact path='/approvedactivationdetails/:id' component={Approvedactivationdetails} />
					<Route exact path='/filleradvertiseredit/:id' component={FilleradvertiserEdit} />
					<Route exact path='/payingadvertiseredit/:id' component={PayingadvertiserEdit} />
					<Route exact path='/activebatchexpand/:id' component={ActivebatchExpand} />
					<Route exact path='/paymenthistoryexpand/:id' component={PaymentHistoryExpand} />
					<Route exact path='/ordersummary/:id' component={OrderSummary} />
					<Route exact path='/vieworder/:id' component={ViewOrder} />
					{/* <Route exact path='makepayment' component={MakePayment} /> */}
					<Route exact path='/batchesinprintingexpand/:id' component={BatchesInPrintingExpand} />
					<Route exact path='/preprintexpand/:id' component={PreprintExpand} />
					<Route exact path='/batchhistoryexpand/:id' component={BatchHistoryExpand} />
					<Route exact path='/dropped/:id' component={Droppped} />
					<Route exact path='/completed/:id' component={Completed} />


					<Route exact path='/pendingactivation' component={PendingActivation1} />
					<Route exact path='/PendingMosquePagination' component={PendingActivation} />
					<Route exact path='/ApprovedMosquePagination' component={Ad_Approve} />

					<Route exact path='/newmosque' component={NewMosq} />

					<Route exact path='/FillerAdvertiserPagination' component={FillerAdvertiser} />
					<Route exact path='/newadvertiser' component={Newadvertiser} />

					<Route exact path='/PayingAdvertiserPagination' component={PayingAdvertiser} />

					<Route exact path='/ActiveBatchPagination' component={ActiveBatch} />

					<Route exact path='/BatchHistoryPagination' component={BatchHistory} />



					<Route exact path='/PaymentHistoryPagination' component={PaymentHistory} />
					<Route exact path='/newbatch' component={Newbatch} />
					<Route exact path='/paymenthistoryadmin' component={Payhisadmin} />

					<Route exact path='/MakePayment' component={MakePayment} />
					<Route exact path='/ReceivePayment' component={ReceivePayment} />

					<Route exact path='/PreprintBatchPagination' component={PreprintBatch} />

					<Route exact path='/BatchesInPrintingPagination' component={BatchesInPrinting} />
					<Route exact path='/adminsettings' component={AdminSettings} />
					<Route exact path='/dashboard' component={DashboardAdmin} />
					<Route exact path='/FillerAdvertiserEdit' component={FilleradvertiserEdit} />

					<Route exact path='/PendingNewsletter' component={PendingNewsLetters} />
					<Route exact path='/ApprovedNewsletter' component={ApprovedNewsLetters} />
					<Route exact path='/RejectedNewsletter' component={RejectedNewsLetters} />
					<Route exact path='/PendingNewsletterExpand/:id' component={PendingNewsLettersExpand} />
					<Route exact path='/ApprovedNewsletterExpand/:id' component={ApprovedNewsLettersExpand} />
					<Route exact path='/RejectedNewsletterExpand/:id' component={RejectedNewsLettersExpand} />
					<Route exact path='/NewsletterVieworder/:id' component={OrderNewsletterMaster} />
					<Route exact path='/PendingPrayerTimes' component={PendingPrayerTimes} />
					<Route exact path='/ApprovedPrayerTimes' component={ApprovedPrayerTimes} />
					<Route exact path='/RejectedPrayerTimes' component={RejectedPrayerTimes} />
					<Route exact path='/PendingPrayerTimesExpand/:id' component={PendingPrayerTimesExpand} />
					<Route exact path='/ApprovedPrayerTimesExpand/:id' component={ApprovedPrayerTimesExpand} />
					<Route exact path='/RejectedPrayerTimesExpand/:id' component={RejectedPrayerTimesExpand} />
					<Route exact path='/PrayertimesVieworder/:id' component={OrderPrayertimesMaster} />

					<Route exact path='/PendingNewsLetter_Checkout/:id' component={PendingNewsLetters_Checkout} />
					<Route exact path='/RejectedNewsletter_Checkout/:id' component={RejectedNewsLetters_Checkout} />
					<Route exact path='/ApprovedNewsletter_Checkout/:id' component={ApprovedNewsletter_Checkout} />
					<Route exact path='/Checkout_NewsletterVieworder/:id' component={Checkout_OrderNewsletterMaster} />

					<Route exact path='/PendingPrayerTimes_Checkout/:id' component={PendingPrayerTimes_Checkout} />
					<Route exact path='/RejectedPrayerTimes_Checkout/:id' component={RejectedPrayerTimes_Checkout} />
					<Route exact path='/ApprovedPrayerTimes_Checkout/:id' component={ApprovedPrayerTimes_Checkout} />
					<Route exact path='/Checkout_PrayertimesVieworder/:id' component={Checkout_OrderPrayertimesMaster} />
					{/* <Route exact path='/PendingNewsletter_CheckoutExpand/:id' component={PendingNewsLettersExpand_Checkout} /> */}

					<Route exact path='/printyourownAdmin' component={PrintYourOwn} />
					<Route exact path='/printyourownDetails/:id' component={PrintYourOwnExpand} />
					<Route exact path='/OtherPayments' component={OtherPaymentHistory} />
					<Route exact path='/OtherPaymentsExpand/:id' component={OtherPaymentExpand} />

					{/* <Route exact path='/adminnewsletterTab' component={TabExampleNewsletter} /> */}
					<Route exact path='/pending_returning_checkout' component={Pending_Checkout_Business_Letter} />
					<Route exact path='/approve_returning_checkout' component={Approve_Checkout_Business_Letter} />
					<Route exact path='/reject_returning_checkout' component={Reject_Checkout_Business_Letter} />

					<Route exact path='/pending_returning_checkoutPrayer' component={Pending_Checkout_Business_Prayertimes} />
					<Route exact path='/approve_returning_checkoutPrayer' component={Approve_Checkout_Business_Prayertimes} />
					<Route exact path='/reject_returning_checkoutPrayer' component={Reject_Checkout_Business_Prayertimes} />

					{/*Mosque routes*/}
					<Route path='/mosque' >
						<MosqueLayout />
					</Route>

					<Route exact path='/step1' component={UserForm} />
					<Route exact path='/masjidterms&conditions' component={MasjidTerms} />
					<Route exact path='/verifyregisteremail' component={VerifyEmail} />

					{/*Services*/}
					<Route exact path='/printout' component={PrintOnYourOwn} />
					<Route exact path='/servicesoptions' component={ServicesDesignOptions} />
					<Route exact path='/ourdesigns' component={ChooseFromOurDesign} />
					<Route exact path='/designerservice' component={WorkWithDesignerService} />

					{/*Business routes*/}
					<Route exact path='/businessdashboard' component={BusinessAdmin} />
					<Route exact path='/businessuser' component={Businessuserr} />
					<Route exact path='/businesssettings' component={BusinessSettings} />
					<Route exact path='/reacttab' component={TabExampleBasic} />
					<Route exact path='/businessorderspagination' component={BusinessOrders} />
					<Route exact path='/businesspaymentpagination' component={BusinessPayment} />
					<Route exact path='/createaccountsettings' component={CreateAccountSettings} />
					<Route exact path='/termsofservices' component={Termsofservices} />
					<Route exact path='/support' component={Support} />
					<Route exact path='/businessterms&conditions' component={BusinessTerms} />
					<Route exact path='/printownterms&conditions' component={PrintownTerms} />
					<Route exact path='/paymentdone' component={PaymentSuccessfull} />
					<Route exact path='/newsletterlist' component={NewsLetters} />
					<Route exact path='/prayerlist' component={GeneratePrayers} />
					<Route exact path='/newNewsletter' component={NewsletterTemplate1} />
					<Route exact path='/letterdetail/:id' component={NewsLetterDetailTemplate1} />
					<Route exact path='/prayertimesdetail/:id' component={PrayerTimesDetailTemplate1} />
					<Route exact path='/newprayertimes' component={PrayerTemplate1} />
					<Route exact path='/DesignerLetter' component={DesignerLetter} />
					<Route exact path='/DesignerPrayer' component={DesignerPrayer} />
					<Route exact path='/newsletterpayment' component={NewsletterPayment} />
					<Route exact path='/prayertimespayment' component={PrayertimesPayment} />

					{/*Checkout from choose from our designs*/}
					<Route exact path='/checkout_newsletter' component={Checkout_Newsletter} />
					<Route exact path='/checkout_prayertimes' component={Checkout_Prayertimes} />
					<Route exact path='/checkout_letterPayment' component={Checkout_NewsletterPayment} />
					<Route exact path='/checkout_prayertimesPayment' component={Checkout_PrayertimesPayment} />

					{/*Login routes*/}
					<Route exact path='/resetpassword' component={Reset} />
					<Route exact path='/login' component={Formlog} />
					<Route exact path='/forgotpassword' component={Forgot} />
					<Route exact path='/confirmforgot' component={Confirm} />
					<Route exact path='/createaccount' component={Create} />




					{/*Home routes*/}
					<Route exact path='/' component={Home} />
					<Route exact path='/brochure' component={BrochureMaster} />
					<Route exact path='/activebatchvieworder/:id' component={OrderBrochureMaster} />

					{/* Pcr route */}
					<Route exact path='/pcr' component={Pcr} />

					{/* Ramdan Template Route */}
					<Route exact path='/ramdantemplate' component={RamadanTemplate} />


					<Route exact path='/template1' component={RealTemplate4} />
					<Route exact path='/template2' component={RealTemplate3} />
					<Route exact path='/template3' component={RealTemplate2} />
					<Route exact path='/template4' component={RealTemplate1} />
					<Route exact path='/demoprinttemplate' component={DemoTemplate} />

					{/* <Route exact path='/template5' component={Template5} />
				<Route exact path='/template6' component={Template6} />
				<Route exact path='/template7' component={Template7} />
				<Route exact path='/template8' component={Template8} /> */}

					<Route exact path='/template5' component={Template5} />
					<Route exact path='/template6' component={Template6} />
					<Route exact path='/template7' component={Template7} />
					<Route exact path='/template8' component={Template8} />
					<Route exact path='/transactiondetail' component={TransactionDetail} />
					


					{/*Anonymous routes*/}
					<Route exact path='/demobusiness' component={Newcustomer} />
					<Route exact path='/checkbox' component={Checkbox} />
					<Route exact path='/demobusiness' component={Demobusiness} />




				</Switch>
			</Router>
		</Suspense>
	);
}

export default App;
