import React, { Component } from 'react';
import { Form, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import axios from '../utils/axios';
import { CONFIG } from '.././utils/constants';
import { withRouter } from 'react-router-dom';
import { Tooltip, message } from 'antd';
// import LoaderGif from '../assets/images/loader3.gif';
import { LoadingOutlined } from '@ant-design/icons';
// import './newcustomer.css'


const text = <span><h5 style={{ color: "white" }}>Password must contain the following:</h5>
  <h6 style={{ color: "white" }}>✔ A lowercase letter</h6>
  <h6 style={{ color: "white" }}>✔ An uppercase letter</h6>
  <h6 style={{ color: "white" }}>✔ A number</h6>
  <h6 style={{ color: "white" }}>✔ A symbol</h6>
  <h6 style={{ color: "white" }}>✔ Minimum 8 characters</h6></span>;


class Newcustomer extends Component {
  constructor(props) {
    super(props);


    this.state = {
      businesstitle: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      conpassword: "",
      P_FirstName: "",
      P_LastName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      card: "",
      expm: "",
      expy: "",
      cvv: "",
      agreeterms: "",
      i_agree: false,
      checked: true,
      isLoading: false

    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      i_agree1: !this.state.checked
    })
  }

  businesstitlehandler = (event) => {
    this.setState({
      businesstitle: event.target.value
    })
  }
  firstnamehandler = (event) => {
    this.setState({
      firstname: event.target.value
    })
  }
  lastnamehandler = (event) => {
    this.setState({
      lastname: event.target.value
    })
  }
  phonenohandler = (event) => {
    this.setState({
      phoneno: event.target.value
    })
  }
  emailhandler = (event) => {
    this.setState({
      email: event.target.value
    }, () => {
      if (this.state.email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
        let obj = {}
        obj.email = this.state.email
        axios.post(CONFIG.API_END_POINT + 'api/v1/admin/EmailChecking', obj).then((response) => {
          if (response.data.success) {
            const success = true
            this.setState({ success: success })
            document.getElementById('emailmessage').innerHTML = ' ';
            document.getElementById('emailmessage').style.color = 'green';
          } else {
            const success = false
            this.setState({ success: success })
            document.getElementById('emailmessage').innerHTML = 'User already exists';
            document.getElementById('emailmessage').style.color = 'Red';
          }
        })
      }
    })
  }
  passwordhandler = (event) => {
    this.setState({
      password: event.target.value
    })
  }
  confpasswordhandler = (event) => {
    this.setState({
      confpassword: event.target.value
    })
  }
  pfirstnamehandler = (event) => {
    this.setState({
      pfirstname: event.target.value
    })
  }
  plastnamehandler = (event) => {
    this.setState({
      plastname: event.target.value
    })
  }
  addresshandler = (event) => {
    this.setState({
      address: event.target.value
    })
  }
  cityhandler = (event) => {
    this.setState({
      city: event.target.value
    })
  }
  statehandler = (event) => {
    this.setState({
      state: event.target.value
    })
  }
  zipcodehandler = (event) => {
    this.setState({
      zipcode: event.target.value
    })
  }
  cardnohandler = (event) => {
    this.setState({
      cardno: event.target.value
    })
  }
  expirymonthhandler = (event) => {
    this.setState({
      expirymonth: event.target.value
    })
  }
  expiryyearhandler = (event) => {
    this.setState({
      expiryyear: event.target.value
    })
  }
  cvvhandler = (event) => {
    this.setState({
      cvv: event.target.value
    })
  }
  agreetermshandler = (event) => {
    this.setState({
      agreeterms: event.target.value
    })
  }
  handleChecked = (e) => {
    this.setState({
      checked: !this.state.checked
    })

  }

  handleChange = (e) => {

    this.setState({ i_agree: !this.state.i_agree });


    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,


      },
      errors: {
        ...this.state.errors,
        [e.target.name]: ''
      }
    });
  }


  check = () => {
    if (document.getElementById('password').value === document.getElementById('confirmpassword').value) {
      document.getElementById('message').style.color = 'green';
      document.getElementById('message').innerHTML = 'Matching';
    } else {
      document.getElementById('message').style.color = 'red';
      document.getElementById('message').innerHTML = 'Passwords does not match';
    }
  }

  Checkemail = () => {
    const email = this.state.email
    if (email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
      document.getElementById('emailmessage').innerHTML = '';
      document.getElementById('emailmessage').style.color = 'green';
    } else {
      document.getElementById('emailmessage').innerHTML = 'Please enter a valid email';
      document.getElementById('emailmessage').style.color = 'Red';
    }
  }

  // verifyEmail = () => {
  //   let obj = {}
  //   obj.email = this.state.email
  //   axios.post(CONFIG.API_END_POINT + 'api/v1/admin/EmailChecking', obj).then((response) => {
  //     if (response.data.success) {
  //       document.getElementById('emailmessage').innerHTML = '';
  //       document.getElementById('emailmessage').style.color = 'green';
  //     } else {
  //       document.getElementById('emailmessage').innerHTML = 'Please enter a valid email';
  //       document.getElementById('emailmessage').style.color = 'Red';
  //     }
  //   })
  // }


  Checkphoneno = () => {
    const email = this.state.phoneno
    if (email.match(/[0-9]{3,14}$/)) {
      document.getElementById('phonenumbermessage').innerHTML = '';
      document.getElementById('phonenumbermessage').style.color = 'green';
    } else {
      document.getElementById('phonenumbermessage').innerHTML = 'Please enter a valid phone number';
      document.getElementById('phonenumbermessage').style.color = 'Red';
    }

  }
  Checkcardno = () => {
    const card = this.state?.cardno
    if (card.match(/[0-9]{16}$/)) {
      document.getElementById('cardnumbermessage').innerHTML = '';
      document.getElementById('cardnumbermessage').style.color = 'green';
    } else {
      document.getElementById('cardnumbermessage').innerHTML = 'Please enter a valid 16-digit card number';
      document.getElementById('cardnumbermessage').style.color = 'Red';
    }

  }

  handlePayment = (e) => {
    window.scrollTo(0, 0)
    e.preventDefault()
    e.persist()
    if (this.props.location.state.selected === true) {
      this.handleSubmited()
    } else {
      this.handleSubmit()
    }

  }

  handleSubmit = () => {
    const amount = this.props.location.state.amount
    const price = amount.slice(1, undefined)

    // const address = this.state.address
    // console.log('address', address)
    // const paymentAddress = address.replace(/[^A-Za-z0-9,\s]*/g, '')
    // const payAddress = paymentAddress.split(',')
    // console.log('pay', paymentAddress)
    // console.log("price", price)



    if (this.state.checked) {
      this.setState({ isLoading: true }, () => {
        let obj = {}
        obj.email = this.state.email
        axios.post(CONFIG.API_END_POINT + 'api/v1/admin/EmailChecking', obj).then((response) => {
          this.setState({ isLoading: false }, () => {
            setTimeout(() => {
              if (response.data.success === 'proceed') {
                this.setState({ isLoading: true }, () => {
                  let obj = {}
                  obj.BusinessTitle = this.state.businesstitle
                  obj.FirstName = this.state.firstname
                  obj.LastName = this.state.lastname
                  obj.phoneNumber = this.state.phoneno
                  obj.email = this.state.email
                  obj.password = this.state.password

                  obj.P_FirstName = this.state.pfirstname
                  obj.P_LastName = this.state.plastname
                  obj.streetAdress = this.state.address
                  obj.city = this.state.city
                  obj.state = this.state.state
                  obj.zipCode = this.state.zipcode
                  obj.cardNo = this.state.cardno
                  obj.ExpirationMoth = this.state.expirymonth
                  obj.ExpirationYear = this.state.expiryyear
                  obj.CVV = this.state.cvv
                  obj.AgreeTermsCondition = this.state.i_agree
                  obj.checkOutGuest = this.state.checked
                  obj.amount = price
                  obj.slotNumber = this.props.location.state.slotnumber
                  obj.MosqueID = this.props.location.state.mosqueid
                  obj.Qty = this.props.location.state.quantity
                  obj.jobcloseOn = this.props.location.state.jobcloseson
                  obj.Iqamaschedule = this.props.location.state.iqamaschedule
                  obj.selectedslot = "yes"
                  obj.selectedDesigner = "no"
                  obj.MosqueName = this.props.location.state.mosqueName
                  obj.forperiod = this.props.location.state.forPeriod
                  obj.jobType = this.props.location.state.jobType
                  obj.description = this.props.location.state.description
                  obj.BatchStatus = this.props.location.state.BatchStatus
                  obj.title = this.props.location.state.title
                  obj.templateSize = this.props.location.state.slots
                  axios({
                    method: 'post',
                    headers: {
                      'Accept': 'application/json, text/plain, */*',
                      'Content-Type': 'application/json'
                    },
                    url: CONFIG.API_END_POINT + 'api/v1/common/businessresgister',
                    data: obj
                    // params:{truckSizeId:"10002"}        
                  }).then((response) => {
                    this.setState({ isLoading: false }, () => {
                      setTimeout(() => {
                        if (response.data.success) {
                          // alert("Payment successful")
                          // this.props.history.push('/')
                          const data = response.data.success.transactionDetails
                          console.log(data);
                          this.setState({
                            transactionID: data.transactionID,
                            BusinessTitle: data.BusinessTitle,
                            paymentDate: data.paymentDate,
                            slotNumber: data.slotNumber,
                            amount: data.amount,
                            PaymentProcessor: data.PaymentProcessor
                          });
                          this.props.history.push('/paymentdone', {
                            transactionID: this.state.transactionID,
                            BusinessTitle: this.state.BusinessTitle,
                            paymentDate: this.state.paymentDate,
                            slotNumber: this.state.slotNumber,
                            amount: this.state.amount,
                            PaymentProcessor: this.state.PaymentProcessor
                          })
                        } else {
                          message.error(`${response.data.error}`)
                        }
                      }, 100)
                    })
                  })
                })
              } else {
                alert("User already exists! Please use a different email or login if you have already have an account")
              }
            }, 100)
          })
        })
      })
    } else {
      this.setState({ isLoading: true }, () => {
        let obj = {}
        obj.BusinessTitle = this.state.businesstitle
        obj.FirstName = this.state.firstname
        obj.LastName = this.state.lastname
        obj.phoneNumber = this.state.phoneno
        obj.email = this.state.email
        obj.password = this.state.password

        obj.P_FirstName = this.state.pfirstname
        obj.P_LastName = this.state.plastname
        obj.streetAdress = this.state.address
        obj.city = this.state.city
        obj.state = this.state.state
        obj.zipCode = this.state.zipcode
        obj.cardNo = this.state.cardno
        obj.ExpirationMoth = this.state.expirymonth
        obj.ExpirationYear = this.state.expiryyear
        obj.CVV = this.state.cvv
        obj.AgreeTermsCondition = this.state.i_agree
        obj.checkOutGuest = this.state.checked
        obj.amount = price
        obj.slotNumber = this.props.location.state.slotnumber
        obj.MosqueID = this.props.location.state.mosqueid
        obj.Qty = this.props.location.state.quantity
        obj.jobcloseOn = this.props.location.state.jobcloseson
        obj.Iqamaschedule = this.props.location.state.iqamaschedule
        obj.selectedslot = "yes"
        obj.selectedDesigner = "no"
        obj.MosqueName = this.props.location.state.mosqueName
        obj.forperiod = this.props.location.state.forPeriod
        obj.jobType = this.props.location.state.jobType
        obj.description = this.props.location.state.description
        obj.BatchStatus = this.props.location.state.BatchStatus
        obj.title = this.props.location.state.title
        obj.templateSize = this.props.location.state.slots
        axios({
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          url: CONFIG.API_END_POINT + 'api/v1/common/businessresgister',
          data: obj
          // params:{truckSizeId:"10002"}        
        }).then((response) => {
          this.setState({ isLoading: false }, () => {
            setTimeout(() => {
              if (response.data.success) {
                // alert("Payment successful")
                // this.props.history.push('/')
                const data = response.data.success.transactionDetails
                console.log(data);
                this.setState({
                  transactionID: data.transactionID,
                  BusinessTitle: data.BusinessTitle,
                  paymentDate: data.paymentDate,
                  slotNumber: data.slotNumber,
                  amount: data.amount,
                  PaymentProcessor: data.PaymentProcessor
                });
                this.props.history.push('/paymentdone', {
                  transactionID: this.state.transactionID,
                  BusinessTitle: this.state.BusinessTitle,
                  paymentDate: this.state.paymentDate,
                  slotNumber: this.state.slotNumber,
                  amount: this.state.amount,
                  PaymentProcessor: this.state.PaymentProcessor
                })
              } else {
                message.error(`${response.data.error}`)
              }
            }, 100)
          })
        })
      })
    }

  }


  handleSubmited = () => {
    const amount = this.props.location.state.amount
    const price = amount.slice(1, undefined)
    const newprice = Number(price) + Number(5)


    // const address = this.state.address
    // const paymentAddress = address.replace(/[^A-Za-z0-9,\s]*/g, '')
    // const payAddress = paymentAddress.split(',')
    // console.log('newprice', newprice)



    // this.setState({ isLoading: true }, () => {
    //   axios({
    //     method: 'post',
    //     headers: {
    //       'Accept': 'application/json, text/plain, */*',
    //       'Content-Type': 'application/json'
    //     },
    //     url: CONFIG.API_END_POINT + 'api/v1/common/businessresgister',
    //     data: obj
    //     // params:{truckSizeId:"10002"}        
    //   }).then((response) => {
    //     this.setState({ isLoading: false }, () => {
    //       setTimeout(() => {
    //         if (response.data.success) {
    //           alert("Payment successful")
    //           this.props.history.push('/')
    //         } else {
    //           alert("Payment could not be completed")
    //         }
    //       }, 100)
    //     })
    //   }).catch(error => {
    //     console.log(error.response);

    //   })
    // })
    if (this.state.checked) {
      this.setState({ isLoading: true }, () => {
        let obj = {}
        obj.email = this.state.email
        axios.post(CONFIG.API_END_POINT + 'api/v1/admin/EmailChecking', obj).then((response) => {
          this.setState({ isLoading: false }, () => {
            setTimeout(() => {
              if (response.data.success === 'proceed') {
                this.setState({ isLoading: true }, () => {
                  let obj = {}
                  obj.BusinessTitle = this.state.businesstitle
                  obj.FirstName = this.state.firstname
                  obj.LastName = this.state.lastname
                  obj.phoneNumber = this.state.phoneno
                  obj.email = this.state.email
                  obj.password = this.state.password

                  obj.P_FirstName = this.state.pfirstname
                  obj.P_LastName = this.state.plastname
                  obj.streetAdress = this.state.address
                  obj.city = this.state.city
                  obj.state = this.state.state
                  obj.zipCode = this.state.zipcode
                  obj.cardNo = this.state.cardno
                  obj.ExpirationMoth = this.state.expirymonth
                  obj.ExpirationYear = this.state.expiryyear
                  obj.CVV = this.state.cvv
                  obj.AgreeTermsCondition = this.state.i_agree
                  obj.checkOutGuest = this.state.checked
                  obj.amount = JSON.stringify(newprice)
                  obj.slotNumber = this.props.location.state.slotnumber
                  obj.MosqueID = this.props.location.state.mosqueid
                  obj.Qty = this.props.location.state.quantity
                  obj.jobcloseOn = this.props.location.state.jobcloseson
                  obj.Iqamaschedule = this.props.location.state.iqamaschedule
                  obj.selectedslot = "no"
                  obj.selectedDesigner = "yes"
                  obj.MosqueName = this.props.location.state.mosqueName
                  obj.forperiod = this.props.location.state.forPeriod
                  obj.jobType = this.props.location.state.jobType
                  obj.description = this.props.location.state.description
                  obj.BatchStatus = this.props.location.state.BatchStatus
                  obj.title = this.props.location.state.title
                  obj.templateSize = this.props.location.state.slots
                  axios({
                    method: 'post',
                    headers: {
                      'Accept': 'application/json, text/plain, */*',
                      'Content-Type': 'application/json'
                    },
                    url: CONFIG.API_END_POINT + 'api/v1/common/businessresgister',
                    data: obj
                    // params:{truckSizeId:"10002"}        
                  }).then((response) => {
                    this.setState({ isLoading: false }, () => {
                      setTimeout(() => {
                        if (response.data.success) {
                          // alert("Payment successful")
                          // this.props.history.push('/')
                          const data = response.data.success.transactionDetails
                          console.log(data);
                          this.setState({
                            transactionID: data.transactionID,
                            BusinessTitle: data.BusinessTitle,
                            paymentDate: data.paymentDate,
                            slotNumber: data.slotNumber,
                            amount: data.amount,
                            PaymentProcessor: data.PaymentProcessor
                          });
                          this.props.history.push('/paymentdone', {
                            transactionID: this.state.transactionID,
                            BusinessTitle: this.state.BusinessTitle,
                            paymentDate: this.state.paymentDate,
                            slotNumber: this.state.slotNumber,
                            amount: this.state.amount,
                            PaymentProcessor: this.state.PaymentProcessor
                          })
                        } else {
                          message.error(`${response.data.error}`)
                        }
                      }, 100)
                    })
                  })
                })
              } else {
                alert("User already exists! Please use a different email or login if you have already have an account")
              }
            }, 100)
          })
        })
      })
    } else {
      this.setState({ isLoading: true }, () => {
        let obj = {}
        obj.BusinessTitle = this.state.businesstitle
        obj.FirstName = this.state.firstname
        obj.LastName = this.state.lastname
        obj.phoneNumber = this.state.phoneno
        obj.email = this.state.email
        obj.password = this.state.password

        obj.P_FirstName = this.state.pfirstname
        obj.P_LastName = this.state.plastname
        obj.streetAdress = this.state.address
        obj.city = this.state.city
        obj.state = this.state.state
        obj.zipCode = this.state.zipcode
        obj.cardNo = this.state.cardno
        obj.ExpirationMoth = this.state.expirymonth
        obj.ExpirationYear = this.state.expiryyear
        obj.CVV = this.state.cvv
        obj.AgreeTermsCondition = this.state.i_agree
        obj.checkOutGuest = this.state.checked
        obj.amount = JSON.stringify(newprice)
        obj.slotNumber = this.props.location.state.slotnumber
        obj.MosqueID = this.props.location.state.mosqueid
        obj.Qty = this.props.location.state.quantity
        obj.jobcloseOn = this.props.location.state.jobcloseson
        obj.Iqamaschedule = this.props.location.state.iqamaschedule
        obj.selectedslot = "no"
        obj.selectedDesigner = "yes"
        obj.MosqueName = this.props.location.state.mosqueName
        obj.forperiod = this.props.location.state.forPeriod
        obj.jobType = this.props.location.state.jobType
        obj.description = this.props.location.state.description
        obj.BatchStatus = this.props.location.state.BatchStatus
        obj.title = this.props.location.state.title
        obj.templateSize = this.props.location.state.slots
        axios({
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          url: CONFIG.API_END_POINT + 'api/v1/common/businessresgister',
          data: obj
          // params:{truckSizeId:"10002"}        
        }).then((response) => {
          this.setState({ isLoading: false }, () => {
            setTimeout(() => {
              if (response.data.success) {
                // alert("Payment successful")
                // this.props.history.push('/')
                const data = response.data.success.transactionDetails
                console.log(data);
                this.setState({
                  transactionID: data.transactionID,
                  BusinessTitle: data.BusinessTitle,
                  paymentDate: data.paymentDate,
                  slotNumber: data.slotNumber,
                  amount: data.amount,
                  PaymentProcessor: data.PaymentProcessor
                });
                this.props.history.push('/paymentdone', {
                  transactionID: this.state.transactionID,
                  BusinessTitle: this.state.BusinessTitle,
                  paymentDate: this.state.paymentDate,
                  slotNumber: this.state.slotNumber,
                  amount: this.state.amount,
                  PaymentProcessor: this.state.PaymentProcessor
                })
              } else {
                message.error(`${response.data.error}`)
              }
            }, 100)
          })
        })
      })
    }
  }






  render() {
    const content = !this.state.checked
      ? <div>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="password" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Password *</Label> <Tooltip placement="topLeft" title={text}><i className="fas fa-lightbulb" style={{ color: "gray" }} /></Tooltip>
              <input id="password" style={{ border: "solid 2px #e3e3e3", height: "40px", width: "100%" }} name="password" type="password" value={this.state.password} onChange={this.passwordhandler} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Please enter a strong password" required onKeyUp={this.check} />
            </FormGroup>

          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="password" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Confirm Password *</Label>
              <input id="confirmpassword" style={{ border: "solid 2px #e3e3e3", height: "40px", width: "100%" }} name="password" type="password" value={this.state.confpassword} onChange={this.confpasswordhandler} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Please enter a strong password" required onKeyUp={this.check} />
              <span id='message'></span>
            </FormGroup>
          </Col>
        </Row>
      </div>
      : null;

    // const designer = !this.props.location.state?.selected
    //   ? <div>
    //     <input
    //       type="checkbox"
    //       // defaultChecked={this.state.i_agree}
    //       checked={this.state.checked}
    //       onChange={this.handleChecked} />
    //     <label style={{ marginLeft: "5px", fontSize: "16px", fontWeight: "600", lineHeight: "26px", marginBottom: "13px", color: "gray" }}>Checkout as Guest</label><hr />
    //   </div>
    //   : null;
    return (
      <>
        {/* {
          this.state?.isLoading &&
          <img alt="" src={LoaderGif} style={{ top: "10%", position: "absolute", left: "45%", height: "200px", width: "500px", zIndex: "1000", }} /> // background: 'rgb(255,255,255,0.6)'
        } */}
        <div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "50%" }}>
          {this.state?.isLoading &&
            <LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
          }
        </div>
        <div >

          {/* <Container className="frm0"> */}
          <Row>
            <Col md={12}>
              <Form onSubmit={this.handlePayment} >
                <div className="container">
                  <div >
                    <Row>
                      <Col>
                        <FormGroup>

                          <Label for="businesstitle" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Business Title *</Label>
                          <Input id="businesstitle" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px", }} name="businesstitle" value={this.state.businesstitle} onChange={this.businesstitlehandler} title="please enter a valid business title and it must be less than 20 characters" required />

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>

                        <FormGroup>
                          <Label for="firatName" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>First Name *</Label>
                          <Input id="firstName" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="firstName" value={this.state.firstname} onChange={this.firstnamehandler} title="please enter a valid first name and it must be less than 20 characters" required />

                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="lastName" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Last Name *</Label>
                          <Input id="lastName" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="lastName" value={this.state.lastname} onChange={this.lastnamehandler} title="please enter a valid last name and it must be less than 20 characters" required />

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="phone" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Phone *</Label>
                          <Input id="phone" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="phone" value={this.state.phoneno} onChange={this.phonenohandler} pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}?" title="please enter a phone number" required onKeyUp={this.Checkphoneno} /><span id="phonenumbermessage"></span>

                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="email" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Email *</Label>
                          <Input id="email" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="email" type="email" value={this.state.email} onChange={this.emailhandler} onKeyUp={this.Checkemail} /><span id="emailmessage"></span>

                        </FormGroup>
                      </Col>
                      <Col>
                        <div>
                          {content}
                          <div>
                            {/* {designer} */}

                            <input
                              type="checkbox"
                              // defaultChecked={this.state.i_agree}
                              checked={this.state.checked}
                              onChange={this.handleChecked} />
                            <label style={{ marginLeft: "5px", fontSize: "16px", fontWeight: "600", lineHeight: "26px", marginBottom: "13px", color: "gray" }}>Checkout as Guest</label><hr />
                          </div>
                        </div>

                      </Col>
                    </Row>

                    <br />


                    <p style={{ color: "#212529", fontSize: "1.7rem" }}>Payment Details<hr /></p>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="P_firstName" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>First Name *</Label>
                          <Input id="P_firstName" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="P_firstName" value={this.state.pfirstname} onChange={this.pfirstnamehandler} title="please enter a valid first name" required />

                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="P_lastName" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Last Name *</Label>
                          <Input id="P_lastName" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="P_lastName" value={this.state.plastname} onChange={this.plastnamehandler} title="please enter a valid last name" required />

                        </FormGroup>

                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="address" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Street Address *</Label>
                          <Input id="address" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="address" value={this.state.address} onChange={this.addresshandler} title="please enter a valid address" required />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="city" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>City *</Label>
                          <Input id="city" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="city" value={this.state.city} onChange={this.cityhandler} title="please enter a valid city" required />
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup>
                          <Label for="state" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>State *</Label>
                          <Input id="state" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="state" value={this.state.statee} onChange={this.statehandler} title="please enter a valid state" required />

                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="zip" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Zip code *</Label>
                          <Input maxlength="5" id="zip" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="zip" value={this.state.zipcode} onChange={this.zipcodehandler} pattern="[0-9]{5}$" title="Please enter a valid zipcode" required />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="card" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Card No. *</Label>
                          <Input maxlength="16" id="card" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="card" value={this.state.cardno} onChange={this.cardnohandler} title="please enter a valid card number" pattern="[0-9]{16}$" required onKeyUp={this.Checkcardno} /><span id="cardnumbermessage"></span>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <FormGroup>
                          <Label for="expm" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Expiration Month *</Label>
                          <select style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px", width: "auto", color: "gray" }} value={this.state.expirymonth} onChange={this.expirymonthhandler}>
                            <option style={{ display: "none" }}>Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>

                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="expy" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>Expiration Year *</Label>
                          <select style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px", color: "gray" }} value={this.state.expiryyear} onChange={this.expiryyearhandler}>
                            <option style={{ display: "none" }}>Year</option>
                            {/* <option>2021</option> */}
                            <option>{new Date().getFullYear()}</option>
                            <option>{new Date().getFullYear() + 1}</option>
                            <option>{new Date().getFullYear() + 2}</option>
                            <option>{new Date().getFullYear() + 3}</option>
                            <option>{new Date().getFullYear() + 4}</option>
                            <option>{new Date().getFullYear() + 5}</option>
                            <option>{new Date().getFullYear() + 6}</option>
                            <option>{new Date().getFullYear() + 7}</option>
                            <option>{new Date().getFullYear() + 8}</option>
                            <option>{new Date().getFullYear() + 9}</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="cvv" style={{ fontSize: "16px", marginBottom: "0", color: "gray", }}>CVV *</Label>
                          <Input id="cvv" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px" }} name="cvv" value={this.state.cvv} onChange={this.cvvhandler} pattern="[0-9]{3}" titlle="please enter a valid cvv" required />
                        </FormGroup>
                      </Col>
                    </Row><br />
                    <Row>
                      <FormGroup>
                        <Col>
                          <label>
                            <input
                              type="checkbox"
                              defaultChecked={this.state.i_agree}
                              onChange={this.handleChange}
                              required
                              style={{ height: "15px", width: "20px" }}
                            /><span style={{ cursor: "pointer", fontSize: "18px", color: "gray" }}>Agree to <span style={{ color: "#D21A1A", textDecoration: "underline" }} ><a target="_blank" style={{ textDecoration: "none", color: "inherit" }} href="/businessterms&conditions">Terms and Conditions</a></span></span>
                          </label>
                          <br />
                        </Col>
                        {
                          this.state.checked ?
                            <button disabled={this.state.success === false} style={{ backgroundColor: "#D21A1A", borderRadius: "2px", marginLeft: "15px", border: "none", marginTop: "25px", width: "150px", height: "35px", color: "white" }}>Submit Payment</button>
                            :
                            <button disabled={this.state.password !== this.state.confpassword} style={{ backgroundColor: "#D21A1A", borderRadius: "2px", marginLeft: "15px", border: "none", marginTop: "25px", width: "150px", height: "35px", color: "white" }}>Submit Payment</button>
                        }
                      </FormGroup>
                    </Row>
                  </div>
                </div>
              </Form>
            </Col>
            {/* <Col md={1}></Col> */}
          </Row>
          {/* </Container> */}

        </div>
      </>
    )
  }
}

export default withRouter(Newcustomer);