import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './AdminNavBar.css';
import { LogoutAll } from '../utils/Helper';
import { LoadingOutlined } from '@ant-design/icons';
//import LoaderGif from '../assets/images/loader3.gif'

export default class Adminheader extends Component {

  handleLogout = () => {
    this.setState({ isLoading: true }, () => {
      LogoutAll()
    })
  }

  render() {
    return (
      <>
        <div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "50%" }}>
          {this.state?.isLoading &&
            <LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
          }
        </div>
        {/* {
          this.state?.isLoading &&
          <img alt="" src={LoaderGif} style={{ top: "10%", position: "absolute", left: "35%", height: "200px", width: "500px", zIndex: "1000", }} /> // background: 'rgb(255,255,255,0.6)'
        } */}
        <div className="sub-header-menu" >
          <div className="container">
            <ul style={{ fontFamily: "WebFonts", marginBottom: "0px", }}>
              <div className="dropdown" style={{  padding: "6px 12px" }}>
                <Link to="/dashboard" style={{ color: "#374767" }}>
                  <i style={{ marginLeft: "30px", marginBottom: "5px" }} class="fas fa-home"></i>
                  <p className="hov" style={{ letterSpacing: "0.3px", lineHeight: "20px", fontFamily: "WebFonts" }}>Dashboard</p></Link>
              </div>
              <div className="dropdown" style={{  padding: "6px 24px", color: "#374767" }}>
                <i style={{ marginLeft: "20px", marginBottom: "5px", }} class="far fa-envelope"></i>
                <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px", cursor: "pointer" }}>Mosques<i class="fas fa-caret-down" style={{ marginLeft: "3px", cursor: "pointer" }}></i></p>
                <div className="dropdown-content" style={{ marginLeft: "-25px", color: "#374767", marginTop: "6px" }}>
                  <Link to="/newmosque" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>New Mosque</Link>
                  <Link to="/PendingMosquePagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Pending Activation</Link>
                  <Link to="/ApprovedMosquePagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767", }}>Approved Mosque</Link>
                </div>
              </div>
              <div className="dropdown" style={{  padding: "6px 24px", color: "#374767" }}>
                <i style={{ marginLeft: "24px", marginBottom: "5px", }} class="far fa-envelope"></i>
                <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px", cursor: "pointer" }}>Advertisers<i class="fas fa-caret-down" style={{ marginLeft: "3px", cursor: "pointer" }}></i></p>
                <div className="dropdown-content" style={{ marginLeft: "-25px", marginTop: "6px" }}>
                  <Link to="/newadvertiser" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>New Advertisers</Link>
                  <Link to="/FillerAdvertiserPagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Filler Advertisers</Link>
                  <Link to="/PayingAdvertiserPagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Current Advertisers</Link>
                </div>
              </div>
              <div className="dropdown" style={{  padding: "6px 24px", color: "#374767" }}>
                <i style={{ marginLeft: "15px", marginBottom: "5px", }} class="far fa-envelope"></i>
                <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px", cursor: "pointer" }}>Orders<i class="fas fa-caret-down" style={{ marginLeft: "3px", cursor: "pointer" }}></i></p>
                <div className="dropdown-content" style={{ marginLeft: "-25px", marginTop: "6px" }}>
                  <Link to="/newbatch" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>New Batch</Link>
                  <Link to="/ActiveBatchPagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Active Batches</Link>
                  <Link to="/PreprintBatchPagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Preprint Batches</Link>
                  <Link to="/BatchesInPrintingPagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Batches in printing</Link>
                  <Link to="/BatchHistoryPagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Batch History</Link>
                </div>
              </div>
              <div className="dropdown" style={{  padding: "6px 24px", color: "#374767" }}>
                <i style={{ marginLeft: "35px", marginBottom: "5px", }} class="fa fa-newspaper"></i>
                <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px", cursor: "pointer" }}>Newsletter<i class="fas fa-caret-down" style={{ marginLeft: "3px", cursor: "pointer" }}></i></p>
                <div className="dropdown-content" style={{ marginLeft: "-25px", marginTop: "6px" }}>
                  <Link to="/pending_returning_checkout" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Pending Newsletter</Link>
                  <Link to="/approve_returning_checkout" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Approved Newsletter</Link>
                  <Link to="/reject_returning_checkout" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Rejected Newsletter</Link>
                </div>
              </div>
              {/* <div className="dropdown" style={{  padding: "6px 24px", color: "#374767" }}>
                <i style={{ marginLeft: "35px", marginBottom: "5px", }} class="fas fa-clock"></i>
                <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px", cursor: "pointer" }}>Prayer Calendars<i class="fas fa-caret-down" style={{ marginLeft: "3px", cursor: "pointer" }}></i></p>
                <div className="dropdown-content" style={{ marginLeft: "-25px", marginTop: "6px" }}>
                  <Link to="/pending_returning_checkoutPrayer" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Pending PrayerTimes</Link>
                  <Link to="/approve_returning_checkoutPrayer" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Approved PrayerTimes</Link>
                  <Link to="/reject_returning_checkoutPrayer" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Rejected PrayerTimes</Link>
                </div>
              </div> */}
              <div className="dropdown" style={{  padding: "6px 16px", color: "#374767" }}>
                <Link style={{ color: "#374767" }} to="/printyourownAdmin"><i style={{ marginLeft: "40px", marginBottom: "5px" }} class="fa fa-print"></i>
                  <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px" }}>Print your own</p>
                </Link>
              </div>
              
              <div className="dropdown" style={{  padding: "6px 24px", color: "#374767" }}>
                <i style={{ marginLeft: "40px", marginBottom: "5px", }} class="fas fa-cogs"></i>
                <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px", cursor: "pointer" }}>Payment History<i class="fas fa-caret-down" style={{ marginLeft: "3px", cursor: "pointer" }}></i></p>
                <div className="dropdown-content" style={{ marginLeft: "-25px", marginTop: "6px" }}>
                  <Link to="/PaymentHistoryPagination" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Prayer Calendars Payments</Link>
                  <Link to="/OtherPayments" className="hov content" style={{ borderBottom: "solid 1px #e7ecf5", color: "#374767" }}>Other Payments</Link>
                </div>
              </div>
              <div className="dropdown" style={{  padding: "6px 16px", color: "#374767" }}>
                <Link style={{ color: "#374767" }} to="/adminsettings"><i style={{ marginLeft: "15px", marginBottom: "5px" }} class="fas fa-cogs"></i>
                  <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px" }}>Settings</p>
                </Link>
              </div>
              <div className="dropdown" style={{  padding: "6px 16px", color: "#374767" }}>
                <Link style={{ color: "#374767" }} to="/paymentLink"><i style={{ marginLeft: "40px", marginBottom: "5px" }} class="fa fa-print"></i>
                  <p className="hov" style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px" }}>Payment Link</p>
                </Link>
              </div>
              <div class="dropdown" style={{  padding: "5px 12px", color: "#374767" }}>
                <i style={{ marginLeft: "15px", marginBottom: "5px" }} class="fas fa-power-off"></i>
                <p style={{ fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px" }}><button className="hov" style={{ backgroundColor: "#eef2f6", border: "solid 1px #eef2f6", color: "#374767", fontWeight: "200", letterSpacing: "0.3px", lineHeight: "20px", fontSize: "14px" }} onClick={this.handleLogout}>Logout</button></p>
              </div>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
