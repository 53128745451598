import React, { Component } from 'react';
import { Input, Button, Label, Row, Col } from 'reactstrap';
import axios from '../utils/axios';
import { withRouter } from 'react-router-dom';
import '../components/pages/login.css';
import { CONFIG } from '.././utils/constants'
// import LoaderGif from '../assets/images/loader3.gif'
import { LoadingOutlined } from '@ant-design/icons';


class Returningcust extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isLoading: false
    }
    this.handleLogin = this.handleLogin.bind(this)
  }

  emailhandler = (event) => {
    this.setState({
      email: event.target.value
    })
  }
  passwordhandler = (event) => {
    this.setState({
      password: event.target.value
    })
  }




  handleLogin = (e) => {
    e.preventDefault();
    let obj = {}
    obj.email = this.state.email
    obj.password = this.state.password

    this.setState({ isLoading: true }, () => {
      axios.post(CONFIG.API_END_POINT + `api/v1/common/login/login`, obj).then(
        (response) => {
          this.setState({ isLoading: false }, () => {
            setTimeout(() => {
              if (response.data.message === 'check your email' || response.data.message === 'Invalid Credentials.') {
                if (response.data.message === 'check your email') {
                  alert("Inavlid email")
                } else {
                  alert("Incorrect Password")
                }
              } else if (response.data.role === 'Business') {
                localStorage.setItem('token', JSON.stringify(response.data.token));
                localStorage.setItem('email', JSON.stringify(response.data.email));
                localStorage.setItem('role', response.data.role);
                localStorage.setItem('mosqueId', response.data.id);
                localStorage.setItem('FirstName', response.data.FirstName);
                localStorage.setItem('LastName', response.data.LastName);
                localStorage.setItem('BusinessTitle', response.data.BusinessTitle);
                localStorage.setItem('phoneNumber', response.data.phoneNumber);
                this.setState({
                  loginToken: response.data.token,
                  loginStatus: response.data.success,
                  role: response.data.role
                });
                window.location.reload(false);
              } else {
                alert("Login in as a business user")
              }
            }, 100)
          })
        })
    })
  }


  render() {
    return (
      <>
        {/* {
          this.state?.isLoading &&
          <img alt="" src={LoaderGif} style={{ top: "30%", position: "absolute", left: "35%", height: "200px", width: "500px", zIndex: "1000", }} /> // background: 'rgb(255,255,255,0.6)'
        } */}
        <div style={{ verticalAlign: "middle", textAlign: "center", position: "absolute", left: "50%", zIndex: "99999", top: "50%" }}>
          {this.state?.isLoading &&
            <LoadingOutlined style={{ fontSize: 120, color: "#D21A1A" }} spin />
          }
        </div>
        <div>

          <div>
            <Row>

              <Col md={12}>
                <div >

                  <form onSubmit={this.handleLogin} className="formlogin">
                    <div>
                      <div >

                        <Col>
                          <Label style={{ fontSize: "16px", marginBottom: "0", color: "gray", marginTop: "13px" }}>Email *</Label>
                          <Input id="email" type="email" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px", width: "100%", marginTop: "5px" }} value={this.state.email} onChange={this.emailhandler} placeholder="Type your email here..." /><br /> </Col>
                        <Col>
                          <Label style={{ fontSize: "16px", marginBottom: "0", color: "gray", marginTop: "5px" }}>Password *</Label>
                          {/* <Tooltip placement="topLeft" title={text}><i className="fas fa-lightbulb" style={{ color: "gray" }} /></Tooltip> */}
                          <Input id="password" style={{ border: "solid 2px #e3e3e3", height: "40px", borderRadius: "1px", width: "100%", marginTop: "5px" }} type="password" value={this.state.password} onChange={this.passwordhandler} placeholder="Type your password..." /><br /></Col>

                        <Col> <Button style={{ width: "100%", backgroundColor: "#D21A1A", borderRadius: "2px", marginTop: "10px", marginBottom: "10px", height: "40px" }}>Login</Button>   </Col>
                      </div>
                    </div>
                  </form>


                </div>
              </Col>
            </Row>

          </div>
        </div>

      </>


    );
  }
}
export default withRouter(Returningcust);



